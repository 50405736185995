import Vue from "vue";
import App from "./App.vue";
import store from "./utils/store";
import router from "./utils/router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import Meta from "vue-meta";

import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "4dF9bhTTbrByz2inExM8k0EZmRdwATfx",
});
Vue.use(ElementUI);
// Vue.use(Meta);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
