<template>
  <div class="productCenterIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <el-carousel
          trigger="click"
          :interval="swiperTime"
          class="swiper"
          :height="imgHeight"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topDivimg">
        <div class="topDiv">
          <header>
            <div>JOIN US</div>
            <div>加入我们</div>
            <div></div>
            <!-- <div style="text-align: center">
              公司产品主要有智能控制和智能运维两大核心业务，囊括散货大型装卸设备管控自动化、生产管控智能化、运维监管智能化、环境综合管控一体化、针对行业场景的打造了“沅视听”智能机器学习平台和数字孪生系统平台，为客户长期保驾护航的互联网+VR的智能运维(巡检)平台，致力于成为智慧港口、智慧钢铁、智慧电厂建设的智造专家。
            </div> -->
          </header>
        </div>
      </div>
      <div id="w100">
        <div id="bx">
          <div class="car">
            <div>
              <img src="../../assets/talentrecruitment/高效.png" alt="" />
              <h3>高效灵活，按时下班</h3>
              <p>
                我们保持头脑清醒和冷静，我们追求极致的创意，并愿意花时间去思考，放慢步伐，精细打磨，创造无法复制的产品。
              </p>
            </div>
            <div>
              <img src="../../assets/talentrecruitment/尊重.png" alt="" />
              <h3>尊重个性，开放包容</h3>
              <p>
                我们相信每个个体都是独一无二的。我们尊重每一个创意，倾听每一个想法。一起探索思维的边界，一起碰撞独特的火花。
              </p>
            </div>
          </div>
          <div class="car">
            <div>
              <img src="../../assets/talentrecruitment/福利.png" alt="" />
              <h3>福利关怀，保驾护航</h3>
              <p>
                除了良好的薪酬，五险一金，员工宿舍，带薪年假等，还有更多隐型福利和关怀等你解锁，为你的逐梦之路保驾护航。
              </p>
            </div>
            <div>
              <img src="../../assets/talentrecruitment/业余.png" alt="" />
              <h3>业余活动，多彩多样</h3>
              <p>
                我们希望平衡工作和生活，我们有丰富多彩的社团活动，让员工保持身心健康，在工作之余也能感受到温暖和快乐。
              </p>
            </div>
          </div>
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>员工福利</h3>
                <div></div>
              </div>
              <div class="d2">employee welfare</div>
            </div>
          </div>
        </div>
      </div>
      <div id="w100" class="backImg">
        <div id="bx">
          <div class="ygfl">
            <div class="top">
              <div v-for="(item, index) in topList" :key="index">
                <img
                  style="
                    width: calc(100vw * 42 / 1920);
                    height: calc(100vw * 42 / 1920);
                  "
                  :src="item.url"
                  alt=""
                />
                <h4 style="font-size: calc(100vw * 16 / 1920)">
                  {{ item.title }}
                </h4>
              </div>
            </div>
            <div class="bottom">
              <div v-for="(item, index) in bottomList" :key="index">
                <img
                  style="
                    width: calc(100vw * 42 / 1920);
                    height: calc(100vw * 42 / 1920);
                  "
                  :src="item.url"
                  alt=""
                />
                <h4 style="font-size: calc(100vw * 16 / 1920)">
                  {{ item.title }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="w100" style="padding-bottom: 2%">
        <div id="bx">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>热招职位</h3>
                <div></div>
              </div>
              <div class="d2">Hot recruitment positions</div>
            </div>
          </div>
          <div class="inputDiv">
            <el-input
              class="el-input"
              v-model="input"
              placeholder="请输入内容"
            ></el-input
            ><el-button class="el-button" type="primary" @click="workJoblist"
              >开始搜索</el-button
            >
          </div>
          <div class="anyNum">
            <p>
              共 <span>{{ dataList.length }}</span
              >个职位
            </p>
          </div>
          <div class="selectDiv">
            <el-collapse accordion>
              <el-collapse-item v-for="(item, index) in dataList" :key="index">
                <template slot="title">
                  <h1>{{ item.jobName }}</h1>
                  <p style="font-size: calc(100vw * 12 / 1920)">
                    {{ item.jobAddress }}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    {{ item.jobLife }}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<span
                      v-if="item.jobEducation == 1"
                      >硕士及以上</span
                    ><span v-if="item.jobEducation == 2">本科及以上</span
                    ><span v-if="item.jobEducation == 3">专科及以上</span
                    ><span v-if="item.jobEducation == 4">高中及以上</span
                    ><span v-if="item.jobEducation == 5">博士</span
                    >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{
                      item.recruitingNum
                    }}人&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{ item.dateStr }}
                  </p>
                </template>
                <div class="mb mt">
                  <h3>岗位介绍</h3>
                  <p v-for="(i, d) in item.jobIntroductionList" :key="d">
                    {{ i }}
                  </p>
                </div>
                <div class="mb">
                  <h3>岗位职责</h3>
                  <p v-for="(i, d) in item.jobDutyList" :key="d">
                    {{ i }}
                  </p>
                </div>
                <div class="mb">
                  <h3>任职要求</h3>
                  <p v-for="(i, d) in item.jobAskList" :key="d">
                    {{ i }}
                  </p>
                </div>
                <div class="mb">
                  <h3>职位关键词</h3>
                  <p>
                    {{ item.jobKeywords }}
                  </p>
                </div>
                <div class="mb">
                  <h3>联系人及邮箱</h3>
                  <p>联系方式：{{ item.contactInformation }}</p>
                  <p style="color: #063e8d">
                    欢迎加入滨沅国科！简历请投递至mailto:jiarr@ydbykj.com
                  </p>
                </div>
                <!-- <el-button
                  >投递简历
                  <img src="../../assets/talentrecruitment/投递简历.png" alt=""
                /></el-button> -->
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <el-pagination
        style="margin-bottom: 5%"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage2"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="pageNum"
      >
      </el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";

export default {
  name: "newsIndex",
  metaInfo: {
    // 设置关键词
    meta: {
      keywords: "加入滨沅,人才招聘,电气工程师,Java工程师",
    },
  },
  components: {
    Harder,
    Footer,
  },
  data() {
    return {
      bannerList: [
        {
          url: require("@/assets/talentrecruitment/人才招聘banner.png"),
          link: "",
        },
      ],
      swiperTime: 7000,
      topList: [
        {
          url: require("@/assets/talentrecruitment/五险一金.png"),
          title: "五险一金",
        },
        {
          url: require("@/assets/talentrecruitment/双休法定假.png"),
          title: "周末双休",
        },
        {
          url: require("@/assets/talentrecruitment/年终奖.png"),
          title: "年终奖",
        },
        {
          url: require("@/assets/talentrecruitment/员工宿舍.png"),
          title: "定期体检",
        },
      ],
      bottomList: [
        {
          url: require("@/assets/talentrecruitment/福利年假.png"),
          title: "带薪年假",
        },
        {
          url: require("@/assets/talentrecruitment/带薪病假.png"),
          title: "节日福利",
        },
        {
          url: require("@/assets/talentrecruitment/弹性办公.png"),
          title: "加班补助",
        },
        {
          url: require("@/assets/talentrecruitment/公司团建.png"),
          title: "多次调薪",
        },
      ],
      activeNames: ["1"],
      input: "",
      dataList: [
        {
          name: "高级电气工程师",
          area: "秦皇岛市",
          year: "3年以上",
          education: "本科及以上",
          people: "3人",
          date: "一天前发布",
          text: [
            {
              p: "01 职位类别：高级电气工程师",
            },
            {
              p: "02 经验要求：3-5年",
            },
            {
              p: "03 职位性质：全职",
            },
            {
              p: "04 学历要求：本科",
            },
            {
              p: "05 行业要求：工业自动化、工程技术与设计服务、人工智能",
            },
            {
              p: "06 薪资范围：7-14k",
            },
            {
              p: "07 招聘人数：2人",
            },
            {
              p: "08 工作地点：秦皇岛/海港区/开发区秦皇岛开发区科技城洋河 道12号楼e谷c区",
            },
          ],
          responsibility: [
            {
              p: "1、参与电气工程工作，提供专业的技术支持;",
            },
            {
              p: "2、参与项目招投标工作，知悉公司投标策略，编制商务标、技术标;",
            },
            {
              p: "3、项目前期现场调研，编写调研报告、需求报告，阅读相关图纸文档;",
            },
            {
              p: "4、组织制定项目总体规划和施工设计，全面负责项目部生产、经营、质量、安全、文明、等一系列管理工作;",
            },
            {
              p: "5、负责整个项目各种施工方案以及进度计划、月、周工作安排编制和落实;",
            },
            {
              p: "6、负责施工现场管理，合理使用物料、机械设备和劳动力，控制各工程项目的施工成本；",
            },
            {
              p: "7、检查电气工程项目的施工进度、质量、安全，并及时解决施工过程中出现的技术问题",
            },
            {
              p: "8、协调解决处理好与业主、监理、分包商以及行业主管部门的关系，确保工程项目正常进行",
            },
            {
              p: "9、按工程进度的“急、慢、先、后”制订设备采购表，以不影响施工连续性为关键;",
            },
            {
              p: "10、组织做好各阶段工程的竣工验收与结算工作。",
            },
          ],
          requirement: [
            {
              p: "1、本科及以上学历，自动化、电气自动化、机电一体化及相关专业；3年以上本专业工作经验；",
            },
            {
              p: "2、具备协调沟通能力，及时处理项目现场问题，全时把控项目进度；",
            },
            {
              p: "3、具备项目组织规划能力及团队管理能力；",
            },
            {
              p: "4、熟悉工控领域的主流PLC编程及组态软件开发，掌握施耐德系列、AB系列、西门子系列编程软件；",
            },
            {
              p: "5、有港口、钢厂、电厂相关工作经验及项目管理经验的优先考虑；",
            },
            {
              p: "6、具备自我学习能力，对于新技术具有钻研精神；",
            },
            {
              p: "7、能够接受出差，能吃苦耐劳，具有较强的独立工作能力，具备良好的沟通能力和团队合作意识。",
            },
          ],
          Jobkeywords:
            "AB/西门子/施耐德 控制算法编程 项目实施 项目管理 技术指导 智能化项目 标书制作 PLC工程师",
          Contactandemail: "贾女士 18730226612（同微信）",
        },
        {
          name: "Java工程师",
          area: "秦皇岛市",
          year: "3年以上",
          education: "本科及以上",
          people: "1人",
          date: "2天前发布",
          text: [
            {
              p: "01 职位类别：高级电气工程师",
            },
            {
              p: "02 经验要求：3-5年",
            },
            {
              p: "03 职位性质：全职",
            },
            {
              p: "04 学历要求：本科",
            },
            {
              p: "05 行业要求：工业自动化、工程技术与设计服务、人工智能",
            },
            {
              p: "06 薪资范围：7-14k",
            },
            {
              p: "07 招聘人数：2人",
            },
            {
              p: "08 工作地点：秦皇岛/海港区/开发区秦皇岛开发区科技城洋河 道12号楼e谷c区",
            },
          ],
          responsibility: [
            {
              p: "1、参与电气工程工作，提供专业的技术支持;",
            },
            {
              p: "2、参与项目招投标工作，知悉公司投标策略，编制商务标、技术标;",
            },
            {
              p: "3、项目前期现场调研，编写调研报告、需求报告，阅读相关图纸文档;",
            },
            {
              p: "4、组织制定项目总体规划和施工设计，全面负责项目部生产、经营、质量、安全、文明、等一系列管理工作;",
            },
            {
              p: "5、负责整个项目各种施工方案以及进度计划、月、周工作安排编制和落实;",
            },
            {
              p: "6、负责施工现场管理，合理使用物料、机械设备和劳动力，控制各工程项目的施工成本；",
            },
            {
              p: "7、检查电气工程项目的施工进度、质量、安全，并及时解决施工过程中出现的技术问题",
            },
            {
              p: "8、协调解决处理好与业主、监理、分包商以及行业主管部门的关系，确保工程项目正常进行",
            },
            {
              p: "9、按工程进度的“急、慢、先、后”制订设备采购表，以不影响施工连续性为关键;",
            },
            {
              p: "10、组织做好各阶段工程的竣工验收与结算工作。",
            },
          ],
          requirement: [
            {
              p: "1、本科及以上学历，自动化、电气自动化、机电一体化及相关专业；3年以上本专业工作经验；",
            },
            {
              p: "2、具备协调沟通能力，及时处理项目现场问题，全时把控项目进度；",
            },
            {
              p: "3、具备项目组织规划能力及团队管理能力；",
            },
            {
              p: "4、熟悉工控领域的主流PLC编程及组态软件开发，掌握施耐德系列、AB系列、西门子系列编程软件；",
            },
            {
              p: "5、有港口、钢厂、电厂相关工作经验及项目管理经验的优先考虑；",
            },
            {
              p: "6、具备自我学习能力，对于新技术具有钻研精神；",
            },
            {
              p: "7、能够接受出差，能吃苦耐劳，具有较强的独立工作能力，具备良好的沟通能力和团队合作意识。",
            },
          ],
          Jobkeywords:
            "AB/西门子/施耐德 控制算法编程 项目实施 项目管理 技术指导 智能化项目 标书制作 PLC工程师",
          Contactandemail: "贾女士 18730226612（同微信）",
        },
        {
          name: "UI设计师",
          area: "秦皇岛市",
          year: "2年以上",
          education: "本科及以上",
          people: "1人",
          date: "1天前发布",
          text: [
            {
              p: "01 职位类别：高级电气工程师",
            },
            {
              p: "02 经验要求：3-5年",
            },
            {
              p: "03 职位性质：全职",
            },
            {
              p: "04 学历要求：本科",
            },
            {
              p: "05 行业要求：工业自动化、工程技术与设计服务、人工智能",
            },
            {
              p: "06 薪资范围：7-14k",
            },
            {
              p: "07 招聘人数：2人",
            },
            {
              p: "08 工作地点：秦皇岛/海港区/开发区秦皇岛开发区科技城洋河 道12号楼e谷c区",
            },
          ],
          responsibility: [
            {
              p: "1、参与电气工程工作，提供专业的技术支持;",
            },
            {
              p: "2、参与项目招投标工作，知悉公司投标策略，编制商务标、技术标;",
            },
            {
              p: "3、项目前期现场调研，编写调研报告、需求报告，阅读相关图纸文档;",
            },
            {
              p: "4、组织制定项目总体规划和施工设计，全面负责项目部生产、经营、质量、安全、文明、等一系列管理工作;",
            },
            {
              p: "5、负责整个项目各种施工方案以及进度计划、月、周工作安排编制和落实;",
            },
            {
              p: "6、负责施工现场管理，合理使用物料、机械设备和劳动力，控制各工程项目的施工成本；",
            },
            {
              p: "7、检查电气工程项目的施工进度、质量、安全，并及时解决施工过程中出现的技术问题",
            },
            {
              p: "8、协调解决处理好与业主、监理、分包商以及行业主管部门的关系，确保工程项目正常进行",
            },
            {
              p: "9、按工程进度的“急、慢、先、后”制订设备采购表，以不影响施工连续性为关键;",
            },
            {
              p: "10、组织做好各阶段工程的竣工验收与结算工作。",
            },
          ],
          requirement: [
            {
              p: "1、本科及以上学历，自动化、电气自动化、机电一体化及相关专业；3年以上本专业工作经验；",
            },
            {
              p: "2、具备协调沟通能力，及时处理项目现场问题，全时把控项目进度；",
            },
            {
              p: "3、具备项目组织规划能力及团队管理能力；",
            },
            {
              p: "4、熟悉工控领域的主流PLC编程及组态软件开发，掌握施耐德系列、AB系列、西门子系列编程软件；",
            },
            {
              p: "5、有港口、钢厂、电厂相关工作经验及项目管理经验的优先考虑；",
            },
            {
              p: "6、具备自我学习能力，对于新技术具有钻研精神；",
            },
            {
              p: "7、能够接受出差，能吃苦耐劳，具有较强的独立工作能力，具备良好的沟通能力和团队合作意识。",
            },
          ],
          Jobkeywords:
            "AB/西门子/施耐德 控制算法编程 项目实施 项目管理 技术指导 智能化项目 标书制作 PLC工程师",
          Contactandemail: "贾女士 18730226612（同微信）",
        },
        {
          name: "PHP工程师",
          area: "秦皇岛市",
          year: "3年以上",
          education: "本科及以上",
          people: "3人",
          date: "一天前发布",
          text: [
            {
              p: "01 职位类别：高级电气工程师",
            },
            {
              p: "02 经验要求：3-5年",
            },
            {
              p: "03 职位性质：全职",
            },
            {
              p: "04 学历要求：本科",
            },
            {
              p: "05 行业要求：工业自动化、工程技术与设计服务、人工智能",
            },
            {
              p: "06 薪资范围：7-14k",
            },
            {
              p: "07 招聘人数：2人",
            },
            {
              p: "08 工作地点：秦皇岛/海港区/开发区秦皇岛开发区科技城洋河 道12号楼e谷c区",
            },
          ],
          responsibility: [
            {
              p: "1、参与电气工程工作，提供专业的技术支持;",
            },
            {
              p: "2、参与项目招投标工作，知悉公司投标策略，编制商务标、技术标;",
            },
            {
              p: "3、项目前期现场调研，编写调研报告、需求报告，阅读相关图纸文档;",
            },
            {
              p: "4、组织制定项目总体规划和施工设计，全面负责项目部生产、经营、质量、安全、文明、等一系列管理工作;",
            },
            {
              p: "5、负责整个项目各种施工方案以及进度计划、月、周工作安排编制和落实;",
            },
            {
              p: "6、负责施工现场管理，合理使用物料、机械设备和劳动力，控制各工程项目的施工成本；",
            },
            {
              p: "7、检查电气工程项目的施工进度、质量、安全，并及时解决施工过程中出现的技术问题",
            },
            {
              p: "8、协调解决处理好与业主、监理、分包商以及行业主管部门的关系，确保工程项目正常进行",
            },
            {
              p: "9、按工程进度的“急、慢、先、后”制订设备采购表，以不影响施工连续性为关键;",
            },
            {
              p: "10、组织做好各阶段工程的竣工验收与结算工作。",
            },
          ],
          requirement: [
            {
              p: "1、本科及以上学历，自动化、电气自动化、机电一体化及相关专业；3年以上本专业工作经验；",
            },
            {
              p: "2、具备协调沟通能力，及时处理项目现场问题，全时把控项目进度；",
            },
            {
              p: "3、具备项目组织规划能力及团队管理能力；",
            },
            {
              p: "4、熟悉工控领域的主流PLC编程及组态软件开发，掌握施耐德系列、AB系列、西门子系列编程软件；",
            },
            {
              p: "5、有港口、钢厂、电厂相关工作经验及项目管理经验的优先考虑；",
            },
            {
              p: "6、具备自我学习能力，对于新技术具有钻研精神；",
            },
            {
              p: "7、能够接受出差，能吃苦耐劳，具有较强的独立工作能力，具备良好的沟通能力和团队合作意识。",
            },
          ],
          Jobkeywords:
            "AB/西门子/施耐德 控制算法编程 项目实施 项目管理 技术指导 智能化项目 标书制作 PLC工程师",
          Contactandemail: "贾女士 18730226612（同微信）",
        },
        {
          name: "Python工程师",
          area: "秦皇岛市",
          year: "3年以上",
          education: "本科及以上",
          people: "3人",
          date: "一天前发布",
          text: [
            {
              p: "01 职位类别：高级电气工程师",
            },
            {
              p: "02 经验要求：3-5年",
            },
            {
              p: "03 职位性质：全职",
            },
            {
              p: "04 学历要求：本科",
            },
            {
              p: "05 行业要求：工业自动化、工程技术与设计服务、人工智能",
            },
            {
              p: "06 薪资范围：7-14k",
            },
            {
              p: "07 招聘人数：2人",
            },
            {
              p: "08 工作地点：秦皇岛/海港区/开发区秦皇岛开发区科技城洋河 道12号楼e谷c区",
            },
          ],
          responsibility: [
            {
              p: "1、参与电气工程工作，提供专业的技术支持;",
            },
            {
              p: "2、参与项目招投标工作，知悉公司投标策略，编制商务标、技术标;",
            },
            {
              p: "3、项目前期现场调研，编写调研报告、需求报告，阅读相关图纸文档;",
            },
            {
              p: "4、组织制定项目总体规划和施工设计，全面负责项目部生产、经营、质量、安全、文明、等一系列管理工作;",
            },
            {
              p: "5、负责整个项目各种施工方案以及进度计划、月、周工作安排编制和落实;",
            },
            {
              p: "6、负责施工现场管理，合理使用物料、机械设备和劳动力，控制各工程项目的施工成本；",
            },
            {
              p: "7、检查电气工程项目的施工进度、质量、安全，并及时解决施工过程中出现的技术问题",
            },
            {
              p: "8、协调解决处理好与业主、监理、分包商以及行业主管部门的关系，确保工程项目正常进行",
            },
            {
              p: "9、按工程进度的“急、慢、先、后”制订设备采购表，以不影响施工连续性为关键;",
            },
            {
              p: "10、组织做好各阶段工程的竣工验收与结算工作。",
            },
          ],
          requirement: [
            {
              p: "1、本科及以上学历，自动化、电气自动化、机电一体化及相关专业；3年以上本专业工作经验；",
            },
            {
              p: "2、具备协调沟通能力，及时处理项目现场问题，全时把控项目进度；",
            },
            {
              p: "3、具备项目组织规划能力及团队管理能力；",
            },
            {
              p: "4、熟悉工控领域的主流PLC编程及组态软件开发，掌握施耐德系列、AB系列、西门子系列编程软件；",
            },
            {
              p: "5、有港口、钢厂、电厂相关工作经验及项目管理经验的优先考虑；",
            },
            {
              p: "6、具备自我学习能力，对于新技术具有钻研精神；",
            },
            {
              p: "7、能够接受出差，能吃苦耐劳，具有较强的独立工作能力，具备良好的沟通能力和团队合作意识。",
            },
          ],
          Jobkeywords:
            "AB/西门子/施耐德 控制算法编程 项目实施 项目管理 技术指导 智能化项目 标书制作 PLC工程师",
          Contactandemail: "贾女士 18730226612（同微信）",
        },
      ],
      imgHeight: "calc(100vw * 800 / 1920)",
      currentPage2: 1,
      pageNum: 0,
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.workJoblist();
  },
  methods: {
    handleChange(val) {},
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.pageNum = val;
    },
    async workJoblist() {
      let result = await this.$store.dispatch("workJoblist", {
        pageNum: this.pageNum,
        pageSize: 10,
        jobName: this.input,
      });
      this.dataList = result.data.rows;
      this.pageNum = result.data.total;
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    // margin-right: 40px;
    // margin-bottom: 10%;
    display: none;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

// 修改滚动条样式
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 20px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  // box-shadow: inset 0 0 5px rgba(0, 123, 255, 0.2);
  background: rgb(208, 208, 208);
  height: 40px !important;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgb(239, 239, 239);
}

.productCenterIndexDiv {
  width: 100%;

  #w100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #bx {
    width: calc(100vw * 1200 / 1920);
    // margin-top: 3%;
    .car {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2%;
      user-select: none;

      div {
        width: 48%;
        height: calc(100vw * 200 / 1920);
        display: flex;
        flex-direction: column;
        align-items: center;
        // border-bottom: 1px solid #e7e7e7;
        // cursor: pointer;
        transition: transform 0.3s ease-out;
        // background: beige;
        img {
          margin-top: 3%;
          width: calc(100vw * 64 / 1920);
          height: calc(100vw * 64 / 1920);
        }
        h3 {
          margin-top: 3%;
          font-weight: 700;
          font-size: calc(100vw * 16 / 1920);
        }
        p {
          margin-top: 3%;
          width: 70%;
          text-align: center;
          font-size: calc(100vw * 14 / 1920);
          color: #333333;
        }
      }
      div:hover {
        transform: translateY(-10px);
        h3 {
          color: #006aff;
        }
        p {
          color: black;
          //   font-weight: 700;
        }
      }
    }
    .topDiv {
      display: flex;
      margin-bottom: 2%;
      margin-top: 2%;
      .dataDivLeft {
        width: 47%;
        // height: 100%;
        // background: #c7e49f;
        .d1 {
          display: flex;
          align-items: center;
          font-size: calc(100vw * 20 / 1920);
          div {
            width: 15%;
            // margin-left: 2%;
            border: 1px solid rgba(128, 142, 162, 0.1);
          }
        }
        .d2 {
          font-size: calc(100vw * 14 / 1920);
          color: rgba(6, 62, 141, 0.1);
          margin-top: 1%;
          margin-bottom: 3%;
        }
      }
    }
    .ygfl {
      width: 100%;
      height: calc(100vw * 400 / 1920);
      // background: beige;
      position: relative;
      .top {
        width: 100%;
        height: 40%;
        margin-top: 1%;
        // background: #d79a9e;
        display: flex;
        div {
          width: calc(100vw * 285 / 1920);
          height: calc(100vw * 153 / 1920);
          background: rgba(255, 255, 255, 0.5);
          margin-right: 2%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // cursor: pointer;
          transition: transform 0.3s ease-out;
          img {
            margin-bottom: 5%;
          }
        }
        div:hover {
          transform: translateY(-10px);
          border-bottom: calc(100vw * 3 / 1920) solid #006aff;
          background: rgba(255, 255, 255, 0.7);
        }
      }
      .bottom {
        width: 100%;
        height: 40%;
        // background: #407d8c;
        position: absolute;
        bottom: 0;
        display: flex;
        div {
          width: calc(100vw * 285 / 1920);
          height: calc(100vw * 153 / 1920);
          background: rgba(255, 255, 255, 0.5);
          margin-right: 2%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // cursor: pointer;
          transition: transform 0.3s ease-out;
          img {
            margin-bottom: 5%;
          }
        }
        div:hover {
          transform: translateY(-10px);
          border-bottom: calc(100vw * 3 / 1920) solid #006aff;
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .inputDiv {
      width: 100%;
      display: flex;
      margin-bottom: 3%;
      .el-input {
        width: 75%;
        height: calc(100vw * 50 / 1920);
        border: none; /*去掉边框*/
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        /deep/ .el-input__inner {
          height: calc(100vw * 50 / 1920);
          padding-left: 3%;
          font-size: 28px !important;
        }
      }
      .el-button {
        width: 25%;
        height: calc(100vw * 50 / 1920);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        font-size: calc(100vw * 16 / 1920);
        background: #1677ff;
      }
    }
    .anyNum {
      width: 100%;
      height: calc(100vw * 30 / 1920);
      // border-bottom: 1px solid #efefef;
      margin-bottom: 2%;
      font-size: calc(100vw * 16 / 1920);
      p {
        color: #888888;
        span {
          color: #1677ff;
        }
      }
    }
    .selectDiv {
      /deep/ .el-collapse-item__header {
        height: calc(100vw * 133 / 1920); /*更改高度*/
        display: flex;
        flex-direction: column;
        text-align: left;
        position: relative;
      }
      .el-collapse-item__header h1 {
        font-size: calc(100vw * 20 / 1920);
        font-weight: bold;
        margin-bottom: 5px;
        color: #333333;
        position: absolute;
        left: 0;
        top: 20%;
      }
      /deep/ .el-collapse-item__arrow {
        position: absolute;
        right: 0;
        top: 50%;
      }

      .el-collapse-item__header p {
        font-size: 14px;
        color: #999;
        position: absolute;
        left: 0;
        top: 60%;
      }
      .mt {
        margin-top: 2%;
      }
      .mb {
        margin-bottom: 2%;
        h3 {
          font-weight: 700;
          font-size: calc(100vw * 16 / 1920);
          color: #333333;
          margin-bottom: 1%;
        }
        p {
          font-size: calc(100vw * 14 / 1920);
          color: #333333;
        }
      }
      /deep/ .el-button {
        width: calc(100vw * 160 / 1920);
        height: calc(100vw * 40 / 1920);
        background: #d91724;
        border-radius: 2px;
        color: white;
        font-size: calc(100vw * 16 / 1920);
      }
    }
  }
  .backImg {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/talentrecruitment/yuangonfuli.png");
    background-size: 100% 100%;
    height: calc(100vw * 553 / 1920) !important;
  }
  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .swiperDiv {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .topDivimg {
      width: 100%;
      height: calc(100vw * 200 / 1920);
      // background: url("../../assets/productCenterMsg/topHeader.png");
      // background-size: 100% 100%;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      //   margin-bottom: 2%;
      // padding-bottom: 3%;
      .topDiv {
        width: calc(100vw * 1200 / 1920);
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 2%;
        header {
          width: 80% !important;
          height: calc(100vw * 186 / 1920);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          //   margin-bottom: 3%;

          div:nth-child(1) {
            font-size: calc(100vw * 64 / 1920);
            color: #eff1f4;
            position: absolute;
            top: 20%;
          }
          div:nth-child(2) {
            font-size: calc(100vw * 30 / 1920);
            font-weight: 700;
            position: absolute;
            top: 40%;
            // color: #eff1f4;
          }
          div:nth-child(3) {
            width: 3%;
            height: 8px;
            position: absolute;
            top: 75%;
            background: #d91724;
          }
          div:nth-child(4) {
            position: absolute;
            font-size: calc(100vw * 16 / 1920);
            top: 90%;
          }
        }
      }
    }
  }
}
</style>
