<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import Harder from "./components/Harder.vue";
export default {
  data() {
    return {
      userAgent: "", // 用户代理字符串
      isMobile: false, // 是否为移动设备
      isTablet: false, // 是否为平板设备
      isDesktop: false, // 是否为桌面设备
    };
  },
  name: "App",
  components: {
    // Harder,
  },
  mounted() {
    // if (this.$route.path == "/index") {
    //   document.title = "滨沅国科（秦皇岛）智能科技股份有限公司";
    // }
    // if (this.$route.path == "/home") {
    //   document.title = "首页";
    // }
    // if (this.$route.path == "/aboutIndex") {
    //   document.title = "关于滨沅";
    // }
    // if (this.$route.path == "/contactusIndex") {
    //   document.title = "联系我们";
    // }
    // 获取用户代理字符串
    this.userAgent = window.navigator.userAgent.toLowerCase();
    // 检测设备类型
    this.isMobile = /mobile|android|ios|iphone|ipad|ipod/.test(this.userAgent);
    this.isTablet = /tablet|ipad/.test(this.userAgent);
    this.isDesktop = !this.isMobile && !this.isTablet;
    if (this.isTablet || this.isMobile) {
      window.location.href = "http://m.ydbykj.com/";
    }
    setTimeout(() => {
      if (this.isTablet || this.isMobile) {
        window.location.href = "http://m.ydbykj.com/";
      }
    }, 2000);
  },
  watch: {
    $route(to, from) {
      // if (this.isMobile && to.path == "/index") {
      //   this.$router.push("/aboutIndex");
      // }
      // if (this.isMobile && to.path == "/home") {
      //   this.$router.push("/aboutIndex");
      // }
      // 当路由发生变化时执行的代码
      // this.$router.go(0);
      window.scrollTo(0, 0); // 将滚动条置顶
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  width: 100%;
  height: 100%;
}
</style>
