<template>
  <div class="productCenterIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <img src="../../assets/productCenterMsg/sbdata/banner1.png" alt="" />
      </div>
      <div class="w100">
        <div class="bx">
          <div class="title">
            <h1 v-show="type == 1">设备管控</h1>
            <h1 v-show="type == 2">安全监管</h1>
            <h1 v-show="type == 3">生产管控</h1>
            <h1 v-show="type == 4">环保管控</h1>
            <h1 v-show="type == 5">智能控制</h1>
            <h1 v-show="type == 6">智能运维</h1>
            <p>
              <span @click="goPush">产品中心</span>>{{ typeStr }}（{{
                data.productTitle
              }}）
            </p>
          </div>
          <div class="data1">
            <div class="left">
              <!-- :src="'http://47.92.6.236/gw/prod-api' + data.productImg" -->
              <img :src="'gw/prod-api' + data.productImg" alt="" />
            </div>
            <div class="right">
              <h3>{{ data.productTitle }}</h3>
              <p>
                {{ data.productExplain }}
              </p>
            </div>
          </div>
          <div class="hrDiv" v-if="data.haveImgs"></div>
          <div class="topDiv" v-if="data.haveImgs">
            <div class="str">INTERFACE DISPLAY</div>
            <div class="title">部分界面展示</div>
            <div class="l"></div>
            <div class="r"></div>
          </div>
        </div>
        <div class="w100">
          <div class="bx">
            <div class="swiperDiv" v-if="data.haveImgs">
              <!-- <el-carousel :interval="4000" type="card" :height="swiperHeight">
            <el-carousel-item v-for="item in data.imagesList" :key="item">
              <img :src="'http://47.92.6.236/gw/prod-api' + item" alt="" />
            </el-carousel-item>
          </el-carousel> -->
              <el-carousel
                trigger="click"
                :autoplay="false"
                class="swiper"
                :height="imgHeight"
              >
                <el-carousel-item
                  v-for="(item, index) in data.imagesList"
                  :key="index"
                >
                  <img :src="'gw/prod-api' + item" alt="" />
                </el-carousel-item>
              </el-carousel>
              <!-- <el-carousel indicator-position="outside">
            <el-carousel-item v-for="item in data.imagesList" :key="item">
              <img :src="'http://47.92.6.236/gw/prod-api' + item" alt="" />
            </el-carousel-item>
          </el-carousel> -->
            </div>
          </div>
        </div>

        <div class="w100" v-if="data.haveImgs">
          <div class="textCenter">{{ data.productTitle }}</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";
export default {
  name: "sbdata",
  components: {
    Harder,
    Footer,
  },
  data() {
    return {
      bannerList: [
        {
          url: require("@/assets/productCenterMsg/产品二.png"),
          link: "",
        },
      ],
      swiperHeight: "calc(100vw * 559 / 1920)",
      type: "",
      typeStr: "",
      id: "",
      data: "",
      imgHeight: "calc(100vw * 800 / 1920)",
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    // 获取名为type的参数的值
    this.type = this.$route.query.type;
    // 获取名为id的参数的值
    this.id = this.$route.query.id;
    if (this.type == 1) {
      this.typeStr = "设备管控";
    }
    if (this.type == 2) {
      this.typeStr = "安全监管";
    }
    if (this.type == 3) {
      this.typeStr = "生产管控";
    }
    if (this.type == 4) {
      this.typeStr = "环保管控";
    }
    this.getData();
  },
  methods: {
    goPush() {
      this.$router.push(`/productCenterIndex`);
      // location.href = "/productCenterIndex";
    },
    async getData() {
      let result = await this.$store.dispatch("get", {
        productId: this.id,
      });
      this.data = result.data.data;
    },
  },
  watch: {
    "$route.query.id"(newId, oldId) {
      if (newId) {
        this.$router.go(0);
        this.getData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    margin-right: 40px;
    margin-bottom: 10%;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

.productCenterIndexDiv {
  width: 100%;

  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .swiperDiv {
      width: 100%;
      height: calc(100vw * 700 / 1920);
      img {
        width: 100%;
        height: calc(100vw * 700 / 1920);
      }
    }
    .w100 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .bx {
        width: calc(100vw * 1200 / 1920);
        padding-top: 3%;
        padding-bottom: 1%;
        .title {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 2%;
          margin-bottom: 4%;
          h1 {
            font-size: calc(100vw * 24 / 1920);
            font-weight: 400;
            color: #333333;
          }
          p {
            font-size: calc(100vw * 16 / 1920);
            span {
              color: #063e8d;
              cursor: pointer;
            }
          }
        }
        .data1 {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 4%;
          .left {
            width: 40%;
            img {
              width: calc(100vw * 540 / 1920);
              border-radius: calc(100vw * 10 / 1920);
              height: calc(100vw * 304 / 1920);
              box-shadow: 14px 14px 14px rgba(0, 0, 0, 0.2);
            }
          }
          .right {
            width: 50%;
            h3 {
              font-size: calc(100vw * 24 / 1920);
              font-weight: 700;
              color: #333333;
              margin-bottom: 3%;
            }
            p {
              font-size: calc(100vw * 14 / 1920);
              line-height: calc(100vw * 30 / 1920);
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: unset;
            }
          }
        }
        .hrDiv {
          width: 100%;
          background: #e0e0e0;
          height: calc(100vw * 1 / 1920);
          margin-bottom: 5%;
        }
        .topDiv {
          display: flex;
          //   margin-bottom: 5%;
          width: 100%;
          position: relative;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: calc(100vw * 100 / 1920);
          .str {
            width: calc(100vw * 356 / 1920);
            height: calc(100vw * 42 / 1920);
            font-weight: 700;
            font-size: calc(100vw * 32 / 1920);
            color: rgba(51, 51, 51, 0.1);
            position: absolute;
            top: 0;
            left: 40%;
          }
          .title {
            font-weight: 400;
            font-size: calc(100vw * 32 / 1920);
            color: #333333;
            position: absolute;
            top: 20%;
            left: 44%;
          }
          .l {
            width: calc(100vw * 40 / 1920);
            height: 0px;
            border: 1px solid #333333;
            position: absolute;
            top: 30%;
            left: 35%;
          }
          .r {
            width: calc(100vw * 40 / 1920);
            height: 0px;
            border: 1px solid #333333;
            position: absolute;
            top: 30%;
            left: 66%;
          }
        }
        .swiperDiv {
          width: 100%;
          /deep/ .el-carousel__indicators {
            display: none;
          }
          img {
            width: 100%;
            height: calc(100vw * 700 / 1920);
            border-radius: calc(100vw * 10 / 1920);
          }
        }
      }

      .textCenter {
        width: 100%;
        text-align: center;
        margin-bottom: 3%;
      }
    }

    .zhudaochanping {
      width: 100%;
      height: calc(100vw * 800 / 1920);
      background: url("../../assets/productCenterMsg/主导产品.png");
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      padding-top: 3%;
      box-sizing: border-box; /* 添加这一行代码 */
      user-select: none;
      h2 {
        font-size: calc(100vw * 30 / 1920);
        font-weight: 700;
        margin-bottom: 2%;
      }
      p {
        width: calc(100vw * 832 / 1920);
        font-size: calc(100vw * 14 / 1920);
        text-align: center;
      }
    }
  }
}
</style>
