<template>
  <div id="contener">
    <div class="backWhite">
      <div class="contenerDiv">
        <header>
          <div>PROPERTY</div>
          <div>知识产权</div>
          <div></div>
          <div>目前拥有散货港口智能化控制关键技术等专利和软著证书30+...</div>
        </header>
        <div class="dataDiv">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>专利证书</h3>
                <div></div>
              </div>
              <div class="d2">ABOUT US</div>
              <!-- <div class="d3" v-for="(item, index) in leftList" :key="index">
                {{ item.text }}
              </div> -->
            </div>
            <!-- <div class="dataDivRight">
              <div class="d3" v-for="(item, index) in rightList" :key="index">
                {{ item.text }}
              </div>
            </div> -->
          </div>
          <div class="bottomDiv">
            <img
              src="../../assets/Qualificationcertification/专利/专利.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="backWhite">
        <div class="contenerDiv">
          <div class="dataDiv2">
            <div class="topDiv">
              <div class="dataDivLeft">
                <div class="d1">
                  <h3>著作权证书</h3>
                  <div></div>
                </div>
                <div class="d2">ABOUT US</div>
              </div>
            </div>
            <div class="center">
              <img
                src="../../assets/Qualificationcertification/著作/软著.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
// import "swiper/swiper-bundle.css";
import Zzzs from "./zzzs.vue";
import Ryzs from "./ryzs.vue";

export default {
  name: "Intellectualproperty",
  components: {
    // swiper,
    // swiperSlide,
    Zzzs,
    Ryzs,
  },
  data() {
    return {
      leftList: [
        {
          text: "· 3A资信等级证书-2021.9.8",
        },
        {
          text: "· 环境管理体系认证证书-2021.8.19-2024.8.18",
        },
        {
          text: "· 建筑业企业资质证书-2021.5.16-2026.5.16（机电工程施工总承包参级）",
        },
        {
          text: "· 质量管理体系认证证书-2020.9.11-2023.9.10",
        },
        {
          text: "· 3A资信等级证书-2021.9.8",
        },
        {
          text: "· 职业健康安全管理体系证书-2021.8.19",
        },
      ],
      rightList: [
        {
          text: "· 建筑业企业资质证书-2022.2.9-2027.2.8（电子与智能化工程专业承包贰级）",
        },
        {
          text: "· 河北省工业企业研发机构（C级）-散货港口智能控制研发中心",
        },
      ],
      nextImg: 5000,
      activeName: "first",
    };
  },
  computed: {
    // nextImg() {
    //   return this.zzzsList.length > 4 ? 3000 : 0;
    // },
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {},
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-carousel__indicators {
  // 指示灯位置
  // left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    // margin-right: 40px;
    // margin-bottom: 10%;
    display: none;
  }
}

#contener {
  width: 100%;
  // height: calc(100vw * 950 / 1920);
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  // overflow: auto;
  .backWhite {
    width: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    z-index: 1;
    position: relative;
    .contenerDiv {
      width: calc(100vw * 1200 / 1920);
      header {
        width: 100% !important;
        height: calc(100vw * 186 / 1920);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 5%;
        div:nth-child(1) {
          font-size: calc(100vw * 64 / 1920);
          color: #eff1f4;
          position: absolute;
          top: 20%;
        }
        div:nth-child(2) {
          font-size: calc(100vw * 30 / 1920);
          font-weight: 700;
          position: absolute;
          top: 40%;
          // color: #eff1f4;
        }
        div:nth-child(3) {
          width: 3%;
          height: 8px;
          position: absolute;
          top: 75%;
          background: #d91724;
        }
        div:nth-child(4) {
          position: absolute;
          font-size: calc(100vw * 16 / 1920);
          width: 70%;
          top: 90%;
          text-align: center;
        }
      }
      .dataDiv {
        width: 100%;
        height: calc(100vw * 1000 / 1920);
        .topDiv {
          display: flex;
          height: calc(100vw * 150 / 1920);
          .dataDivLeft {
            width: 47%;
            height: 100%;
            // background: #c7e49f;
            .d1 {
              display: flex;
              align-items: center;
              font-size: calc(100vw * 20 / 1920);
              div {
                width: 15%;
                // margin-left: 2%;
                border: 1px solid rgba(128, 142, 162, 0.1);
              }
            }
            .d2 {
              font-size: calc(100vw * 14 / 1920);
              color: rgba(6, 62, 141, 0.1);
              margin-top: 1%;
              margin-bottom: 3%;
            }
            .d3 {
              width: 95%;
              // letter-spacing: 5px;
              // margin-top: 3%;
              height: 5%;
              font-size: calc(100vw * 14 / 1920);
              // text-indent: 2em; /* 添加两个空格 */
              word-wrap: break-word; /* 自动换行 */
              overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .dataDivRight {
            width: 53%;
            height: 100%;
            // background: #d0d0d0;
            position: relative;
            padding-top: 6%;
            .d3 {
              width: 95%;
              // letter-spacing: 5px;
              // margin-top: 3%;
              height: 5%;
              font-size: calc(100vw * 14 / 1920);
              // text-indent: 2em; /* 添加两个空格 */
              word-wrap: break-word; /* 自动换行 */
              overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .bottomDiv {
          width: 100%;
          // height: calc(100vw * 100 / 1920);
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: calc(100vw * 1030 / 1920);
          }
        }
      }
    }
    .dataDiv2 {
      margin-top: 5%;
      width: 100%;
      .topDiv {
        display: flex;
        height: calc(100vw * 110 / 1920);
        .dataDivLeft {
          width: 47%;
          height: 100%;
          // background: #c7e49f;
          .d1 {
            display: flex;
            align-items: center;
            font-size: calc(100vw * 20 / 1920);
            div {
              width: 15%;
              // margin-left: 2%;
              border: 1px solid rgba(128, 142, 162, 0.1);
            }
          }
          .d2 {
            font-size: calc(100vw * 14 / 1920);
            color: rgba(6, 62, 141, 0.1);
            margin-top: 1%;
            margin-bottom: 3%;
          }
          .d3 {
            width: 95%;
            // letter-spacing: 5px;
            // margin-top: 3%;
            height: 5%;
            font-size: calc(100vw * 14 / 1920);
            // text-indent: 2em; /* 添加两个空格 */
            word-wrap: break-word; /* 自动换行 */
            overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .dataDivRight {
          width: 53%;
          height: 100%;
          // background: #d0d0d0;
          position: relative;
          padding-top: 6%;
          .d3 {
            width: 95%;
            // letter-spacing: 5px;
            // margin-top: 3%;
            height: 5%;
            font-size: calc(100vw * 14 / 1920);
            // text-indent: 2em; /* 添加两个空格 */
            word-wrap: break-word; /* 自动换行 */
            overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .center {
        // /deep/ .el-tabs__nav-scroll {
        //   display: flex;
        //   justify-content: center;
        // }
        // /deep/ .el-tabs__item {
        //   width: calc(100vw * 150 / 1920);
        //   text-align: center;
        //   font-size: calc(100vw * 16 / 1920);
        //   color: #000000;
        //   font-weight: 600;
        //   margin-bottom: 2%;
        // }
        // /deep/ .el-tabs__active-bar {
        //   background: #d91724;
        //   height: calc(100vw * 2 / 1920);
        // }
        img {
          width: calc(100vw * 1200 / 1920);
          height: calc(100vw * 1757 / 1920);
        }
      }
    }
  }
}
</style>
