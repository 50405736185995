import Vue from "vue";
import Vuex from "vuex";
import ajax from "./ajax";
Vue.use(Vuex);
const state = {
  queryRegistComponents: false,
  indexPage: 1,
  // dialogImgBool: false,
  dialogImgurl: "",
  routerPush: false,
  currentIndex: 0,
};
const getters = {};
const actions = {
  //产品中心获取产品
  async typeproduct(context, { productType, rows }) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/product/open/typeproduct",
      data: {
        productType: productType,
        rows: rows,
      },
    });
    return result;
  },
  //产品详细信息
  async get(context, { productId }) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/product/open/get",
      data: {
        productId: productId,
      },
    });
    return result;
  },
  //行业新闻
  async hninfo(context) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/information/open/hninfo",
      // data: {
      //   productId: productId,
      // },
    });
    return result;
  },
  //公司新闻
  async ydinfo(context) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/information/open/ydinfo",
      // data: {
      //   productId: productId,
      // },
    });
    return result;
  },
  //公司新闻 || 行业新闻  新闻列表
  async list(context, { infoType, pageNum, pageSize }) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/information/open/list",
      data: {
        infoType: infoType,
        pageNum: pageNum,
        pageSize: pageSize,
      },
    });
    return result;
  },
  //获取详细新闻信息
  async getData(context, { infoId }) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/information/open/get",
      data: {
        infoId: infoId,
      },
    });
    return result;
  },
  //发展历程
  async developlist(context) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/develop/open/developlist",
      data: {
        // infoId: infoId,
      },
    });
    return result;
  },
  //人才招聘信息
  async workJoblist(context, { pageNum, pageSize, jobName }) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/jobs/open/workJoblist",
      data: {
        pageNum: pageNum,
        pageSize: pageSize,
        jobName: jobName,
      },
    });
    return result;
  },
  //底部导航免费资讯
  async smsSendMsg(context, { phoneNumber }) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/sms/smsSendMsg",
      data: {
        phoneNumber: phoneNumber,
      },
    });
    return result;
  },
  //联系我们-在线留言
  async insertOne(
    context,
    { leveContacts, levePhone, leveEmail, leveAddr, leveContent }
  ) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/msg/open/insertOne",
      data: {
        leveContacts: leveContacts, //联系人
        levePhone: levePhone, //联系电话
        leveEmail: leveEmail, //邮箱
        leveAddr: leveAddr, //地址
        leveContent: leveContent, //留言内容
      },
    });
    return result;
  },
  //获取验证码
  async sendCode(context, { phone }) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/sms/sendCode",
      data: {
        phone: phone,
      },
    });
    return result;
  },
  //获取验证码
  async partnership() {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/partnership/open/list",
      // data: {
      //   phone: phone,
      // },
    });
    return result;
  },
  //资讯详细信息接口-了解更多
  async insertRow(
    context,
    {
      contactName,
      phone,
      email,
      company,
      province,
      industry,
      position,
      demand,
      code,
    }
  ) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/contact/open/insertRow",
      data: {
        contactName: contactName,
        phone: phone, //手机
        email: email, //邮箱
        company: company, //公司
        province: province, //省份
        industry: industry, //行业
        position: position, //职位
        demand: demand, //需求
        companyIdentity: 1, //公司分类 1 终端用户 2中间商
        code: code, //验证码
      },
    });
    return result;
  },
  //产品解决方案-核心业务
  async coreproduct(context) {
    let result = await ajax({
      method: "POST",
      baseURL: "/gw/prod-api",
      url: "/ydby/product/open/coreproduct",
    });
    return result;
  },
};
const mutations = {};
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
