<template>
  <div
    class="full-screen-section"
    :style="{ transform: 'translateY(' + translateY + '%)' }"
  >
    <div
      class="full-screen-video-section section1"
      :class="{ active: this.$store.state.currentIndex === 0 }"
    >
      <video autoplay muted loop>
        <source src="../../assets/home/第1个.mp4" type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="content">
        <el-button
          class="elButton"
          round
          @click="$router.push('/productCenterIndex')"
          >了解更多</el-button
        >
      </div>
    </div>
    <div
      class="full-screen-video-section section2"
      :class="{ active: $store.state.currentIndex === 1 }"
    >
      <video autoplay muted loop>
        <source src="../../assets/home/lizi.mp4" type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="content">
        <h1
          style="
            font-size: calc(100vw * 48 / 1920);
            margin-bottom: 2%;
            user-select: none;
          "
        >
          引领散货装卸无人化建设，构筑互联网+的智能运维平台
        </h1>
        <h3
          style="
            font-size: calc(100vw * 24 / 1920);
            font-weight: 400;
            user-select: none;
          "
        >
          设备管控自动化、生产管控智能化
        </h3>
        <el-button
          class="elButton"
          round
          @click="$router.push('/productCenterIndex')"
          >了解更多 》</el-button
        >
      </div>
    </div>
    <div
      class="full-screen-video-section section3"
      :class="{ active: $store.state.currentIndex === 2 }"
    >
      <!-- <video autoplay muted loop>
        <source
          src="https://www.w3schools.com/html/mov_bbb.mp4"
          type="video/mp4"
        />
      </video> -->
      <video autoplay muted loop>
        <source src="../../assets/home/swiper3.mp4" type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="content">
        <el-button
          class="elButton"
          round
          @click="$router.push('/productCenterIndex')"
          >了解更多 》</el-button
        >
      </div>
    </div>
    <div
      class="full-screen-video-section section4"
      :class="{ active: $store.state.currentIndex === 3 }"
    >
      <!-- <video autoplay muted loop>
        <source
          src="https://www.w3schools.com/html/mov_bbb.mp4"
          type="video/mp4"
        />
      </video> -->
      <video autoplay muted loop>
        <source src="../../assets/home/daji.mp4" type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="content" style="position: relative">
        <h1
          style="
            position: absolute;
            top: 12%;
            font-size: calc(100vw * 48 / 1920);
            left: 37%;
            user-select: none;
          "
        >
          数字孪生仿真建模系统
        </h1>
        <h2
          style="
            position: absolute;
            top: 20%;
            font-size: calc(100vw * 28 / 1920);
            left: 42%;
            font-weight: 400;
            user-select: none;
          "
        >
          ——虚拟仿真技术赋能工厂数字化转型
        </h2>
      </div>
    </div>
    <div
      class="full-screen-video-section section5"
      :class="{ active: $store.state.currentIndex === 4 }"
    >
      <video autoplay muted loop>
        <source src="../../assets/home/ditu.mp4" type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="content" style="position: relative">
        <h1
          style="
            position: absolute;
            top: 35%;
            font-size: calc(100vw * 48 / 1920);
            left: 15%;
            user-select: none;
            font-weight: 600;
          "
        >
          业务范围分布广阔，持续为客户创造价值
        </h1>
        <p
          style="
            position: absolute;
            top: 45%;
            font-size: calc(100vw * 18 / 1920);
            left: 15%;
            width: calc(100vw * 569 / 1920);
            user-select: none;
            text-align: left;
          "
        >
          公司具有开展全球范围业务能力，项目产品已经逐步拓展至环渤海、长三角、珠三角、东南沿海、西南沿海等省份，未来将进一步开拓其他地区市场。
        </p>
        <el-button class="elButton" round @click="$router.push('/aboutIndex')"
          >了解更多 》</el-button
        >
      </div>
    </div>
    <div
      class="full-screen-video-section section6"
      :class="{ active: $store.state.currentIndex === 5 }"
    >
      <video autoplay muted loop>
        <source src="../../assets/home/环形.mp4" type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="content"></div>
    </div>
    <div
      class="full-screen-video-section section7"
      :class="{ active: $store.state.currentIndex === 6 }"
    >
      <video autoplay muted loop>
        <source src="../../assets/home/虚拟展厅视频.mp4" type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="content"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden !important;
}

.full-screen-section {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.8s ease-in-out;
}

.full-screen-video-section {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.full-screen-video-section video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}
.full-screen-video-section img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.full-screen-video-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.full-screen-video-section .content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  height: 100%;
}

/* 设置每个section的位置 */
.section1 {
  // position: relative;
  top: 0;
  .elButton {
    position: absolute;
    top: 60%;
    left: 20%;
    background-color: #d91724 !important;
    color: white !important;
    border: none !important;
    width: 12% !important;
    height: 6% !important;
    font-size: calc(100vw * 20 / 1920) !important;
    // border-radius: 5%;
  }
  .elButton:hover {
    /* font-weight: 800 !important; */
    transform: scale(1.1);
    /* font-size: 48px !important; */
    transition: transform 0.3s ease-in-out;
  }
}
.section2 {
  top: 100%;
  .elButton {
    position: absolute;
    top: 63%;
    left: 44%;
    background-color: #d91724 !important;
    color: white !important;
    border: none !important;
    width: 12% !important;
    height: 6% !important;
    font-size: calc(100vw * 20 / 1920) !important;
    // border-radius: 5%;
  }
}
.section3 {
  top: 200%;
  .elButton {
    position: absolute;
    top: 72%;
    left: 50%;
    background-color: #d91724 !important;
    color: white !important;
    border: none !important;
    width: 12% !important;
    height: 6% !important;
    font-size: calc(100vw * 20 / 1920) !important;
    // border-radius: 5%;
  }
}
.section4 {
  top: 300%;
}
.section5 {
  top: 400%;
  .elButton {
    position: absolute;
    top: 60%;
    left: 15%;
    background-color: #d91724 !important;
    color: white !important;
    border: none !important;
    width: 12% !important;
    height: 6% !important;
    font-size: calc(100vw * 20 / 1920) !important;
    // border-radius: 5%;
  }
}
.section6 {
  top: 500%;
}
.section7 {
  top: 600%;
}

/* 设置当前活动的section的样式 */
.active {
  display: block;
}
</style>

<script>
import { mapState } from "vuex";
export default {
  metaInfo: {
    // 设置关键词
    meta: {
      keywords: "智慧港口,只能运维平台,数字孪生,",
    },
  },
  data() {
    return {
      // this.$store.state.currentIndex: 0,
      translateY: 0,
      wheelCount: 0,
      WHEEL_THRESHOLD: 4,
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "hidden"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "hidden"; // 设置溢出内容为隐藏
    });
  },
  computed: {
    ...mapState(["currentIndex"]),
  },
  mounted() {
    window.addEventListener("wheel", this.handleWheel);
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleWheel);
  },
  methods: {
    handleWheel(event) {
      this.wheelCount++;
      if (this.wheelCount >= this.WHEEL_THRESHOLD) {
        const deltaY = event.deltaY;
        if (deltaY > 0 && this.$store.state.currentIndex < 6) {
          this.$store.state.currentIndex++;
          this.translateY -= 100;
        } else if (deltaY < 0 && this.$store.state.currentIndex > 0) {
          this.$store.state.currentIndex--;
          this.translateY += 100;
        }
        if (this.$store.state.currentIndex == 0) {
          this.translateY = 0;
        }
        // 在这里执行您想要进行的操作
        this.wheelCount = 0; // 重置计数器
      }
    },
  },
  watch: {
    currentIndex(n, o) {
      if (n == 0) {
        this.translateY = 0;
      }
      if (n) {
        if (n == 1) {
          this.translateY = -100;
        }
        if (n == 2) {
          this.translateY = -200;
        }
        if (n == 3) {
          this.translateY = -300;
        }
        if (n == 4) {
          this.translateY = -400;
        }
        if (n == 5) {
          this.translateY = -500;
        }
        if (n == 6) {
          this.translateY = -600;
        }
      }
    },
  },
};
</script>
