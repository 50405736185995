<template>
  <!-- <div
    class="carousel"
    @mouseenter="pauseCarousel"
    @mouseleave="resumeCarousel"
  >
    <div class="ulDiv" ref="ulDiv">
      <div class="car" v-for="(item, index) in items" :key="index">
        <img
          :src="item.src"
          @mouseenter="getImg(item.src)"
          @mouseleave="$store.state.dialogImgBool = false"
          :alt="item.alt"
        />
      </div>
      <div
        class="car"
        v-for="(item, index) in items.slice(0, 2)"
        :key="index + items.length"
      >
        <img :src="item.src" :alt="item.alt" v-enlarge />
      </div>
    </div>
  </div> -->
  <div></div>
</template>

<script>
export default {
  directives: {
    // enlarge: function (el, binding) {
    //   el.style.fontSize = binding.value + "px";
    // },
  },
  data() {
    return {
      // items: [
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/3A资信等级证书-2021.9.8.png"),
      //     alt: "3A资信等级证书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/安全生产许可证-2021.8.20.png"),
      //     alt: "安全生产许可证",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/河北省工业企业研发机构c级-散货港口智能控制研发中心.jpg"),
      //     alt: "河北省工业企业研发机构c级-散货港口智能控制研发中心",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/环境管理体系认证证书-2021.8.19-2024.8.18.png"),
      //     alt: "环境管理体系认证证书-2021.8.19-2024.8.18",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/建筑业企业资质证书-2021.5.17.png"),
      //     alt: "建筑业企业资质证书-2021.5.17",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/建筑业企业资质证书-2022.2.9.png"),
      //     alt: "建筑业企业资质证书-2022.2.9",
      //   },
      //   // {
      //   //   src: require("@/assets/Qualificationcertification/资质/营业执照.png"),
      //   //   alt: "营业执照",
      //   // },
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/职业健康安全管理体系证书.png"),
      //     alt: "职业健康安全管理体系证书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/资质/质量管理体系认证证书-2020.9.11-2023.9.10.png"),
      //     alt: "质量管理体系认证证书-2020.9.11-2023.9.10",
      //   },
      // ],
      // intervalId: null,
      // speed: 1, // 每秒移动的像素数
    };
  },
  mounted() {
    // this.intervalId = setInterval(this.moveCarousel, 16);
  },
  // beforeDestroy() {
  //   clearInterval(this.intervalId);
  // },
  methods: {
    // moveCarousel() {
    //   const ulDiv = this.$refs.ulDiv;
    //   let left = parseInt(getComputedStyle(ulDiv).left) || 0;
    //   left -= this.speed;
    //   if (left < -1500) {
    //     left = 0;
    //   }
    //   ulDiv.style.left = `${left}px`;
    //   if (left === 0) {
    //     clearInterval(this.intervalId);
    //     setTimeout(() => {
    //       this.intervalId = setInterval(this.moveCarousel, 16);
    //     }, 2000);
    //   }
    // },
    // pauseCarousel() {
    //   clearInterval(this.intervalId);
    // },
    // resumeCarousel() {
    //   this.intervalId = setInterval(this.moveCarousel, 16);
    // },
    // getImg(parse) {
    //   this.$store.state.dialogImgBool = true;
    //   this.$store.state.dialogImgurl = parse;
    // },
  },
};
</script>

<style lang="less" scoped>
.carousel {
  width: calc(100vw * 1200 / 1920);
  overflow: hidden;
  // background: url("../../assets/Qualificationcertification/资质/资质图.png");
  // background-size: 100% 100%;
  .ulDiv {
    width: calc(100vw * 6600 / 1920);
    // height: calc(100vw * 100 / 1920);
    overflow: hidden;
    display: flex;
    position: relative;
    left: 0;
    .car {
      width: calc(100vw * 300 / 1920);
      height: calc(100vw * 400 / 1920);
      z-index: 1;
      cursor: pointer;
      img {
        width: calc(100vw * 285 / 1920);
        height: calc(100vw * 390 / 1920);
      }
    }
  }
}
</style>
