<template>
  <div class="headerDiv">
    <div class="topDiv">
      <div>
        <img
          style="
            width: calc(100vw * 146 / 1920);
            height: calc(100vw * 35 / 1920);
          "
          src="../assets/logoWhite.png"
          alt=""
        />
      </div>
      <div class="aDiv">
        <a
          @mouseover="addTopDivCol()"
          @click="goPush('/home')"
          :class="{ active: activeTitle === 'home' }"
          >首页</a
        >
        <a
          @mouseover="addTopDivCol()"
          @click="goPush('/aboutIndex')"
          :class="{ active: activeTitle === 'aboutBY' }"
          >关于滨沅</a
        >
        <a
          @mouseover="handleMouseOver('产品解决方案')"
          @click="goPush('/productCenterIndex')"
          :class="{ active: activeTitle === 'productCenterIndex' }"
          >产品解决方案</a
        >
        <a
          @mouseover="addTopDivCol()"
          :class="{ active: activeTitle === 'newsIndex' }"
          @click="goPush('/newsIndex')"
          >新闻资讯</a
        >
        <a
          @mouseover="addTopDivCol()"
          :class="{ active: activeTitle === 'recruitmentIndex' }"
          @click="goPush('/recruitmentIndex')"
          >人才招聘</a
        >
        <a
          @mouseover="addTopDivCol()"
          :class="{ active: activeTitle === 'contactusIndex' }"
          @click="goPush('/contactusIndex')"
          >联系我们</a
        >
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: calc(100vw * 16 / 1920);
        "
      >
        <img
          style="
            width: calc(100vw * 24 / 1920);
            height: calc(100vw * 24 / 1920);
          "
          src="../assets/电话.png"
          alt=""
        />
        <span style="color: white; margin-left: 5px">0335-8352973</span>
      </div>
    </div>
    <div class="buttomDiv" v-show="buttomBool" @mouseleave="addTopDivCol">
      <div class="left">
        <div style="width: 35%; position: absolute; right: 5%; top: 15%">
          <p
            style="
              margin-right: 1%;
              text-align: justify;
              text-align-last: justify;
              font-size: calc(100vw * 25 / 1920);
              user-select: none;
            "
          >
            智能智造&nbsp;&nbsp;品牌专家
          </p>
        </div>
        <div style="width: 35%; position: absolute; right: 5%; top: 28%">
          <p
            style="
              margin-right: 1%;
              text-align: justify;
              text-align-last: justify;
              font-size: calc(100vw * 15 / 1920);
              opacity: 0.4;
              user-select: none;
            "
          >
            INTELLIGENT&nbsp;MANUFACTURING
          </p>
        </div>
      </div>
      <div class="right">
        <div>
          <div class="right1Div">
            <p
              style="cursor: pointer; font-size: calc(100vw * 16 / 1920)"
              @click="$router.push('/productCenterIndex')"
            >
              产品中心首页 〉
            </p>
          </div>
          <div style="display: flex">
            <div class="dataDiv">
              <h3>设备管控</h3>
              <div class="hr"></div>
              <div class="dataList">
                <div v-for="(item, index) in shebeiList" :key="index">
                  ●&nbsp;&nbsp;&nbsp;<span
                    style="cursor: pointer"
                    @click="
                      $router.push(`/sbdata?type=${1}&id=${item.productId}`)
                    "
                    >{{ item.productTitle }}</span
                  >
                </div>
              </div>
            </div>
            <div class="dataDiv">
              <h3>安全管控</h3>
              <div class="hr"></div>
              <div class="dataList">
                <div v-for="(item, index) in anquanList" :key="index">
                  ●&nbsp;&nbsp;&nbsp;<span
                    style="cursor: pointer"
                    @click="
                      $router.push(
                        `/sbdata?type=${2}&id=${item.productId}`,
                        () => {
                          window.location.reload();
                        }
                      )
                    "
                    >{{ item.productTitle }}</span
                  >
                </div>
              </div>
            </div>
            <div class="dataDiv">
              <h3>生产管控</h3>
              <div class="hr"></div>
              <div class="dataList">
                <div v-for="(item, index) in shengchanList" :key="index">
                  ●&nbsp;&nbsp;&nbsp;<span
                    style="cursor: pointer"
                    @click="
                      $router.push(
                        `/sbdata?type=${3}&id=${item.productId}`,
                        () => {
                          window.location.reload();
                        }
                      )
                    "
                    >{{ item.productTitle }}</span
                  >
                </div>
              </div>
            </div>
            <div class="dataDiv">
              <h3>环保管控</h3>
              <div class="hr"></div>
              <div class="dataList">
                <div v-for="(item, index) in huanbaoList" :key="index">
                  ●&nbsp;&nbsp;&nbsp;<span
                    style="cursor: pointer"
                    @click="
                      $router.push(
                        `/sbdata?type=${4}&id=${item.productId}`,
                        () => {
                          window.location.reload();
                        }
                      )
                    "
                    >{{ item.productTitle }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightDiv" @mouseenter="showElements" @mouseleave="hideElements">
      <svg
        t="1679988699912"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="5863"
        width="30"
        height="30"
      >
        <path
          d="M731.7 451.9c0 21.9 14.6 35.4 35.4 35.4 21.9 0 35.4-14.6 35.4-35.4-0.5-118.7-96.3-214.6-215.1-215.1-21.9 0-35.4 14.6-35.4 35.4 0 21.9 14.6 35.4 35.4 35.4 79.7 0.5 143.8 64.6 144.3 144.3z m143.2 0c0 21.9 14.6 35.4 35.4 35.4s35.4-14.6 35.4-35.4c0-197.4-162-358.3-358.3-358.3-21.9 0-35.4 14.6-35.4 35.4s14.6 35.4 35.4 35.4c158.3 0 287.5 129.7 287.5 287.5zM390.6 326.4c39.6-39.6 42.7-100.5 7.3-143.2l-111-140.7C251.5-4.3 182.8-11.6 136.4 24.8c-3.1 3.1-7.3 3.1-7.3 7.3L31.8 129C-60.9 221.7 71.4 473.2 312 713.8s488 369.7 581.2 279.7l96.9-96.9c42.7-42.7 42.7-110.9 0-150.5l-7.3-7.3-140.2-110.9c-42.7-35.4-103.6-32.3-143.2 7.3l-60.9 60.9c-64.6-39.6-122.4-82.3-172.4-132.8-50-50.5-92.7-107.8-132.3-172.4-1-0.5 56.8-64.5 56.8-64.5zM340.1 229c10.4 14.6 10.4 35.4-3.1 46.9l-79.2 82.8c-11.5 11.5-14.1 28.6-7.3 42.7 42.2 78.1 95.8 148.9 157.8 212 62.5 62.5 133.8 115.6 212 157.8 14.6 6.8 31.8 4.2 42.7-7.3l82.3-82.3c14.6-14.6 32.3-14.6 46.9-3.1l140.1 115.1s3.1 0 3.1 3.1c14.1 13 14.6 34.9 1.6 49l-1.6 1.6-97 96.7c-46.9 46.9-268.7-71.9-480.7-279.7C146.8 455 31.8 229 78.6 182.1L179.1 81.6c14.6-10.4 39.6-10.4 50 7.3l111 140.1z"
          fill="#ffffff"
          p-id="5864"
        ></path>
      </svg>
      <p>联系我们</p>
    </div>
    <div
      class="rightDivLeftDiv"
      v-show="rightDivLeftDivBool"
      @mouseenter="showElements"
      @mouseleave="hideElements"
    >
      <div>
        <h3>售前咨询</h3>
        <p>产品、解决方案咨询</p>
      </div>
      <div>
        <h3>售后咨询</h3>
        <p>0335-8352973</p>
      </div>
      <span
        style="cursor: pointer; color: #006aff"
        @click="goPush('/contactusIndex')"
        >更多联系方式&nbsp;></span
      >
    </div>
    <div
      class="rightDivLeftDivAfter"
      v-show="rightDivLeftDivBool"
      @mouseenter="showElements"
      @mouseleave="hideElements"
    >
      ▶
    </div>
    <div class="tabsDiv" v-show="pathHome">
      <div
        v-for="(item, index) in 7"
        :key="index"
        :class="$store.state.currentIndex == index ? 'currentIndex' : ''"
        @click="setCurrentIndex(index)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeIndex: "1",
      buttomBool: false,
      rightDivLeftDivBool: false,
      activeTitle: "home",
      shebeiList: [],
      anquanList: [],
      shengchanList: [],
      huanbaoList: [],
      pathHome: false,
    };
  },
  mounted() {
    this.getShebeiList();
    this.getAnquanList();
    this.getShengchanList();
    this.getHuanbaoList();
    if (this.$route.path == "/home") {
      this.activeTitle = "home";
      let topDiv = document.querySelector(".topDiv");
      topDiv.style.background = "rgba(255,255,255,0)";
      this.pathHome = true;
    }
    if (this.$route.path == "/aboutIndex") {
      this.activeTitle = "aboutBY";
      this.pathHome = false;
    }
    if (this.$route.path == "/productCenterIndex") {
      this.activeTitle = "productCenterIndex";
      this.pathHome = false;
    }
    if (this.$route.path == "/newsIndex") {
      this.activeTitle = "newsIndex";
      this.pathHome = false;
    }
    if (this.$route.path == "/newsAny") {
      this.activeTitle = "newsIndex";
      this.pathHome = false;
    }
    if (this.$route.path == "/indetail") {
      this.activeTitle = "newsIndex";
      this.pathHome = false;
    }
    if (this.$route.path == "/recruitmentIndex") {
      this.activeTitle = "recruitmentIndex";
      this.pathHome = false;
    }
    if (this.$route.path == "/contactusIndex") {
      this.activeTitle = "contactusIndex";
      this.pathHome = false;
    }
    if (this.$route.path == "/sbdata") {
      this.activeTitle = "productCenterIndex";
      this.pathHome = false;
    }
    if (this.$route.path == "/indetail") {
      this.activeTitle = "newsIndex";
      this.pathHome = false;
    }
  },
  methods: {
    handleSelect(key, keyPath) {},
    handleMouseOver(p) {
      this.buttomBool = true;
    },
    routerGo(r, n) {
      this.$router.push(r);
      if (n) {
        this.$store.state.indexPage = n;
      }
    },
    addTopDivCol(p) {
      this.buttomBool = false;
      // this.activeTitle = p;
    },
    showElements() {
      // 显示下方元素
      this.rightDivLeftDivBool = true;
    },
    hideElements() {
      // 隐藏下方元素
      this.rightDivLeftDivBool = false;
    },
    goPush(path) {
      this.$store.state.routerPush = true;
      this.$router.push(path);
      // location.href = path;
    },
    async getShebeiList() {
      let result = await this.$store.dispatch("typeproduct", {
        productType: 1,
        rows: "",
      });
      this.shebeiList = result.data.data;
    },
    async getAnquanList() {
      let result = await this.$store.dispatch("typeproduct", {
        productType: 2,
        rows: "",
      });
      this.anquanList = result.data.data;
    },
    async getShengchanList() {
      let result = await this.$store.dispatch("typeproduct", {
        productType: 3,
        rows: "",
      });
      this.shengchanList = result.data.data;
    },
    async getHuanbaoList() {
      let result = await this.$store.dispatch("typeproduct", {
        productType: 4,
        rows: "",
      });
      this.huanbaoList = result.data.data;
    },
    setCurrentIndex(i) {
      this.$store.state.currentIndex = i;
    },
  },
  watch: {
    rightDivLeftDivBool(n, o) {
      if (n) {
        let rightDiv = document.querySelector(".rightDiv");
        rightDiv.style.backgroundImage = "linear-gradient(#49d1fe, #002dcc)";
      } else {
        let rightDiv = document.querySelector(".rightDiv");
        rightDiv.style.background = "#000";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.headerDiv {
  width: 100%;
  z-index: 10;
  .topDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vw * 75 / 1920);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0);
    .active {
      color: #006aff !important;
    }
    // background-color: rgba(0, 0, 0);
    .aDiv {
      margin-left: 8%;
      width: 40%;
      display: flex;
      a {
        color: white;
        text-decoration: none;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(100vw * 14 / 1920);
        height: calc(100vw * 75 / 1920);
        // flex: 1;
        width: 13%;
        // margin-right: 4%;
        cursor: pointer;
      }
      a:hover {
        // color: rgb(220, 52, 52);
        background: rgba(255, 255, 255, 0.1);
        transition: color 0.3s ease;
      }
    }
  }
  .buttomDiv {
    width: 100%;
    // height: 50%;
    // background-color: rgba(10, 10, 10, 0.9);
    position: fixed;
    top: calc(100vw * 75 / 1920);
    z-index: 10;
    display: flex;
    .left {
      position: relative;
      width: 35%;
      // background-color: rgba(5, 5, 5, 0.9);
      background: url("../assets/left2.png") no-repeat center center/cover;
      z-index: 2;
      // padding-top: 3%;
      div {
        p {
          color: white;
        }
      }
    }
    .right {
      position: relative;
      width: 65%;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 2;
      padding-left: 3%;
      padding-top: 1%;
      box-sizing: border-box;
      padding-bottom: 1%;
      .right1Div {
        margin-bottom: 2%;
        user-select: none;
        color: #006aff;
      }
      .dataDiv {
        margin-right: 5%;
        h3 {
          font-weight: 400;
          font-size: calc(100vw * 16 / 1920);
          color: #666768;
        }
        .hr {
          margin-top: 4%;
          width: calc(100vw * 213 / 1920);
          height: 0px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 8%;
        }
        .dataList {
          color: white;
          font-size: calc(100vw * 14 / 1920);
          div {
            margin-bottom: 4%;
            cursor: pointer;
            display: -webkit-box; //不限制文本几行 自动换行 溢出显示省略号
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            max-height: 3.6rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          div:hover {
            color: #006aff;
          }
        }
      }
    }
  }
  .rightDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 2%;
    bottom: 13%;
    // align-items: center;
    width: calc(100vw * 45 / 1920);
    height: calc(100vw * 165 / 1920);
    padding: 24px 30px;
    background: #000;
    border: 1px solid #2c2c2c;
    border-radius: calc(100vw * 165 / 1920);
    z-index: 10;
    color: white;
    user-select: none;
    // img {
    //   width: calc(100vw * 30 / 1920);
    //   height: calc(100vw * 30 / 1920);
    // }
    p {
      margin-top: calc(100vw * 15 / 1920);
      font-size: calc(100vw * 16 / 1920);
      writing-mode: vertical-lr;
      text-orientation: upright;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      letter-spacing: calc(100vw * 10 / 1920);
    }
  }
  .rightDiv:hover {
    background-image: linear-gradient(#49d1fe, #002dcc);
    border: none;
  }
  .rightDivLeftDiv {
    width: calc(100vw * 200 / 1920);
    height: calc(100vw * 200 / 1920);
    border-radius: calc(100vw * 10 / 1920);
    background-color: rgba(19, 19, 19, 0.9);
    position: absolute;
    right: 6.4%;
    bottom: 13%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 1% 1%;
    box-sizing: border-box;
    h3,
    p {
      color: white;
    }
    p {
      font-size: calc(100vw * 14 / 1920);
      margin-top: calc(100vw * 5 / 1920);
    }
    a {
      color: rgb(34, 119, 255);
      text-decoration: none;
    }
    div {
      flex: 1;
    }
  }
  .rightDivLeftDivAfter {
    width: calc(100vw * 20 / 1920);
    height: calc(100vw * 90 / 1920);
    // background-color: rgba(19, 19, 19, 0.6);
    color: rgba(19, 19, 19, 0.9);
    position: absolute;
    right: 5.5%;
    bottom: 20%;
    z-index: 10;
    // border-top: calc(100vw * 20 / 1920) solid transparent;
    // border-bottom: calc(100vw * 20 / 1920) solid transparent;
    // border-left: calc(100vw * 20 / 1920) solid #ccc;
  }
  .tabsDiv {
    position: absolute;
    width: calc(100vw * 10 / 1920);
    height: calc(100vw * 300 / 1920);
    // background: #666768;
    z-index: 99;
    right: 2%;
    top: 25%;
    display: flex;
    flex-direction: column;
    div {
      width: 100%;
      height: calc(100vw * 20 / 1920);
      flex: 1;
      margin-bottom: 50%;
      background: rgba(102, 103, 104, 0.4);
      cursor: pointer;
      border-radius: calc(100vw * 20 / 1920);
    }
    div:hover {
      background: rgba(222, 222, 222, 0.8);
    }
    .currentIndex {
      background: rgba(222, 222, 222, 0.8);
    }
  }
}
</style>
