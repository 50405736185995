<template>
  <div class="productCenterIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <el-carousel
          trigger="click"
          :interval="swiperTime"
          class="swiper"
          :height="imgHeight"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topDivimg">
        <div class="topDiv">
          <header>
            <div>PROPERTY</div>
            <div>新闻资讯</div>
            <div></div>
            <!-- <div style="text-align: center">
              公司产品主要有智能控制和智能运维两大核心业务，囊括散货大型装卸设备管控自动化、生产管控智能化、运维监管智能化、环境综合管控一体化、针对行业场景的打造了“沅视听”智能机器学习平台和数字孪生系统平台，为客户长期保驾护航的互联网+VR的智能运维(巡检)平台，致力于成为智慧港口、智慧钢铁、智慧电厂建设的智造专家。
            </div> -->
          </header>
        </div>
      </div>
      <div id="w100">
        <div id="bx">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>行业新闻</h3>
                <div></div>
              </div>
              <div class="d2">NEWS</div>
            </div>
          </div>
          <div class="getAny">
            <span
              href=""
              style="
                color: #063e8d;
                text-decoration: none;
                font-size: calc(100vw * 16 / 1920);
              "
              @click="goPush(1)"
              >更多</span
            >
          </div>
          <div class="myYoushi">
            <div class="wrapper">
              <div class="ul">
                <div class="li" v-for="(item, index) in newsList" :key="index">
                  <img
                    @click="$router.push(`/indetail?id=${item.infoId}`)"
                    :src="'gw/prod-api' + item.infoImg"
                    alt=""
                  />
                  <h3 @click="$router.push(`/indetail?id=${item.infoId}`)">
                    {{ item.infoTitle }}
                  </h3>
                  <p @click="$router.push(`/indetail?id=${item.infoId}`)">
                    {{ item.infoOutLine }}
                  </p>
                  <div>
                    <span
                      @click="$router.push(`/indetail?id=${item.infoId}`)"
                      >{{ item.infoDate }}</span
                    >
                    <span
                      style="
                        color: #063e8d;
                        text-decoration: none;
                        font-size: calc(100vw * 16 / 1920);
                      "
                      @click="$router.push(`/indetail?id=${item.infoId}`)"
                      >更多
                      <img class="next" src="../../assets/news/更多.png" alt=""
                    /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="w100" style="padding-bottom: 2%">
        <div id="bx">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>公司新闻</h3>
                <div></div>
              </div>
              <div class="d2">Company News</div>
            </div>
          </div>
          <div class="getAny">
            <span
              href=""
              style="
                color: #063e8d;
                text-decoration: none;
                font-size: calc(100vw * 16 / 1920);
              "
              @click="goPush(2)"
              >更多</span
            >
          </div>
          <div class="gsxw">
            <div class="left">
              <div class="dateDiv">
                <h3>{{ rightList[0].infoDate }}</h3>
                <img
                  @click="$router.push(`/indetail?id=${rightList[0].infoId}`)"
                  src="../../assets/news/更多.png"
                  alt=""
                />
              </div>
              <div class="hrDiv"></div>
              <h3
                class="titleDiv"
                style="cursor: pointer"
                @click="$router.push(`/indetail?id=${rightList[0].infoId}`)"
              >
                {{ rightList[0].infoTitle }}
              </h3>
              <p
                style="
                  cursor: pointer;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                "
                @click="$router.push(`/indetail?id=${rightList[0].infoId}`)"
              >
                {{ rightList[0].infoOutLine }}
              </p>
              <img
                class="llsDiv"
                style="cursor: pointer"
                @click="$router.push(`/indetail?id=${rightList[0].infoId}`)"
                :src="'gw/prod-api' + rightList[0].infoImg"
                alt=""
              />
            </div>
            <div class="right">
              <div class="li">
                <div class="d1">
                  <h3
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[1].infoId}`)"
                  >
                    {{ rightList[1].infoTitle }}
                  </h3>
                  <p>{{ rightList[1].infoDate }}</p>
                </div>
                <div class="d2">
                  <p
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[1].infoId}`)"
                  >
                    {{ rightList[1].infoOutLine }}
                  </p>
                  <img
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[1].infoId}`)"
                    src="../../assets/news/更多.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="li">
                <div class="d1">
                  <h3
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[2].infoId}`)"
                  >
                    {{ rightList[2].infoTitle }}
                  </h3>
                  <p>{{ rightList[2].infoDate }}</p>
                </div>
                <div class="d2">
                  <p
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[2].infoId}`)"
                  >
                    {{ rightList[2].infoOutLine }}
                  </p>
                  <img
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[2].infoId}`)"
                    src="../../assets/news/更多.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="li">
                <div class="d1">
                  <h3
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[3].infoId}`)"
                  >
                    {{ rightList[3].infoTitle }}
                  </h3>
                  <p>{{ rightList[3].infoDate }}</p>
                </div>
                <div class="d2">
                  <p
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[3].infoId}`)"
                  >
                    {{ rightList[3].infoOutLine }}
                  </p>
                  <img
                    style="cursor: pointer"
                    @click="$router.push(`/indetail?id=${rightList[3].infoId}`)"
                    src="../../assets/news/更多.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";
export default {
  name: "newsIndex",
  metaInfo: {
    // 设置关键词
    meta: {
      keywords: "公司新闻,行业新闻,,港口新闻",
    },
  },
  components: {
    Harder,
    Footer,
  },
  data() {
    return {
      bannerList: [
        {
          url: require("@/assets/news/新闻中心banner1.png"),
          link: "",
        },
        {
          url: require("@/assets/news/新闻中心banner2.png"),
          link: "",
        },
        {
          url: require("@/assets/news/新闻中心banner3.png"),
          link: "",
        },
      ],
      swiperTime: 7000,
      newsList: [],
      rightList: [],
      imgHeight: "calc(100vw * 800 / 1920)",
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.hninfo();
    this.ydinfo();
  },
  methods: {
    //行内新闻
    async hninfo() {
      let result = await this.$store.dispatch("hninfo");
      if (result.data.code == 200) {
        this.newsList = result.data.data;
      } else {
        this.newsList = [];
      }
    },
    //公司新闻
    async ydinfo() {
      let result = await this.$store.dispatch("ydinfo");
      if (result.data.code == 200) {
        this.rightList = result.data.data;
      } else {
        this.rightList = [];
      }
    },
    //跳转新闻列表
    goPush(p) {
      this.$router.push(`/newsAny`, () => {
        sessionStorage.setItem("infoType", p);
        window.location.reload();
      });
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    margin-right: 40px;
    margin-bottom: 10%;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

// 修改滚动条样式
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 20px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  // box-shadow: inset 0 0 5px rgba(0, 123, 255, 0.2);
  background: rgb(208, 208, 208);
  height: 40px !important;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgb(239, 239, 239);
}

.productCenterIndexDiv {
  width: 100%;

  #w100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #bx {
    width: calc(100vw * 1200 / 1920);
    margin-top: 3%;
    .topDiv {
      display: flex;
      // margin-bottom: 2%;
      .dataDivLeft {
        width: 47%;
        // height: 100%;
        // background: #c7e49f;
        .d1 {
          display: flex;
          align-items: center;
          font-size: calc(100vw * 20 / 1920);
          div {
            width: 15%;
            // margin-left: 2%;
            border: 1px solid rgba(128, 142, 162, 0.1);
          }
        }
        .d2 {
          font-size: calc(100vw * 14 / 1920);
          color: rgba(6, 62, 141, 0.1);
          margin-top: 1%;
          margin-bottom: 3%;
        }
      }
    }
    .getAny {
      width: 100%;
      position: relative;
      height: calc(100vw * 30 / 1920);
      span {
        position: absolute;
        right: 0;
        cursor: pointer;
      }
      // position: absolute;
      // right: 0;
      // float: right;
    }
    .myYoushi {
      // position: relative;
      width: 100%;
      overflow-x: auto;

      .ul {
        display: flex;
        // display: inline-block;
        width: 100%;
        margin-bottom: 2%;
        .li {
          width: calc(100vw * 342 / 1920);
          height: calc(100vw * 378 / 1920);
          background: #f5f5f5;
          border-radius: 10px;
          margin-right: 1%;
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: transform 0.3s ease-out;
          cursor: pointer;
          img {
            width: calc(100vw * 342 / 1920);
            height: calc(100vw * 199 / 1920);
            border-radius: 10px 10px 0px 0px;
          }
          h3 {
            font-size: calc(100vw * 16 / 1920);
            width: calc(100vw * 303 / 1920);
            height: calc(100vw * 21 / 1920);
            // overflow: hidden;
            margin-top: 8%;
            margin-bottom: 8%;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            display: -webkit-box; //不限制文本几行 自动换行 溢出显示省略号
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            max-height: 3.6rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            font-size: calc(100vw * 14 / 1920);
            width: calc(100vw * 303 / 1920);
            margin-bottom: 10%;
            color: #333333;
            height: calc(100vw * 40 / 1920);
            // overflow: hidden;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-height: 3.6rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          div {
            width: calc(100vw * 303 / 1920);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            span {
              font-size: calc(100vw * 12 / 1920);
            }
            a {
              color: #063e8d;
              text-decoration: none;
              display: block;
              display: flex;
              align-items: center;
              font-size: calc(100vw * 12 / 1920);
            }
          }
          .next {
            width: calc(100vw * 24 / 1920);
            height: calc(100vw * 6 / 1920);
            cursor: pointer;
          }
        }
        .li:hover {
          transform: translateY(10px);
        }
      }
    }
    .gsxw {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2%;
      .left {
        width: 49%;
        height: calc(100vw * 490 / 1920);
        // background: rgb(233, 233, 233);
        .dateDiv {
          width: 100%;
          display: flex;
          justify-content: space-between;
          // justify-content: center;
          align-items: center;
          margin-bottom: 2%;
          h3 {
            font-size: calc(100vw * 20 / 1920);
            color: #333333;
          }
          img {
            width: calc(100vw * 27 / 1920);
            height: calc(100vw * 10 / 1920);
            cursor: pointer;
          }
        }
        .hrDiv {
          border: 2px solid #efefef;
          margin-bottom: 4%;
        }
        .titleDiv {
          font-size: calc(100vw * 16 / 1920);
          color: #333333;
          margin-bottom: 4%;
        }
        p {
          font-size: calc(100vw * 14 / 1920);
          color: #666666;
          margin-bottom: 4%;
        }
        .llsDiv {
          width: 100%;
          box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.3);
          border-radius: calc(100vw * 10 / 1920);
        }
      }
      .right {
        width: 49%;
        height: calc(100vw * 490 / 1920);
        // background: rgb(204, 220, 177);
        .li {
          height: 33%;
          width: 100%;
          padding-top: 3%;
          box-sizing: border-box;
          border-bottom: 1px solid #efefef;
          .d1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5%;
            h3 {
              font-size: calc(100vw * 16 / 1920);
              color: #000000;
              font-weight: 400;
            }
            p {
              font-size: calc(100vw * 20 / 1920);
              color: #d2d2d2;
              font-weight: 700;
            }
          }
          .d2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              width: 85%;
              font-size: calc(100vw * 14 / 1920);
              color: #333333;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              max-height: 3.6rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            img {
              width: calc(100vw * 27 / 1920);
              height: calc(100vw * 8 / 1920);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .swiperDiv {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .topDivimg {
      width: 100%;
      height: calc(100vw * 200 / 1920);
      // background: url("../../assets/productCenterMsg/topHeader.png");
      // background-size: 100% 100%;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      //   margin-bottom: 2%;
      // padding-bottom: 3%;
      .topDiv {
        width: calc(100vw * 1200 / 1920);
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 2%;
        header {
          width: 80% !important;
          height: calc(100vw * 186 / 1920);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          //   margin-bottom: 3%;

          div:nth-child(1) {
            font-size: calc(100vw * 64 / 1920);
            color: #eff1f4;
            position: absolute;
            top: 20%;
          }
          div:nth-child(2) {
            font-size: calc(100vw * 30 / 1920);
            font-weight: 700;
            position: absolute;
            top: 40%;
            // color: #eff1f4;
          }
          div:nth-child(3) {
            width: 3%;
            height: 8px;
            position: absolute;
            top: 75%;
            background: #d91724;
          }
          div:nth-child(4) {
            position: absolute;
            font-size: calc(100vw * 16 / 1920);
            top: 90%;
          }
        }
      }
    }
  }
}
</style>
