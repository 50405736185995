<template>
  <div id="contener">
    <div class="backWhite">
      <div class="contenerDiv">
        <header>
          <div>INTRODUCTION</div>
          <div>企业介绍</div>
          <div></div>
          <div>
            引领散货装卸无人化建设，构筑互联网+的智能运维平台，做中国最值得信赖的智造服务商
          </div>
        </header>
        <div class="dataDiv">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div>
                <h3>关于我们</h3>
                <div></div>
              </div>
              <div>ABOUT US</div>
              <div>
                滨沅国科（秦皇岛）智能科技股份有限公司是一家专注于绿色智慧散货装卸行业全流程智能管控的高科技公司，公司深耕绿色散货装卸领域二十余年，依托国家重点大学燕山大学(原东北重型机械学院)强大的科研支撑，发扬工匠精神，致力于提供全方位的散货装卸解决方案。
              </div>
              <div>
                滨沅国科作为“高新技术企业”、“河北省专精特新企业”、“ISO9001质量管理体系认证企业”、“环境管理体系认证企业”、“职业健康安全管理体系认证企业”和“河北省工业企业研发机构”，拥有机电工程施工总承包三级资质、电子与智能化工程专业承包二级资质，申请专利软著30余项，先后荣获中国发明协会技术创新二等奖、第7届中国品牌创新金匠奖、第九届中国创新创业大赛省二等奖、中国煤炭工业协会技术创新二等奖、河北省科学技术进步三等奖等10余项。入选中国名牌2022企业社会责任优秀案例，被第七届中国品牌创新发展论坛评为“星级合作伙伴”。
              </div>
            </div>
            <div class="dataDivRight">
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="bottomDiv">
            <el-card class="box-card">
              <div>
                <h2>20+</h2>
                <h3 style="font-size: calc(100vw * 15 / 1920)">技术沉淀</h3>
              </div>
              <div style="font-size: calc(100vw * 14 / 1920)">
                深耕装卸领域二十余年，拥有多领域卓越的研究成果和丰富的实践经验。
              </div>
              <div></div>
            </el-card>
            <el-card class="box-card"
              ><div>
                <h2>200+</h2>
                <h3 style="font-size: calc(100vw * 15 / 1920)">合作单位</h3>
              </div>
              <div style="font-size: calc(100vw * 14 / 1920)">
                目前产品已经广泛用到我国各大港口、钢厂和电厂，如锦州港、秦皇岛港、唐山港、天津港、黄骅港、日照港、连云港、珠海港、防城港、新疆、内蒙古等地区，具有良好的市场前景。
              </div>
              <div></div>
            </el-card>
            <el-card class="box-card">
              <div>
                <h2>90+</h2>
                <h3 style="font-size: calc(100vw * 15 / 1920)">团队实力</h3>
              </div>
              <div style="font-size: calc(100vw * 14 / 1920)">
                滨沅国科拥有知识产权30余项，合作共建多个研发机构、研究中心和产学研合作基地，推动了公司的技术研发和市场推广。
              </div>
              <div></div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
    <div class="carDiv">
      <div class="top">
        <div>
          <div style="margin-right: 3%"></div>
          <div style="margin-left: 1%">发展历程</div>
          <div></div>
        </div>
        <p class="p1">DEVELOPMENT HISTORY</p>
        <p class="p2" style="line-height: calc(100vw * 26 / 1920)">
          公司前身是燕山大学港口自动化研究所，依托燕山大学强大的科研支撑、数十位行业资深技术专家和高端科研人才
        </p>
        <p class="p3">智库团队，公司具备强大的研发能力。</p>
      </div>
      <div class="myYoushi">
        <div class="ul">
          <div
            class="li"
            v-for="(item, index) in topList"
            :key="index"
            @mouseenter="setDivData(item)"
            @mouseout="clearDivData"
          >
            <div class="oDiv" style="pointer-events: none">
              <h3>{{ item.developDate }}</h3>
              <p>{{ item.developTitle }}</p>
              <img src="../../assets/aboutMsg/发展历程卡片奖杯.png" alt="" />
            </div>
          </div>
        </div>
        <div class="hrDiv">
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
            "
            alt=""
          />
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
              left: 21%;
            "
            alt=""
          />
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
              left: 32%;
            "
            alt=""
          />
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
              left: 43%;
            "
            alt=""
          />
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
              left: 54%;
            "
            alt=""
          />
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
              left: 65%;
            "
            alt=""
          />
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
              left: 76%;
            "
            alt=""
          />
          <img
            src="../../assets/aboutMsg/发展历程点.png"
            style="
              width: calc(100vw * 24 / 1920);
              height: calc(100vw * 24 / 1920);
              position: absolute;
              top: calc(100vw * -11 / 1920);
              left: 87%;
            "
            alt=""
          />
        </div>
        <div class="ul" style="padding-left: 12%">
          <div
            class="li"
            v-for="(item, index) in bottomList"
            :key="index"
            @mouseenter="setDivData(item)"
            @mouseout="clearDivData"
          >
            <div class="oDiv" style="pointer-events: none">
              <h3>{{ item.developDate }}</h3>
              <p>{{ item.developTitle }}</p>
              <img src="../../assets/aboutMsg/发展历程卡片奖杯.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backWhite">
      <div class="qiyewenhuaDiv">
        <div class="topDiv">
          <div>
            <div></div>
            <div
              style="
                margin-left: 3%;
                margin-right: 3%;
                width: calc(100vw * 100 / 1920);
              "
            >
              企业文化
            </div>
            <div></div>
          </div>
          <p class="p1">CORPORATE CULTURE</p>
          <p class="p2" style="line-height: calc(100vw * 26 / 1920)">
            公司秉承“客户至上，求真务实，坚持创新，自强不息”的经营理念，致力于引领散货装卸无人化建设，构筑
          </p>
          <p class="p3">互联网+的智能运维平台。</p>
        </div>
        <div class="imgDiv" style="margin-top: 2%">
          <div class="imgDivOne">
            <p>专业/专注/专心</p>
            <div class="progress">
              <div></div>
            </div>
            <div class="data" style="line-height: calc(100vw * 26 / 1920)">
              滨沅国科身肩“赋能装卸行业，实现降本增效”的使命，致力成为装卸行业智能化引领者，持续为客户创造价值，努力做中国最值得信赖的智造服务商。
            </div>
          </div>
          <div class="imgDivTwo">
            <p>价值观</p>
            <p>持续为客户创造价值</p>
          </div>
          <div class="imgDivThree">
            <p>企业愿景</p>
            <p>做中国最值得信赖的智造服务商</p>
          </div>
        </div>
      </div>
    </div>
    <div class="backWhite2">
      <div class="contenerDiv">
        <div class="topDiv">
          <div>
            <div></div>
            <div
              style="
                margin-left: 2%;
                margin-right: 2%;
                width: calc(100vw * 100 / 1920);
              "
            >
              技术服务
            </div>
            <div></div>
          </div>
          <p class="p1">TECHNICAL SERVICE</p>
          <p class="p2" style="line-height: calc(100vw * 26 / 1920)">
            滨沅国科拥有成熟的先进技术和优秀的研发团队，在人工智能、机器视觉、无人驾驶、云计算、大数据、5G通信、物联网、智能控制等技术大力发展的背景下，不断深耕散货装卸行业，致力于提供高品质的散货装卸行业解决方案。
          </p>
        </div>
        <div class="bottom">
          <div
            v-for="(item, index) in jishufuwuCar"
            :key="index"
            @mouseenter="setImg(item)"
            @mouseleave="getImg(item)"
            class="jishuCar"
          >
            <img :src="item.url" alt="" />
            <b>{{ item.h }}</b>
            <p>{{ item.p }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="imgDiv"></div>
  </div>
</template>

<script>
export default {
  name: "EnterpriseIntroduction",
  data() {
    return {
      jishufuwuCar: [
        {
          url: require("@/assets/aboutMsg/技术咨询图标.png"),
          imgUrl1: require("@/assets/aboutMsg/技术咨询图标.png"),
          imgUrl2: require("@/assets/aboutMsg/技术咨询图标white.png"),
          h: "技术咨询",
          p: "提供合作前技术咨询、行业疑难问题免费解答",
        },
        {
          url: require("@/assets/aboutMsg/产品设计图标.png"),
          imgUrl1: require("@/assets/aboutMsg/产品设计图标.png"),
          imgUrl2: require("@/assets/aboutMsg/产品设计图标white.png"),
          h: "产品设计",
          p: "支持软件界面UI设计、功能设计、产品设计",
        },
        {
          url: require("@/assets/aboutMsg/软件开发图标.png"),
          imgUrl1: require("@/assets/aboutMsg/软件开发图标.png"),
          imgUrl2: require("@/assets/aboutMsg/软件开发图标white.png"),
          h: "软件开发",
          p: "程序开发语言丰富、技术先进、保质保量高效率",
        },
        {
          url: require("@/assets/aboutMsg/3D建模图标.png"),
          imgUrl1: require("@/assets/aboutMsg/3D建模图标.png"),
          imgUrl2: require("@/assets/aboutMsg/3D建模图标white.png"),
          h: "3D建模",
          p: "3D数字化高精度专业建模，定制还原机械图纸",
        },
        {
          url: require("@/assets/aboutMsg/数字孪生图标.png"),
          imgUrl1: require("@/assets/aboutMsg/数字孪生图标.png"),
          imgUrl2: require("@/assets/aboutMsg/数字孪生图标white.png"),
          h: "数字孪生",
          p: "基于现实需求，实现复杂系统虚实融合，实现生产流程可控化",
        },
        {
          url: require("@/assets/aboutMsg/测试运维图标.png"),
          imgUrl1: require("@/assets/aboutMsg/测试运维图标.png"),
          imgUrl2: require("@/assets/aboutMsg/测试运维图标white.png"),
          h: "测试运维",
          p: "提供产品上线前后测试运维一站式服务",
        },
      ],
      topList: [1, 1, 1, 1, 1],
      bottomList: [1, 1, 1],
      originalContent: "",
    };
  },
  mounted() {
    this.developlist();
  },
  methods: {
    setDivData(item) {
      const oDiv = event.currentTarget.querySelector(".oDiv");
      // 保存原始内容
      this.originalContent = oDiv.innerHTML;
      // 删除 h3、p 和 img 元素
      const h3 = oDiv.querySelector("h3");
      const p = oDiv.querySelector("p");
      const img = oDiv.querySelector("img");
      oDiv.removeChild(h3);
      oDiv.removeChild(p);
      oDiv.removeChild(img);
      // 添加新的元素
      const newDiv = document.createElement("p");
      newDiv.textContent = item.developExplain;
      newDiv.style.fontSize = "calc(100vw * 12 / 1920)"; // 设置字体大小
      newDiv.style.width = "95%";
      // newDiv.style.display = "-webkit-box"; // 不限制文本几行 自动换行 溢出显示省略号
      // newDiv.style.webkitBoxOrient = "vertical";
      // newDiv.style.webkitLineClamp = "6";
      // newDiv.style.maxHeight = "3.6rem";
      newDiv.style.overflow = "hidden";
      // newDiv.style.textOverflow = "ellipsis";
      newDiv.style.paddingLeft = "5%";
      newDiv.style.paddingBottom = "2%";
      newDiv.style.paddingTop = "5%";
      newDiv.style.boxSizing = "border-box";
      oDiv.appendChild(newDiv);
    },
    clearDivData() {
      const oDiv = event.currentTarget.querySelector(".oDiv");
      // 恢复原始内容
      oDiv.innerHTML = this.originalContent;
    },
    setImg(res) {
      res.url = res.imgUrl2;
    },
    getImg(res) {
      res.url = res.imgUrl1;
    },
    async developlist() {
      let result = await this.$store.dispatch("developlist");
      this.topList = result.data.upList;
      this.bottomList = result.data.downList;
      this.topList.forEach((i) => {
        i.divBool = false;
      });
      this.bottomList.forEach((i) => {
        i.divBool = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 修改滚动条样式
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 20px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  // box-shadow: inset 0 0 5px rgba(0, 123, 255, 0.2);
  background: rgba(255, 255, 255, 0.5);
  height: 40px !important;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
}
#contener {
  width: 100%;
  // height: calc(100vw * 950 / 1920);
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  // overflow: auto;
  .backWhite {
    width: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    z-index: 1;
    .contenerDiv {
      width: calc(100vw * 1200 / 1920);
      header {
        width: 100% !important;
        height: calc(100vw * 186 / 1920);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 3%;
        div:nth-child(1) {
          font-size: calc(100vw * 64 / 1920);
          color: #eff1f4;
          position: absolute;
          top: 20%;
        }
        div:nth-child(2) {
          font-size: calc(100vw * 30 / 1920);
          font-weight: 700;
          position: absolute;
          top: 40%;
          // color: #eff1f4;
        }
        div:nth-child(3) {
          width: 3%;
          height: 8px;
          position: absolute;
          top: 75%;
          background: #d91724;
        }
        div:nth-child(4) {
          position: absolute;
          font-size: calc(100vw * 16 / 1920);
          top: 90%;
        }
      }
      .dataDiv {
        width: 100%;
        height: calc(100vw * 800 / 1920);

        .topDiv {
          display: flex;
          height: calc(100vw * 500 / 1920);
          .dataDivLeft {
            width: 47%;
            height: 100%;
            // background: #c7e49f;
            div:nth-child(1) {
              display: flex;
              align-items: center;
              font-size: calc(100vw * 20 / 1920);
              div {
                width: 15%;
                margin-left: 2%;
                border: 1px solid rgba(128, 142, 162, 0.1);
              }
            }
            div:nth-child(2) {
              font-size: calc(100vw * 14 / 1920);
              color: rgba(6, 62, 141, 0.1);
            }
            div:nth-child(3) {
              width: 95%;
              letter-spacing: 5px;
              // margin-top: 3%;
              height: 20%;
              line-height: calc(100vw * 22 / 1920);
              font-size: calc(100vw * 14 / 1920);
              text-indent: 2em; /* 添加两个空格 */
              word-wrap: break-word; /* 自动换行 */
              overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
              margin-bottom: 5%;
              margin-top: 5%;
            }
            div:nth-child(4) {
              letter-spacing: 5px;
              width: 95%;
              height: 20%;
              font-size: calc(100vw * 14 / 1920);
              text-indent: 2em; /* 添加两个空格 */
              line-height: calc(100vw * 22 / 1920);
              word-wrap: break-word; /* 自动换行 */
              overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
            }
            // div:nth-child(5) {
            //   letter-spacing: 5px;
            //   width: 95%;
            //   height: 20%;
            //   font-size: calc(100vw * 14 / 1920);
            //   text-indent: 2em; /* 添加两个空格 */
            //   word-wrap: break-word; /* 自动换行 */
            //   overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
            // }
          }
          .dataDivRight {
            width: 53%;
            height: 100%;
            // background: #d0d0d0;
            position: relative;
            div:nth-child(1) {
              width: 90%;
              height: 80%;
              background: rgba(6, 62, 141, 0.1);
              position: absolute;
              right: 3%;
            }
            div:nth-child(2) {
              width: 90%;
              height: 80%;
              background: url("../../assets/aboutMsg/公司照片.png");
              background-size: 100% 100%;
              position: absolute;
              right: 6%;
              top: 4%;
              z-index: 1;
            }
          }
        }
        .bottomDiv {
          width: 100%;
          height: 25%;
          // background: #d5d5d5;
          display: flex;
          .box-card {
            width: 32%;
            // height: 95%;
            margin-right: 1%;
            position: relative;
            div:nth-child(1) {
              display: flex;
              align-items: center;
              position: absolute;
              width: 100%;
              top: 15%;
              left: 10%;
              h2 {
                color: #006aff;
                margin-right: 3%;
                font-size: calc(100vw * 24 / 1920);
              }
            }
            div:nth-child(2) {
              position: absolute;
              width: 85%;
              // height: calc(100vw * 80 / 1920) !important;
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              /*1. 超出的部分隐藏 */
              overflow: hidden;
              /*2. 文字用省略号替代超出的部分 */
              text-overflow: ellipsis;
              /* 3. 弹性伸缩盒子模型显示 */
              display: -webkit-box;
              /* 4. 限制在一个块元素显示的文本的行数 */
              -webkit-line-clamp: 4;
              /* 5. 设置或检索伸缩盒对象的子元素的排列方式 */
              -webkit-box-orient: vertical;
              top: 35%;
              left: 10%;
            }
            div:nth-child(3) {
              position: absolute;
              width: 100%;
              bottom: 0;
              height: 2%;
              background: #006aff;
            }
          }
        }
      }
    }
    .qiyewenhuaDiv {
      padding-top: 3%;
      width: calc(100vw * 1200 / 1920);
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      .topDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1%;
          div:nth-child(1) {
            width: calc(100vw * 63 / 1920);
            border: 1px solid rgba(6, 62, 141, 0.1);
          }
          div:nth-child(2) {
            font-size: calc(100vw * 20 / 1920);
            font-weight: 800;
            color: #333333;
          }
          div:nth-child(3) {
            width: calc(100vw * 63 / 1920);
            border: 1px solid rgba(6, 62, 141, 0.1);
          }
        }
        .p1 {
          margin-bottom: 1%;
          color: rgba(6, 62, 141, 0.3);
          font-size: calc(100vw * 14 / 1920);
        }
        .p2 {
          // margin-bottom: 1%;
          color: #333333;
          font-size: calc(100vw * 14 / 1920);
        }
        .p3 {
          margin-bottom: 1%;
          color: #333333;
          font-size: calc(100vw * 14 / 1920);
        }
      }
      .imgDiv {
        height: calc(100vw * 410 / 1920);
        // background: #d9d9d9;
        display: flex;
        .imgDivOne {
          margin-right: 2%;
          background: url("../../assets/aboutMsg/专业专注专心.png");
          background-size: 100% 100%;
          height: calc(100vw * 410 / 1920);
          width: calc(100vw * 590 / 1920);
          color: white;
          position: relative;
          p {
            font-size: calc(100vw * 20 / 1920);
            font-weight: 500;
            position: absolute;
            top: 21%;
            left: 5%;
          }
          .progress {
            width: calc(100vw * 350 / 1920);
            height: calc(100vw * 3 / 1920);
            background: rgba(255, 255, 255, 0.2);
            position: absolute;
            top: 35%;
            left: 5%;
            div {
              width: calc(100vw * 50 / 1920);
              height: calc(100vw * 4 / 1920);
              background: #006aff;
            }
          }
          .data {
            width: calc(100vw * 355 / 1920);
            height: calc(100vw * 184 / 1920);
            background: rgba(255, 255, 255, 0.15);
            backdrop-filter: blur(9.5px);
            padding: 3% 3%;
            box-sizing: border-box;
            word-break: break-word; //文本超出 自动换行
            position: absolute;
            bottom: 10%;
            left: 5%;
            font-size: calc(100vw * 15 / 1920);
          }
        }
        .imgDivTwo {
          margin-right: 2%;
          background: url("../../assets/aboutMsg/价值观.png");
          background-size: 100% 100%;
          height: calc(100vw * 410 / 1920);
          width: calc(100vw * 285 / 1920);
          position: relative;
          p:nth-child(1) {
            font-size: calc(100vw * 20 / 1920);
            color: #333333;
            position: absolute;
            font-weight: 500;
            top: 10%;
            left: 10%;
          }
          p:nth-child(2) {
            font-size: calc(100vw * 14 / 1920);
            color: #333333;
            position: absolute;
            top: 20%;
            left: 10%;
          }
        }
        .imgDivThree {
          background: url("../../assets/aboutMsg/企业愿景.png");
          background-size: 100% 100%;
          height: calc(100vw * 410 / 1920);
          width: calc(100vw * 285 / 1920);
          position: relative;
          p:nth-child(1) {
            font-size: calc(100vw * 20 / 1920);
            color: #333333;
            position: absolute;
            font-weight: 500;
            top: 10%;
            left: 10%;
          }
          p:nth-child(2) {
            font-size: calc(100vw * 14 / 1920);
            color: #333333;
            position: absolute;
            top: 20%;
            left: 10%;
          }
        }
      }
    }
  }
  .backWhite2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f7f9;
    z-index: 1;
    padding-bottom: 2%;
    .contenerDiv {
      width: calc(100vw * 1200 / 1920);
      padding-top: 3%;
      .topDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 4%;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1%;
          div:nth-child(1) {
            width: calc(100vw * 63 / 1920);
            border: 1px solid rgba(6, 62, 141, 0.1);
          }
          div:nth-child(2) {
            font-size: calc(100vw * 20 / 1920);
            font-weight: 800;
            color: #333333;
          }
          div:nth-child(3) {
            width: calc(100vw * 63 / 1920);
            border: 1px solid rgba(6, 62, 141, 0.1);
          }
        }
        .p1 {
          margin-bottom: 2%;
          color: rgba(6, 62, 141, 0.3);
          font-size: calc(100vw * 14 / 1920);
        }
        .p2 {
          // margin-bottom: 1%;
          color: #333333;
          font-size: calc(100vw * 14 / 1920);
          word-break: break-word; //文本超出 自动换行
          width: 70%;
          text-align: center;
        }
      }
      .bottom {
        display: flex;
        flex-flow: row wrap;
        div {
          background: white;
          width: 32%;
          height: calc(100vw * 240 / 1920);
          margin-right: 1.3%;
          margin-bottom: 1.3%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          // cursor: pointer;
          img {
            // color: #006aff;
            position: absolute;
            top: 15%;
            width: calc(100vw * 60 / 1920);
            height: calc(100vw * 60 / 1920);
          }
          b,
          p {
            width: 90%;
            text-align: center;
            word-break: break-word; //文本超出 自动换行
          }
          b {
            position: absolute;
            top: 50%;
            font-size: calc(100vw * 16 / 1920);
            font-weight: 700;
          }
          p {
            position: absolute;
            top: 65%;
            font-size: calc(100vw * 14 / 1920);
          }
        }
        div:hover {
          color: white;
          background: linear-gradient(180deg, #2c7adf 0%, #2eabdf 100%);
        }
      }
    }
  }

  #imgDiv {
    width: 100%;
    height: calc(100vw * 1000 / 1920);
    background: url("../../assets/aboutMsg/imgDiv.png");
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .carDiv {
    width: calc(100vw * 1200 / 1920);
    height: calc(100vw * 700 / 1920);
    z-index: 1;
    color: white;
    padding-top: 3%;
    .myYoushi {
      // position: relative;
      width: 100%;
      overflow-x: auto;
      .hrDiv {
        width: 100%;
        height: calc(100vw * 1 / 1920);
        // border: 1px solid #e0e0e0;
        background: #e0e0e0;
        padding-left: 10%;
        // margin-right: 10%;
        position: relative;
      }
      .ul {
        margin-top: 5%;
        display: flex;
        // display: inline-block;
        width: 100%;
        margin-bottom: 2%;
        .li {
          margin-bottom: 3%;

          width: calc(100vw * 284 / 1920);
          height: calc(100vw * 151 / 1920);
          background: #f5f5f5;
          border-radius: 10px;
          margin-right: 1%;
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: transform 0.3s ease-out;
          background: rgba(255, 255, 255, 0.8);
          // cursor: pointer;
          div {
            width: calc(100vw * 284 / 1920);
            height: calc(100vw * 151 / 1920);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
            border-radius: 10px 10px 0px 0px;
            position: relative;
            h3 {
              font-weight: 700;
              font-size: calc(100vw * 24 / 1920);
              color: #333333;
              position: absolute;
              top: 20%;
              left: 15%;
            }
            p {
              font-size: calc(100vw * 14 / 1920);
              color: #333333;
              position: absolute;
              top: 50%;
              left: 15%;
              width: calc(100vw * 137 / 1920);
              display: -webkit-box; //不限制文本几行 自动换行 溢出显示省略号
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              max-height: 3.6rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            img {
              width: calc(100vw * 137 / 1920);
              height: calc(100vw * 137 / 1920);
              position: absolute;
              right: -5%;
              bottom: -10%;
            }
          }
        }
        .li:hover {
          transform: translateY(10px);
          background: linear-gradient(
            270deg,
            rgba(0, 148, 255, 0.8) 0%,
            rgba(0, 102, 255, 0.8) 100%
          );
          div {
            h3,
            p {
              color: white !important;
            }
          }
        }
      }
    }
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        div:nth-child(1) {
          width: calc(100vw * 63 / 1920);
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
        div:nth-child(2) {
          width: calc(100vw * 100 / 1920);
          font-size: calc(100vw * 20 / 1920);
          font-weight: 700;
        }
        div:nth-child(3) {
          width: calc(100vw * 63 / 1920);
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
      .p1 {
        color: rgba(255, 255, 255, 0.6);
        margin-top: 1%;
        margin-bottom: 1%;
      }
      .p2 {
        font-size: calc(100vw * 14 / 1920);
      }
      .p3 {
        font-size: calc(100vw * 14 / 1920);
      }
    }
  }
}
</style>
