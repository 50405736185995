<template>
  <div class="productCenterIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <el-carousel
          trigger="click"
          :interval="swiperTime"
          class="swiper"
          :height="imgHeight"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topDivimg">
        <div class="topDiv">
          <header>
            <div>JOIN US</div>
            <div>联系我们</div>
            <div></div>
            <!-- <div style="text-align: center">
              公司产品主要有智能控制和智能运维两大核心业务，囊括散货大型装卸设备管控自动化、生产管控智能化、运维监管智能化、环境综合管控一体化、针对行业场景的打造了“沅视听”智能机器学习平台和数字孪生系统平台，为客户长期保驾护航的互联网+VR的智能运维(巡检)平台，致力于成为智慧港口、智慧钢铁、智慧电厂建设的智造专家。
            </div> -->
          </header>
        </div>
      </div>
      <div id="w100">
        <div id="bx">
          <div class="car">
            <div>
              <img src="../../assets/contacus/1.png" alt="" />
              <h3>资讯热线</h3>
              <p>0335-8352973</p>
            </div>
            <div>
              <img src="../../assets/contacus/2.png" alt="" />
              <h3>联系地址</h3>
              <p>河北省秦皇岛市开发区兴凯湖路与龙海道交汇处科技大厦4层</p>
            </div>
            <div>
              <img src="../../assets/contacus/3.png" alt="" />
              <h3>联系邮箱</h3>
              <p>ydbykj@163.com</p>
            </div>
            <div>
              <img src="../../assets/contacus/4.png" alt="" />
              <h3>联系客服</h3>
              <p>微信：DJW0401_222</p>
              <span>QQ：</span>
            </div>
          </div>
          <div class="topDiv" style="margin-top: 5%">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>我们在哪</h3>
                <div></div>
              </div>
              <div class="d2">Where are we</div>
            </div>
          </div>
        </div>
      </div>
      <div id="w100">
        <!-- <div id="map">
          <baidu-map
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 100%"
          >
            <bm-marker :position="markerPosition" :title="markerTitle">
            </bm-marker>
          </baidu-map>
        </div> -->
        <!--地图容器-->
        <div
          id="container"
          style="width: 100%; height: calc(100vw * 700 / 1920)"
        ></div>
      </div>
      <div id="w100" style="padding-bottom: 2%">
        <div id="bx">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>全国办事处</h3>
                <div></div>
              </div>
              <div class="d2">National Office</div>
            </div>
          </div>
          <div class="twoCar">
            <!-- <img
              style="
                width: 100%;
                height: calc(100vw * 546 / 1920);
                display: block;
              "
              src="../../assets/contacus/办事处.png"
              alt=""
            /> -->
            <LiList />
          </div>

          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>在线留言</h3>
                <div></div>
              </div>
              <div class="d2">MESSAGE</div>
            </div>
          </div>
          <div class="inputDiv">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="50px"
              class="demo-ruleForm"
            >
              <el-form-item label="姓名" prop="leveContacts">
                <el-input v-model="ruleForm.leveContacts"></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="levePhone">
                <el-input v-model="ruleForm.levePhone"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="leveEmail">
                <el-input v-model="ruleForm.leveEmail"></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="leveAddr">
                <el-input v-model="ruleForm.leveAddr"></el-input>
              </el-form-item>
            </el-form>
            <el-form
              :model="ruleForm"
              ref="ruleForm"
              label-width="50px"
              class="ruleFormDiv"
            >
              <el-form-item label="内容" prop="leveContent">
                <!-- <el-input
                  class="leveContent"
                  v-model="ruleForm.leveContent"
                ></el-input> -->
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="ruleForm.leveContent"
                >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="buttonDiv">
            <el-button @click="insertOne">提交内容</el-button>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";
import LiList from "./liList.vue";

import AMapLoader from "@amap/amap-jsapi-loader"; //引入

export default {
  name: "newsIndex",
  metaInfo: {
    // 设置关键词
    meta: {
      keywords: "联系我们,滨沅国科联系方式,滨沅国科联系",
    },
  },
  components: {
    Harder,
    Footer,
    LiList,
  },
  data() {
    return {
      bannerList: [
        {
          url: require("@/assets/contacus/联系我们banner.png"),
          link: "",
        },
      ],
      swiperTime: 7000,
      map: null, //初始化 map 对象
      imgHeight: "calc(100vw * 800 / 1920)",
      center: { lng: 119.48264, lat: 39.93168 },
      zoom: 15,
      markerPosition: { lng: 119.48264, lat: 39.93168 },
      markerTitle: "滨沅国科（秦皇岛）智能科技股份有限公司",
      labelContent: "1",
      ruleForm: {
        leveContacts: "",
        levePhone: "",
        leveEmail: "",
        leveAddr: "",
        leveContent: "",
      },
      rules: {
        leveContacts: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 4, message: "长度为 2 到 4 个字", trigger: "blur" },
        ],
        levePhone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        // leveEmail: [
        //   { required: true, message: "请输入邮箱地址", trigger: "blur" },
        //   {
        //     type: "email",
        //     message: "请输入正确的邮箱地址",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // leveAddr: [
        //   { required: true, message: "请输入地址", trigger: "blur" },
        //   {
        //     min: 2,
        //     max: 25,
        //     message: "请保持地址的长度在 2 到 25 个字之内",
        //     trigger: "blur",
        //   },
        // ],
        // leveContent: [
        //   { required: true, message: "请输入内容", trigger: "blur" },
        //   {
        //     min: 5,
        //     max: 100,
        //     message: "内容请在 5 到 100 个字内",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },

  methods: {
    async insertOne() {
      if (
        this.ruleForm.leveContacts &&
        this.ruleForm.levePhone
        //  &&
        // this.ruleForm.leveEmail &&
        // this.ruleForm.leveAddr &&
        // this.ruleForm.leveContent
      ) {
        let result = await this.$store.dispatch("insertOne", {
          leveContacts: this.ruleForm.leveContacts, //联系人
          levePhone: this.ruleForm.levePhone, //联系电话
          leveEmail: this.ruleForm.leveEmail, //邮箱
          leveAddr: this.ruleForm.leveAddr, //地址
          leveContent: this.ruleForm.leveContent, //留言内容
        });
        if (result.data.code == 200) {
          this.$message({
            message: "留言成功",
            type: "success",
          });
          this.ruleForm.leveContacts = ""; //联系人
          this.ruleForm.levePhone = ""; //联系电话
          this.ruleForm.leveEmail = ""; //邮箱
          this.ruleForm.leveAddr = ""; //地址
          this.ruleForm.leveContent = ""; //留言内容
        }
      } else {
        this.$message({
          message: "请输入内容",
          type: "warning",
        });
      }
    },
    initMap() {
      var center = new TMap.LatLng(39.925495627289, 119.47667170175); //设置中心点坐标
      //初始化地图
      var map = new TMap.Map(container, {
        center: center,
      });

      //创建并初始化MultiMarker
      var markerLayer = new TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        // styles: {
        //   label: new TMap.LabelStyle({
        //     color: "#d91724", //颜色属性
        //     size: 20, //文字大小属性
        //     offset: { x: 0, y: 0 }, //文字偏移属性单位为像素
        //     angle: 0, //文字旋转属性
        //     alignment: "center", //文字水平对齐属性
        //     verticalAlignment: "middle", //文字垂直对齐属性
        //   }),
        // },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(39.925495627289, 119.47667170175), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
            content: "滨沅国科（秦皇岛）智能科技股份有限公司", //标注文本
          },
        ],
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initMap();
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    // margin-right: 40px;
    // margin-bottom: 10%;
    display: none;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

// 修改滚动条样式
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 20px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  // box-shadow: inset 0 0 5px rgba(0, 123, 255, 0.2);
  background: rgb(208, 208, 208);
  height: 40px !important;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgb(239, 239, 239);
}

.productCenterIndexDiv {
  width: 100%;

  #w100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    #map {
      width: 100%;
      height: calc(100vw * 700 / 1920);
      // background: url("../../assets/contacus/地图.png");
      background-size: 100% 100%;
    }
  }
  #bx {
    width: calc(100vw * 1200 / 1920);
    // margin-top: 3%;
    .car {
      width: 100%;
      height: calc(100vw * 313 / 1920);
      display: flex;
      div {
        height: calc(100vw * 313 / 1920);
        width: calc(100vw * 285 / 1920);
        // background: beige;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        // cursor: pointer;
        transition: transform 0.3s ease-out;
        img {
          height: calc(100vw * 123 / 1920);
          width: calc(100vw * 123 / 1920);
          position: absolute;
          top: 20%;
        }
        h3 {
          position: absolute;
          top: 65%;
          font-size: calc(100vw * 16 / 1920);
          color: #000000;
        }
        p {
          position: absolute;
          top: 75%;
          width: 65%;
          text-align: center;
          font-size: calc(100vw * 14 / 1920);
          color: #333333;
        }
        span {
          position: absolute;
          top: 83%;
          width: 65%;
          font-size: calc(100vw * 14 / 1920);
          color: #333333;
          text-align: center;
        }
      }
      div:hover {
        transform: translateY(-10px);
        border-bottom: calc(100vw * 3 / 1920) solid #006aff;
      }
    }
    .topDiv {
      display: flex;
      margin-bottom: 2%;
      margin-top: 2%;
      .dataDivLeft {
        width: 47%;
        // height: 100%;
        // background: #c7e49f;
        .d1 {
          display: flex;
          align-items: center;
          font-size: calc(100vw * 20 / 1920);
          div {
            width: 15%;
            // margin-left: 2%;
            border: 1px solid rgba(128, 142, 162, 0.1);
          }
        }
        .d2 {
          font-size: calc(100vw * 14 / 1920);
          color: rgba(6, 62, 141, 0.1);
          margin-top: 1%;
          margin-bottom: 3%;
        }
      }
    }
    .twoCar {
      width: 100%;
      // height: calc(100vw * 546 / 1920);
      // display: flex;
      // position: relative;
      margin-bottom: 4%;
    }
    .inputDiv {
      width: 100%;

      /deep/ .el-input {
        font-size: calc(100vw * 18 / 1920) !important;
      }
      .d1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .left {
        width: 45%;
        /deep/ .el-input {
          margin-top: 2%;
          margin-bottom: 5%;
          .el-input__inner {
            height: calc(100vw * 40 / 1920);
            padding-left: 3%;
          }
        }
      }
      .right {
        width: 45%;
        /deep/ .el-input {
          margin-top: 2%;
          margin-bottom: 5%;
          .el-input__inner {
            height: calc(100vw * 40 / 1920);
            padding-left: 3%;
          }
        }
      }
      .bottomDiv {
        width: 100%;
        /deep/ .el-textarea {
          height: calc(100vw * 162 / 1920);
          margin-top: 1%;
          margin-bottom: 5%;

          .el-textarea__inner {
            height: calc(100vw * 162 / 1920);
            padding-top: 1%;
            box-sizing: border-box;
            padding-left: 1%;
          }
        }
      }
      /deep/ .demo-ruleForm {
        display: flex;
        flex-wrap: wrap;
        .el-form-item {
          width: calc(100vw * 600 / 1920);
          height: calc(100vw * 40 / 1920);
          margin-bottom: 3%;
          .el-form-item__label {
            width: 100px !important;
            line-height: calc(100vw * 40 / 1920) !important;
            font-size: calc(100vw * 13 / 1920) !important;
          }
          .el-input {
            height: calc(100vw * 40 / 1920);

            .el-input__inner {
              height: calc(100vw * 40 / 1920);
            }
          }
        }
      }
      /deep/ .ruleFormDiv {
        width: 100%;
        .el-form-item {
          .el-form-item__label {
            width: 100px !important;
            line-height: calc(100vw * 70 / 1920) !important;
            font-size: calc(100vw * 13 / 1920) !important;
          }
          .el-textarea {
            height: calc(100vw * 80 / 1920);

            .el-textarea__inner {
              height: calc(100vw * 80 / 1920);
            }
          }
        }
      }
    }
    .buttonDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vw * 40 / 1920);
      /deep/ .el-button {
        width: calc(100vw * 160 / 1920);
        height: calc(100vw * 40 / 1920);
        background: #d91724;
        border-radius: 2px;
        color: white;
        font-size: calc(100vw * 16 / 1920);
      }
    }
  }
  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .swiperDiv {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .topDivimg {
      width: 100%;
      height: calc(100vw * 200 / 1920);
      // background: url("../../assets/productCenterMsg/topHeader.png");
      // background-size: 100% 100%;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      //   margin-bottom: 2%;
      // padding-bottom: 3%;
      .topDiv {
        width: calc(100vw * 1200 / 1920);
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 2%;
        header {
          width: 80% !important;
          height: calc(100vw * 186 / 1920);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          //   margin-bottom: 3%;

          div:nth-child(1) {
            font-size: calc(100vw * 64 / 1920);
            color: #eff1f4;
            position: absolute;
            top: 20%;
          }
          div:nth-child(2) {
            font-size: calc(100vw * 30 / 1920);
            font-weight: 700;
            position: absolute;
            top: 40%;
            // color: #eff1f4;
          }
          div:nth-child(3) {
            width: 3%;
            height: 8px;
            position: absolute;
            top: 75%;
            background: #d91724;
          }
          div:nth-child(4) {
            position: absolute;
            font-size: calc(100vw * 16 / 1920);
            top: 90%;
          }
        }
      }
    }
  }
}
</style>
