<template>
  <div class="productCenterIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <el-carousel
          trigger="click"
          :interval="swiperTime"
          class="swiper"
          :height="imgHeight"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="" />
            <!-- <h3>山东港口青岛港自动化码头生产不断创出新佳绩</h3> -->
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topDivimg">
        <div class="topDiv">
          <header>
            <div>
              <h3 v-if="data.infoType == 1">行业资讯</h3>
              <h3 v-if="data.infoType == 2">公司资讯</h3>
              <h4>&nbsp;&nbsp;&nbsp;NEWS</h4>
            </div>
            <div>
              <span
                @click="$router.push('/newsIndex')"
                style="
                  color: #063e8d;
                  cursor: pointer;
                  font-size: calc(100vw * 16 / 1920);
                "
                >新闻资讯></span
              >
              <span
                @click="$router.push('/newsAny')"
                style="
                  color: #063e8d;
                  cursor: pointer;
                  font-size: calc(100vw * 16 / 1920);
                "
                >资讯列表</span
              >
              <p style="font-size: calc(100vw * 16 / 1920)">>资讯详情</p>
            </div>
          </header>
          <div class="dateDiv">
            <div>发布时间：{{ data.infoDate }}</div>
            <div>来自：{{ data.infoCfrom }}</div>
          </div>
          <div class="dataDiv">
            <h1>{{ data.infoTitle }}</h1>
            <div class="titleDiv">
              <div class="left">
                <div>摘</div>
                <div>要</div>
              </div>
              <div class="right">
                {{ data.infoOutLine }}
              </div>
            </div>
            <div class="topText">
              {{ data.infoExplain1 }}
            </div>
            <img
              v-if="data.infoExplainImg !== null"
              class="imgText"
              :src="'gw/prod-api' + data.infoExplainImg"
              alt=""
            />
            <div class="topText">
              {{ data.infoExplain2 }}
            </div>
            <div class="topText">
              {{ data.infoExplain3 }}
            </div>
            <div class="hrDiv"></div>
            <div class="nextText">
              <div v-show="upInfo">
                <p>上一篇：</p>
                <span v-if="upInfo" @click="upData(upInfo)">{{ upTitle }}</span>
              </div>
              <div v-show="downInfo">
                <p>下一篇：</p>
                <span v-if="downInfo" @click="downData(downInfo)">{{
                  downTitle
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";
export default {
  name: "newsIndex",
  components: {
    Harder,
    Footer,
  },
  data() {
    return {
      bannerList: [
        {
          url: require("@/assets/news/新闻中心banner2.png"),
          link: "",
        },
        // {
        //   url: require("@/assets/news/banner3.png"),
        //   link: "",
        // },
        // {
        //   url: require("@/assets/news/banner2.png"),
        //   link: "",
        // },
      ],
      swiperTime: 7000,
      data: {},
      upInfo: "",
      upTitle: "",
      downInfo: "",
      downTitle: "",
      imgHeight: "calc(100vw * 800 / 1920)",
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.upInfo = "";
      this.upTitle = "";
      this.downInfo = "";
      this.downTitle = "";
      let result = await this.$store.dispatch("getData", {
        infoId: Number(this.$route.query.id),
      });

      if (result.data.code == 200) {
        this.data = result.data.data;
        if (result.data.downInfo) {
          this.downInfo = result.data.downInfo.infoId;
          this.downTitle = result.data.downInfo.infoTitle;
        }
        if (result.data.upInfo) {
          this.upInfo = result.data.upInfo.infoId;
          this.upTitle = result.data.upInfo.infoTitle;
        }
      } else {
        this.data = {};
      }
    },
    async upData(id) {
      this.upInfo = "";
      this.upTitle = "";
      this.downInfo = "";
      this.downTitle = "";
      let result = await this.$store.dispatch("getData", {
        infoId: id,
      });
      if (result.data.code == 200) {
        this.data = result.data.data;
        if (result.data.downInfo !== undefined) {
          this.downInfo = result.data.downInfo.infoId;
          this.downTitle = result.data.downInfo.infoTitle;
        }
        if (result.data.upInfo !== undefined) {
          this.upInfo = result.data.upInfo.infoId;
          this.upTitle = result.data.upInfo.infoTitle;
        }
      } else {
        this.data = {};
      }
    },
    async downData(id) {
      let result = await this.$store.dispatch("getData", {
        infoId: id,
      });
      this.upInfo = "";
      this.upTitle = "";
      this.downInfo = "";
      this.downTitle = "";
      if (result.data.code == 200) {
        this.data = result.data.data;
        // this.$router.push(`/indetail?id=${result.data.data.infoId}`);
        // this.$router.history.current.query.id = result.data.data.infoId;
        if (result.data.downInfo !== undefined) {
          this.downInfo = result.data.downInfo.infoId;
          this.downTitle = result.data.downInfo.infoTitle;
        }
        if (result.data.upInfo !== undefined) {
          this.upInfo = result.data.upInfo.infoId;
          this.upTitle = result.data.upInfo.infoTitle;
        }
      } else {
        this.data = {};
      }
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    margin-right: 40px;
    margin-bottom: 10%;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

.productCenterIndexDiv {
  width: 100%;
  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .swiperDiv {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .topDivimg {
      width: 100%;
      //   height: calc(100vw * 200 / 1920);
      display: flex;
      flex-direction: column;
      align-items: center;
      .topDiv {
        width: calc(100vw * 1200 / 1920);
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 2%;
        header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #aaaaaa;
          height: calc(100vw * 70 / 1920);
          margin-top: 3%;
          margin-bottom: 1%;
          div {
            display: flex;
            align-items: center;
            h3 {
              font-size: calc(100vw * 24 / 1920);
              color: #000000;
              //   margin-right: 1%;
            }
            h4 {
              font-size: calc(100vw * 24 / 1920);
              color: #dfdfdf;
            }
            a {
              text-decoration: none;
              color: #063e8d;
            }
            p {
              color: #666666;
            }
          }
        }
        .dateDiv {
          margin-top: 1%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666666;
          font-size: calc(100vw * 16 / 1920);
          margin-bottom: 3%;
        }
        .dataDiv {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          h1 {
            color: #333333;
            font-size: calc(100vw * 40 / 1920);
          }
          .titleDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3%;
            margin-bottom: 3%;
            height: calc(100vw * 120 / 1920);
            width: 100%;
            background: #f8f8f8;
            .left {
              height: 70%;
              width: 14%;
              display: flex;
              align-items: center;
              padding-left: 5%;
              border-right: 1px solid #e7e7e7;
              div:nth-child(1) {
                background: #063e8d;
                color: white;
                font-size: calc(100vw * 32 / 1920);
                font-weight: 700;
                width: calc(100vw * 56 / 1920);
                height: calc(100vw * 47 / 1920);
                text-align: center;
              }
              div:nth-child(2) {
                background: #ffffff;
                color: #d91724;
                font-size: calc(100vw * 32 / 1920);
                font-weight: 700;
                width: calc(100vw * 56 / 1920);
                height: calc(100vw * 47 / 1920);
                text-align: center;
              }
            }
            .right {
              height: 45%;
              width: 75%;
              padding-right: 5%;
              box-sizing: border-box;
              font-weight: 400;
              font-size: calc(100vw * 14 / 1920);
            }
          }
          .topText {
            font-weight: 400;
            font-size: calc(100vw * 14 / 1920);
            color: #000000;
            line-height: calc(100vw * 30 / 1920);
            margin-bottom: 3%;
          }
          .imgText {
            margin-bottom: 3%;
            width: 100%;
            height: calc(100vw * 683 / 1920);
          }
          .hrDiv {
            width: 100%;
            border: 1px solid #cdcdcd;
            margin-bottom: 3%;
          }
          .nextText {
            width: 100%;
            height: calc(100vw * 100 / 1920);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3%;
            position: relative;
            div {
              display: flex;
              align-items: center;
              font-size: calc(100vw * 16 / 1920);
              span {
                cursor: pointer;
                color: #063e8d;
              }
            }
            div:nth-child(1) {
              position: absolute;
              left: 0;
            }
            div:nth-child(2) {
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
