<template>
  <div id="FooterDiv">
    <div class="OneDiv">
      <div class="main">
        <h1>为什么选择滨沅国科？</h1>
        <h4>行业领先的实力，全球500强的共同选择</h4>
        <el-button id="elButton" round @click="dialogBool = true"
          >了解更多 》</el-button
        >
        <!-- <div id="logoList1"></div>
        <div id="logoList2"></div> -->
      </div>
      <div class="dataTitle">
        <img src="../assets//FooterMsg/lj.png" alt="" />
        <h2>友情链接</h2>
      </div>
      <div class="dataDiv">
        <div class="bx">
          <div v-for="(item, index) in imgUrlList" :key="index">
            <img
              @click="goImgUrl(item.partnerPath)"
              :src="item.partnerIcon"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="TwoDiv">
      <div class="main">
        <h1>免费体验智能控制系统解决方案</h1>
        <h4>优势一试便知</h4>
        <div id="inputDiv">
          <div>
            <input
              class="el-input"
              type="text"
              v-model="phoneInput"
              placeholder="请输入你的手机号"
            />
          </div>
          <div>
            <el-button class="el-button" type="primary" @click="smsSendMsg"
              >免费咨询</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="ThreeDiv">
      <div class="main">
        <div class="left">
          <img
            style="
              width: calc(100vw * 146 / 1920);
              height: calc(100vw * 35 / 1920);
            "
            src="../assets/logoWhite.png"
            alt=""
          />
          <div
            class="d1"
            style="cursor: pointer; font-size: calc(100vw * 16 / 1920)"
            @click="openNewPage"
          >
            <span>燕山大学港口自动化研究所</span>
            <span>（产学研一体化）</span>
          </div>
          <div class="d2">
            <!-- <div></div>
            <div></div> -->
          </div>
          <p class="p1">创新 / 智能 / 共赢</p>
          <p class="p2">人工智能 | 机器视觉 | 云计算 | 5G | 物联网 | 大数据</p>
        </div>
        <div class="center">
          <div
            class="centerDiv"
            v-for="(item, index) in lianxiList"
            :key="index"
          >
            <img :src="item.imgUrl" alt="" />
            <div>{{ item.p }}</div>
          </div>
        </div>
        <div class="right">
          <div>
            <img
              style="
                width: calc(100vw * 121 / 1920);
                height: calc(100vw * 121 / 1920);
              "
              src="../assets/FooterMsg/code.png"
              alt=""
            />
            <!-- <p style="font-size: calc(100vw * 14 / 1920)">微信公众号</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="FourDiv">
      <div class="main">
        滨沅国科（秦皇岛）智能科技股份有限公司 &copy;
        版权所有&nbsp;&nbsp;&nbsp;<span @click="goIcp()"
          >冀ICP备20015907号-1</span
        >
      </div>
    </div>
    <div class="dialogDiv" v-if="dialogBool">
      <div class="inputDiv">
        <div class="titleDiv">
          <div></div>
          <div>
            <p>请留下您的联系方式</p>
            <p>我们会尽快和您联系</p>
          </div>
          <div></div>
        </div>
        <div class="inputList">
          <el-input
            class="el-input"
            v-model="contactName"
            placeholder="姓名（必填）"
          ></el-input>

          <div
            style="
              display: flex;
              color: #ffffff;

              margin-bottom: 4%;
              align-items: center;
              font-size: calc(100vw * 16 / 1920);
            "
          >
            <p>贵司的身份：</p>
            <el-radio
              style="font-size: calc(100vw * 16 / 1920)"
              v-model="companyIdentity"
              label="1"
              >终端用户</el-radio
            >
            <el-radio
              style="font-size: calc(100vw * 16 / 1920)"
              v-model="radio"
              label="2"
              >中间商</el-radio
            >
          </div>
          <el-input
            class="el-input"
            v-model="email"
            placeholder="邮箱（必填）"
          ></el-input>
          <el-input
            class="el-input"
            v-model="company"
            placeholder="公司（必填）"
          ></el-input>
          <el-input
            class="el-input"
            v-model="province"
            placeholder="省份（必填）"
          ></el-input>
          <el-input
            class="el-input"
            v-model="industry"
            placeholder="行业（必填）"
          ></el-input>
          <el-input
            class="el-input"
            v-model="position"
            placeholder="职务（必填）"
          ></el-input>
          <el-input
            class="el-input"
            type="textarea"
            :rows="2"
            placeholder="需求（必填）"
            v-model="demand"
          >
          </el-input>
          <el-input
            class="el-input"
            v-model="phone"
            placeholder="手机号（必填）"
          ></el-input>
          <div style="display: flex">
            <el-input
              class="el-input"
              v-model="code"
              placeholder="验证码（必填）"
            ></el-input>
            <el-button class="el-button" type="primary" @click="sendCode"
              >发送验证码</el-button
            >
          </div>
          <!-- <div class="buttonDiv">
            <input type="checkbox" class="checkbox" v-model="checked" />
            <div>阅读并接受<span>隐私政策</span>和<span>法律声明</span></div>
          </div> -->
          <el-button class="confirm" type="primary" @click="insertRow"
            >确认提交</el-button
          >
          <span class="close" @click="dialogBool = false">关闭</span>
        </div>
      </div>
      <div class="back"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      lianxiList: [
        {
          imgUrl: require("@/assets/FooterMsg/地址.png"),
          p: "地址：河北省秦皇岛市开发区龙海道与兴凯湖路交叉口科技大厦4层",
        },
        {
          imgUrl: require("@/assets/FooterMsg/邮编.png"),
          p: "邮编：066000",
        },
        {
          imgUrl: require("@/assets/FooterMsg/电话.png"),
          p: "电话：0335-8352973",
        },
        {
          imgUrl: require("@/assets/FooterMsg/email.png"),
          p: "E-mail：ydbykj@163.com",
        },
        {
          imgUrl: require("@/assets/FooterMsg/网址.png"),
          p: "www.ydbykj.com",
        },
      ],
      textarea: "",
      checked: false,
      dialogBool: false,
      phoneInput: "",

      contactName: "",
      phone: "",
      email: "",
      company: "",
      province: "",
      industry: "",
      position: "",
      demand: "",
      companyIdentity: "1",
      code: "",
      imgUrlList: [],
    };
  },
  mounted() {
    this.partnership();
  },
  methods: {
    openNewPage() {
      window.open("http://iee.ysu.edu.cn/portauto/", "newPage");
    },
    async smsSendMsg() {
      if (this.phoneInput.length == 11) {
        let result = await this.$store.dispatch("smsSendMsg", {
          phoneNumber: this.phoneInput,
        });
        if (result.data.code == 200) {
          this.$message({
            message: "登记成功，公司相关工作人员会尽快与您联系",
            type: "success",
          });
          this.phoneInput = "";
        } else {
          this.$message({
            message: result.data.msg,
            type: "warning",
          });
          this.phoneInput = "";
        }
      } else {
        this.$message({
          message: "请输入正确的电话号码",
          type: "warning",
        });
        // this.phoneInput = "";
      }
    },
    async sendCode() {
      let result = await this.$store.dispatch("sendCode", {
        phone: this.phone,
      });
      if (result.data.code == 200) {
        this.$message({
          message: "发送成功，请注意查看短信",
          type: "success",
        });
      }
    },
    async insertRow() {
      let result = await this.$store.dispatch("insertRow", {
        contactName: this.contactName,
        phone: this.phone, //手机
        email: this.email, //邮箱
        company: this.company, //公司
        province: this.province, //省份
        industry: this.industry, //行业
        position: this.position, //职位
        demand: this.demand, //需求
        companyIdentity: this.companyIdentity, //公司分类 1 终端用户 2中间商
        code: this.code, //验证码
      });
      if (result.data.code == 200) {
        this.$message({
          message: "提交成功，工作人员会尽快与您联系",
          type: "success",
        });
        this.dialogBool = false;
        (this.contactName = ""),
          (this.phone = ""), //手机
          (this.email = ""), //邮箱
          (this.company = ""), //公司
          (this.province = ""), //省份
          (this.industry = ""), //行业
          (this.position = ""), //职位
          (this.demand = ""), //需求
          (this.companyIdentity = ""), //公司分类 1 终端用户 2中间商
          (this.code = ""); //验证码
      } else {
        this.$message({
          message: "请输入验证码",
          type: "warning",
        });
      }
    },
    goIcp() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    async partnership() {
      let res = await this.$store.dispatch("partnership");
      // console.log(res);
      res.data.data.forEach((item) => {
        item.partnerIcon = "gw/prod-api" + item.partnerIcon;
      });
      this.imgUrlList = res.data.data;
      // res.data.data.forEach((item) => {
      //   this.imgUrlList.push(item);
      // });
    },
    goImgUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
#FooterDiv {
  width: 100%;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  background: white;
  // z-index: 1;
  .OneDiv {
    height: calc(100vw * 681 / 1920);
    width: 100%;
    background: url("../assets/FooterMsg/d1.png");
    background-size: 100% 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .main {
      width: calc(100vw * 1200 / 1920);
      position: relative;
      height: calc(100vw * 651 / 1920);
      h1 {
        font-size: calc(100vw * 36 / 1920);
        color: white;
        font-weight: 100;
        position: absolute;
        top: 20%;
        user-select: none;
      }
      h4 {
        font-size: calc(100vw * 16 / 1920);
        color: white;
        font-weight: 100;
        position: absolute;
        top: 35%;
        user-select: none;
      }
      #elButton {
        // position: absolute;
        // top: 60%;
        // left: 20%;
        background-color: #d91724 !important;
        color: white !important;
        border: none !important;
        width: calc(100vw * 180 / 1920);
        height: calc(100vw * 48 / 1920);
        font-size: calc(100vw * 20 / 1920) !important;
        // border-radius: 5%;
        position: absolute;
        top: 60%;
        user-select: none;
        text-align: center;
        line-height: calc(100vw * 28 / 1920);
      }
      #elButton:hover {
        /* font-weight: 800 !important; */
        transform: scale(1.1);
        /* font-size: 48px !important; */
        transition: transform 0.3s ease-in-out;
      }
      #logoList1 {
        width: 100%;
        height: calc(100vw * 125 / 1920);
        background: url("../assets/FooterMsg/底部导航One图标.png");
        background-size: 100% 100%;
        position: absolute;
        top: 60%;
      }
      #logoList2 {
        width: 100%;
        height: calc(100vw * 125 / 1920);
        background: url("../assets/FooterMsg/底部导航Two图标.png");
        background-size: 100% 100%;
        position: absolute;
        top: 80%;
      }
    }
    .dataTitle {
      width: 100%;
      // position: absolute;
      // top: 53%;
      background: linear-gradient(
        to right,
        #515e67 0%,
        #ffffff 50%,
        #515e67 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: calc(100vw * 30 / 1920);
        height: calc(100vw * 30 / 1920);
        margin-right: 0.5%;
      }
      h2 {
        // display: block;
        color: #061429;
        height: calc(100vw * 40 / 1920);
        line-height: calc(100vw * 40 / 1920);
        font-weight: 500;
        font-size: calc(100vw * 20 / 1920);
        text-align: center;
      }
    }
    .dataDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      // position: absolute;
      // top: 65%;
      padding-top: 2%;
      padding-left: 2%;
      padding-bottom: 2%;
      box-sizing: border-box;
      background: linear-gradient(
        to right,
        #6b7c88 0%,
        #ffffff 50%,
        #6b7c88 100%
      );
      .bx {
        width: calc(100vw * 1200 / 1920);
        display: flex;
        flex-wrap: wrap;
        div {
          width: 20%;
          // margin-right: 2%;
          margin-top: 2%;
          margin-bottom: 2%;
          // flex: 1;
        }
        img {
          height: calc(100vw * 30 / 1920);
          width: calc(100vw * 170 / 1920);
          // width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .TwoDiv {
    height: calc(100vw * 283 / 1920);
    width: 100%;
    background: url("../assets/FooterMsg/d2.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
    z-index: 1;
    .main {
      width: calc(100vw * 1200 / 1920);
      height: calc(100vw * 283 / 1920);
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      input:focus {
        outline: none;
      }
      input {
        color: #fff;
        padding-left: 7%;
        box-sizing: border-box;
      }
      input::placeholder {
        color: #fff;
      }
      h1 {
        font-size: calc(100vw * 30 / 1920);
        color: white;
        font-weight: 100;
        position: absolute;
        top: 20%;
        user-select: none;
      }
      h4 {
        font-size: calc(100vw * 16 / 1920);
        color: white;
        font-weight: 100;
        position: absolute;
        top: 40%;
        user-select: none;
      }
      #inputDiv {
        width: calc(100vw * 326 / 1920);
        height: calc(100vw * 40 / 1920);
        display: flex;
        position: absolute;
        top: 60%;
        align-items: center;
        justify-content: center;
        div {
          .el-input {
            width: calc(100vw * 216 / 1920);
            height: calc(100vw * 40 / 1920) !important;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 2px 0px 0px 2px;
            color: white !important;
            font-size: calc(100vw * 12 / 1920);
          }
          .el-button {
            height: calc(100vw * 40 / 1920) !important;
            width: calc(100vw * 110 / 1920);
            font-size: calc(100vw * 12 / 1920);
          }
        }
      }
    }
  }
  .ThreeDiv {
    height: calc(100vw * 350 / 1920);
    width: 100%;
    display: flex;
    // background: url("../assets/FooterMsg/底部菜单公众号.png");
    // background-size: 100% 100%;
    background: #061429;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .main {
      width: calc(100vw * 1200 / 1920);
      height: calc(100vw * 350 / 1920);
      display: flex;
      .left {
        width: calc(100vw * 400 / 1920);
        color: #ffffff;
        position: relative;
        img {
          position: absolute;
          top: 15%;
        }
        .d1 {
          position: absolute;
          top: 35%;
        }
        .d2 {
          // border: 1px solid rgba(255, 255, 255, 0.3);
          background: rgba(255, 255, 255, 0.3);
          width: calc(100vw * 350 / 1920);
          height: 1px;
          display: flex;
          position: absolute;
          top: 50%;
          // div:nth-child(1) {
          // }
          // div:nth-child(2) {
          //   border: 1px solid rgba(255, 255, 255, 0.3);
          //   width: calc(100vw * 380 / 1920);
          //   height: calc(100vw * 0.5 / 1920);
          // }
        }
        .p1 {
          position: absolute;
          top: 60%;
          font-size: calc(100vw * 16 / 1920);
        }
        .p2 {
          position: absolute;
          top: 75%;
          font-size: calc(100vw * 12 / 1920);
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .center {
        width: calc(100vw * 500 / 1920);
        color: #ffffff;
        padding-top: 8%;
        padding-left: 3%;
        .centerDiv {
          display: flex;
          margin-bottom: 4%;
          // justify-content: center;
          align-items: center;
          font-size: calc(100vw * 14 / 1920);
          img {
            width: calc(100vw * 20 / 1920);
            height: calc(100vw * 20 / 1920);
            margin-right: 2%;
          }
        }
      }
      .right {
        width: calc(100vw * 300 / 1920);
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          text-align: center;
        }
      }
    }
  }
  .FourDiv {
    height: calc(100vw * 64 / 1920);
    width: 100%;
    background: #020a16;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    .main {
      width: calc(100vw * 1200 / 1920);
      color: white;
      text-align: center;
      line-height: calc(100vw * 64 / 1920);
      font-size: calc(100vw * 12 / 1920);
      span {
        cursor: pointer;
      }
    }
  }
  .dialogDiv {
    position: fixed;
    width: calc(100vw * 350 / 1920);
    height: calc(100vw * 596 / 1920);
    z-index: 10;
    background: #020b18;
    border-radius: calc(100vw * 10 / 1920);
    right: 20%;
    bottom: 10%;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    .inputDiv {
      width: calc(100vw * 300 / 1920);
      height: calc(100vw * 500 / 1920);
      // background: beige;
      position: absolute;
      left: 7%;
      top: 3%;
      z-index: 5;
      .titleDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5%;
        div:nth-child(1) {
          width: calc(100vw * 50 / 1920);
          height: 0;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
        div:nth-child(2) {
          color: white;
          font-size: calc(100vw * 16 / 1920);
          margin-left: 5%;
          margin-right: 5%;
          user-select: none;
        }
        div:nth-child(3) {
          width: calc(100vw * 50 / 1920);
          height: 0;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
      .inputList {
        /deep/ .el-input {
          .el-input__inner {
            background: #00060f;
            border: 1px solid rgba(198, 198, 198, 0.2);
            color: white;
            width: 100%;
            height: calc(100vw * 30 / 1920);
            margin-bottom: 4%;
          }
          .el-textarea__inner {
            background: #00060f;
            border: 1px solid rgba(198, 198, 198, 0.2);
            color: white;
            width: 100%;
            height: calc(100vw * 30 / 1920);
            margin-bottom: 4%;
          }
        }
        .el-button {
          background: #006aff;
          width: 44%;
          height: calc(100vw * 30 / 1920);
          border: none;
        }
        /deep/ .el-radio__input {
          .el-radio__inner {
            width: calc(100vw * 12 / 1920);
            height: calc(100vw * 12 / 1920);
          }
        }
        .buttonDiv {
          display: flex;
          color: white !important;
          align-items: center;
          font-size: calc(100vw * 14 / 1920);
          margin-bottom: 4%;
          span {
            color: #006aff;
            cursor: pointer;
          }
          .checkbox {
            width: calc(100vw * 14 / 1920);
            height: calc(100vw * 14 / 1920);
            margin-right: 2%;
          }
        }
        .confirm {
          width: 100%;
          border: none;
          margin-bottom: 3%;
        }
        .close {
          display: block;
          width: 100%;
          text-align: center;
          color: rgb(204, 204, 204);
          cursor: pointer;
          font-size: calc(100vw * 14 / 1920);
        }
      }
    }
    .back {
      z-index: 1;
      position: absolute;
      bottom: 0;
      width: calc(100vw * 350 / 1920);
      height: calc(100vw * 226 / 1920);
      background: url("../assets/FooterMsg/dialog.png");
      background-size: 100% 100%;
    }
  }
}
</style>
