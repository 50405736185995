<template>
  <div class="liListDiv">
    <ul @mouseleave="handleMouseLeave">
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/广西.jpg'))
        "
      >
        <h3>广西</h3>
      </li>

      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/扬州.jpg'))
        "
      >
        <h3>扬州</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/山东.jpg'))
        "
      >
        <h3>日照</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/秦皇岛.jpg'))
        "
      >
        <h3>秦皇岛</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/曹妃甸.jpg'))
        "
      >
        <h3>曹妃甸</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/沧州.jpg'))
        "
      >
        <h3>沧州</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/锦州港.jpg'))
        "
      >
        <h3>锦州</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/珠海.jpg'))
        "
      >
        <h3>珠海</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/京唐.jpg'))
        "
      >
        <h3>京唐</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/天津.jpg'))
        "
      >
        <h3>天津</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/连云港.jpg'))
        "
      >
        <h3>连云港</h3>
      </li>
      <li
        @mouseenter="
          handleMouseEnter(require('../../assets/contacus/唐山.jpg'))
        "
      >
        <h3>唐山</h3>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      originalWidths: [], // 存储li元素的原始宽度
      defaultBackgroundImage: require("../../assets/contacus/秦皇岛.jpg"), // 默认的背景图
    };
  },
  mounted() {
    const liElements = document.querySelectorAll(".liListDiv ul li");
    liElements.forEach((li) => {
      this.originalWidths.push(li.offsetWidth); // 存储每个li元素的原始宽度
    });
  },
  methods: {
    handleMouseEnter(backgroundImage) {
      const liElements = document.querySelectorAll(".liListDiv ul li");
      const currentLi = event.target;
      const currentIndex = Array.from(liElements).indexOf(currentLi);
      const currentWidth = this.originalWidths[currentIndex];
      const newWidth = currentWidth * 3.5;
      liElements.forEach((li, index) => {
        if (index !== currentIndex) {
          const ratio = this.originalWidths[index] / currentWidth;
          li.style.width = `${this.originalWidths[index] / ratio}px`;
        } else {
          li.style.width = `${newWidth}px`;
        }
        li.style.transition = "width 0.3s ease-in-out"; // 为所有的 li 元素设置过渡效果
      });
      const ulElement = document.querySelector(".liListDiv ul");
      ulElement.style.backgroundImage = `url(${backgroundImage})`; // 先将背景图设置为新的图片
      ulElement.style.opacity = 0; // 先将 ul 元素的透明度设置为 0
      setTimeout(() => {
        ulElement.style.opacity = 1; // 等待一段时间后再将透明度设置为 1，实现过渡效果
      }, 100);
    },
    handleMouseLeave() {
      const liElements = document.querySelectorAll(".liListDiv ul li");
      liElements.forEach((li, index) => {
        li.style.width = `${this.originalWidths[index]}px`;
      });
      const ulElement = document.querySelector(".liListDiv ul");
      ulElement.style.backgroundImage = `url(${this.defaultBackgroundImage})`;
    },
  },
};
</script>

<style lang="less" scoped>
.liListDiv {
  width: 100%;
  height: calc(100vw * 564 / 1920);
  ul {
    width: 100%;
    height: 100%;
    display: flex;
    background-image: url("../../assets/contacus/秦皇岛.jpg"); // 默认的背景图
    background-size: 100% 100%;
    transition: background-image 1s ease-in-out, opacity 1s ease-in-out; /* 添加 transition 属性 */
    opacity: 1; /* 将初始的透明度设置为 1 */
    li {
      width: calc(100vw * 120 / 1920);
      height: 100%;
      border-right: calc(100vw * 1 / 1920) solid #ccc;
      background: rgba(229, 229, 229, 0.1);
      list-style: none;
      position: relative;
      h3 {
        font-size: calc(100vw * 16 / 1920);
        color: white;
        position: absolute;
        top: 45%;
        left: 25%;
      }
      h3:first-child {
        position: absolute;
        top: 45%;
        left: 20%;
      }
    }
    li:hover {
      background: rgba(35, 35, 35, 0.1);
    }
    li:last-child {
      border-right: none;
    }
    li {
      transition: width 0.3s ease-in-out; /* 添加 transition 属性 */
    }
  }
}
</style>
