<template>
  <!-- <div
    class="carousel"
    @mouseenter="pauseCarousel"
    @mouseleave="resumeCarousel"
  >
    <div class="ulDiv" ref="ulDiv">
      <div class="car" v-for="(item, index) in items" :key="index">
        <img :src="item.src" @mouseleave="getImg(item.alt)" :alt="item.alt" />
      </div>
      <div
        class="car"
        v-for="(item, index) in items.slice(0, 2)"
        :key="index + items.length"
      >
        <img :src="item.src" :alt="item.alt" />
      </div>
    </div>
  </div> -->
  <div class="main">
    <div class="imgDiv" v-for="(item, index) in listImg" :key="index">
      <img :src="item.src" alt="" />
      <div>{{ item.alt }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // items: [
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/河北省科学技术奖证书-2017.2.6.png"),
      //     alt: "河北省科学技术奖证书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/金匠奖（2020中国品牌榜）-2021.3.png"),
      //     alt: "金匠奖（2020中国品牌榜）",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/秦皇岛“大众创业、万众创新技能大赛”-科技型中小企业创新创业大赛成长企业组获奖证书-2020.12.jpg"),
      //     alt: "秦皇岛“大众创业、万众创新技能大赛”-科技型中小企业创新创业大赛成长企业组获奖证书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/秦皇岛创新创业大赛-2020.8.jpg"),
      //     alt: "秦皇岛创新创业大赛",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/山东装备专家库专家聘书-2020.12.jpg"),
      //     alt: "山东装备专家库专家聘书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知名商标.jpg"),
      //     alt: "知名商标",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权示范企业.jpg"),
      //     alt: "知识产权示范企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权优势企业.jpg"),
      //     alt: "知识产权优势企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中关村e谷之星-2019.1.jpg"),
      //     alt: "中关村e谷之星",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中国发明创业奖·创新奖证书-2010.10.jpg"),
      //     alt: "中国发明创业奖·创新奖证书-2010.10",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中国机械工业科学技术奖-2014.10.25.jpg"),
      //     alt: "中国机械工业科学技术奖-2014.10.25",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中国煤炭工业科学技术奖-2021.png"),
      //     alt: "中国煤炭工业科学技术奖-2021",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中国仪器仪表学会科学技术奖-2013.8.png"),
      //     alt: "中国仪器仪表学会科学技术奖-2013.8",
      //   },

      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/河北省科学技术成果证书（照片）-2011.6.29.png"),
      //     alt: "河北省科学技术成果证书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/河北省科学技术奖-2010.12.14.png"),
      //     alt: "河北省科学技术奖",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/河北省科学技术奖-2011.png"),
      //     alt: "河北省科学技术奖",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/河北省创新创业大赛-2021.2.png"),
      //     alt: "河北省创新创业大赛",
      //   },
      // ],
      // intervalId: null,
      // items: [
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/山东装备专家库专家聘书-2020.12.jpg"),
      //     alt: "山东装备专家库专家聘书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知名商标.jpg"),
      //     alt: "知名商标",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权示范企业.jpg"),
      //     alt: "知识产权示范企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权优势企业.jpg"),
      //     alt: "知识产权优势企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中关村e谷之星-2019.1.jpg"),
      //     alt: "中关村e谷之星",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/山东装备专家库专家聘书-2020.12.jpg"),
      //     alt: "山东装备专家库专家聘书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知名商标.jpg"),
      //     alt: "知名商标",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权示范企业.jpg"),
      //     alt: "知识产权示范企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权优势企业.jpg"),
      //     alt: "知识产权优势企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中关村e谷之星-2019.1.jpg"),
      //     alt: "中关村e谷之星",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/山东装备专家库专家聘书-2020.12.jpg"),
      //     alt: "山东装备专家库专家聘书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知名商标.jpg"),
      //     alt: "知名商标",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权示范企业.jpg"),
      //     alt: "知识产权示范企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知识产权优势企业.jpg"),
      //     alt: "知识产权优势企业",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/中关村e谷之星-2019.1.jpg"),
      //     alt: "中关村e谷之星",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/山东装备专家库专家聘书-2020.12.jpg"),
      //     alt: "山东装备专家库专家聘书",
      //   },
      //   {
      //     src: require("@/assets/Qualificationcertification/荣誉/知名商标.jpg"),
      //     alt: "知名商标",
      //   },
      // ],
      // speed: 1, // 每秒移动的像素数
      listImg: [
        {
          src: require("@/assets/Qualificationcertification/荣誉/知名商标.jpg"),
          alt: "知名商标",
        },
        {
          src: require("@/assets/Qualificationcertification/荣誉/知识产权示范企业.jpg"),
          alt: "知识产权示范企业",
        },
        {
          src: require("@/assets/Qualificationcertification/荣誉/知识产权优势企业.jpg"),
          alt: "知识产权优势企业",
        },
      ],
    };
  },
  mounted() {
    // let ulDiv = document.querySelector(".ulDiv");
    // let w = this.items.length;
    // ulDiv.style.width = `calc(100vw * ${w} / 1920)`;
    // this.intervalId = setInterval(this.moveCarousel, 16);
  },
  // beforeDestroy() {
  //   clearInterval(this.intervalId);
  // },
  methods: {
    // moveCarousel() {
    //   const ulDiv = this.$refs.ulDiv;
    //   let left = parseInt(getComputedStyle(ulDiv).left) || 0;
    //   left -= this.speed;
    //   if (left < -3900) {
    //     left = 0;
    //   }
    //   ulDiv.style.left = `${left}px`;
    //   if (left === 0) {
    //     clearInterval(this.intervalId);
    //     setTimeout(() => {
    //       this.intervalId = setInterval(this.moveCarousel, 16);
    //     }, 2000);
    //   }
    // },
    // pauseCarousel() {
    //   clearInterval(this.intervalId);
    // },
    // resumeCarousel() {
    //   this.intervalId = setInterval(this.moveCarousel, 16);
    // },
    // getImg(parse) {
    // },
  },
};
</script>

<style lang="less" scoped>
.main {
  margin-top: 2%;
  width: calc(100vw * 1200 / 1920);
  display: flex;
  .imgDiv {
    width: calc(100vw * 380 / 1920);
    height: calc(100vw * 317 / 1920);
    margin-right: 2.5%;
    margin-bottom: 2%;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);
    img {
      width: calc(100vw * 380 / 1920);
      height: calc(100vw * 268 / 1920);
    }
    div {
      width: calc(100vw * 380 / 1920);
      height: calc(100vw * 49 / 1920);
      text-align: center;
      line-height: calc(100vw * 40 / 1920);
      font-weight: 700;
      color: #000000;
      // box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);
    }
  }
}
.carousel {
  width: calc(100vw * 1200 / 1920);
  overflow: hidden;
  .ulDiv {
    width: calc(100vw * 6800 / 1920);
    // height: calc(100vw * 100 / 1920);
    overflow: hidden;
    display: flex;
    position: relative;
    left: 0;
    .car {
      width: calc(100vw * 400 / 1920);
      height: calc(100vw * 317 / 1920);
      z-index: 1;
      cursor: pointer;
      img {
        width: calc(100vw * 380 / 1920);
        height: calc(100vw * 317 / 1920);
      }
    }
  }
}
</style>
