<template>
  <div class="image-gallery">
    <div class="bottonDiv">
      <img
        @click="upnext"
        style="cursor: pointer"
        src="../../assets/productCenterMsg/jingdiananli/上一张.png"
        alt=""
      />
      <div class="thumbnail">
        <img
          @click="upnext"
          style="margin-bottom: 5%; cursor: pointer"
          :src="img1.url"
          alt=""
        />
        <img
          style="
            margin-bottom: 5%;
            border: calc(100vw * 3 / 1920) solid rgb(0, 255, 106);
          "
          :src="img2.url"
          alt=""
        />
        <img @click="next" style="cursor: pointer" :src="img3.url" alt="" />
      </div>
      <img
        @click="next"
        style="cursor: pointer"
        src="../../assets/productCenterMsg/jingdiananli/下一张.png"
        alt=""
      />
    </div>

    <div class="image-container">
      <img :src="images[currentIndex].url" alt="" />
    </div>
    <div class="rightDiv">
      <h3>{{ images[currentIndex].title }}</h3>
      <div class="hrDiv">
        <div class="h"></div>
        <div class="w"></div>
      </div>
      <p v-for="(item, index) in images[currentIndex].text" :key="index">
        ● {{ item }}
      </p>

      <!-- <div
        v-for="(item, index) in currentImage.items"
        :key="index"
        class="nameDiv"
      >
        <div>●</div>
        <div>{{ item }}</div>
      </div> -->
      <!-- <div class="hrDiv">
        <div class="h"></div>
        <div class="w"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          text: [
            "装车机全自动控制研发",
            "计算机视觉技术实现皮带防撕裂自动检测项目",
          ],
          title: "山东日照港",
          // items: ["堆取料机", "装船机"],
          url: require("@/assets/productCenterMsg/jingdiananli/山东日照港.png"),
        },
        {
          text: [
            "黄骅港智能翻车机关键技术研究",
            "黄骅港翻车机智能给料技术研究",
            "神华黄骅港二期堆场建模及单机无人化",
            "黄骅港装船机自动化推广",
            "黄骅港装船机智能视频预警及控制系统技术开发",
            "黄骅港翻堆集控智慧操作屏应用研究项目",
            "黄骅港煤炭港口智能皮带机系统技术研究项目",
          ],
          title: "黄骅港",
          url: require("@/assets/productCenterMsg/jingdiananli/黄骅港.png"),
        },
        {
          text: [
            "翻车机与堆料机变频系统升级改造项目",
            "国投曹妃甸港起步翻车机漏斗料位计改造",
            "国投曹妃甸港起步翻车机机上电气与控制系统",
            "国投曹妃甸港S4、S5堆料机变频驱动系统升级改造",
            "国投曹妃甸港CD2翻车机变频器改造",
            "取料机无人化作业系统",
            "单机全天候抑尘项目",
            "装船机智能化改造专用部分",
            "装船机智能控制系统研发及应用",
            "车厢反位动态识别器",
            "火车车钩反位动态监测预警系统",
            "BF1皮带基于视觉技术皮带纵向撕裂检测系统",
          ],
          title: "曹妃甸港",
          url: require("@/assets/productCenterMsg/jingdiananli/曹妃甸港.png"),
        },
        {
          text: [
            "翻车机控制系统优化提升项目",
            "堆取料机自动化",
            "堆取料机自动化",
            "堆取料机自动化改造项目二期",
            "堆取料机自动化改造项目",
          ],
          title: "唐山港",
          url: require("@/assets/productCenterMsg/jingdiananli/唐山港.png"),
        },
        {
          text: ["智慧港口"],
          title: "扬州泰富港",
          url: require("@/assets/productCenterMsg/jingdiananli/扬州泰富港.png"),
        },
        {
          text: [
            "翻车机智能化提升",
            "一公司码头工程控制系统建设",
            "CD6翻车机智能化均衡给料设计与研发",
            "基于光栅和图像辨识技术的列车反位识别技术",
            "基于港口物联网消防远程监控系统",
            "储罐区智能巡检技术研究与应用",
          ],
          title: "秦皇岛港",
          url: require("@/assets/productCenterMsg/jingdiananli/秦皇岛港.png"),
        },
      ],
      currentIndex: 0,
      img1: "",
      img2: "",
      img3: "",
    };
  },
  mounted() {
    this.updateVisibleImages();
  },
  methods: {
    upnext() {
      if (this.currentIndex == 0) {
        this.currentIndex = this.images.length - 1;
      } else {
        this.currentIndex--;
      }
      this.upImg();
    },
    next() {
      this.currentIndex++;
      if (this.currentIndex == this.images.length) {
        this.currentIndex = 0;
      }
      this.upImg();
    },
    upImg() {
      if (this.currentIndex == this.images.length - 1) {
        this.img1 = this.images[this.images.length - 2];
        this.img2 = this.images[this.images.length - 1];
        this.img3 = this.images[0];
      } else if (this.currentIndex == 0) {
        this.img1 = this.images[this.images.length - 1];
        this.img2 = this.images[this.currentIndex];
        this.img3 = this.images[this.currentIndex + 1];
      } else {
        this.img1 = this.images[this.currentIndex - 1];
        this.img2 = this.images[this.currentIndex];
        this.img3 = this.images[this.currentIndex + 1];
      }
    },
  },
  mounted() {
    this.upImg();
  },
};
</script>

<style lang="less" scoped>
.image-gallery {
  display: flex;
  .bottonDiv {
    width: calc(100vw * 202 / 1920) !important;
    height: calc(100vw * 440 / 1920) !important;
    background: rgba(0, 6, 15, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    box-sizing: border-box; /* 添加这一行代码 */
  }
}

.image-container {
  width: calc(100vw * 760 / 1920);
  height: calc(100vw * 440 / 1920);
  overflow: hidden;
  img {
    width: calc(100vw * 760 / 1920) !important;
    height: calc(100vw * 440 / 1920) !important;
  }
}
.rightDiv {
  width: calc(100vw * 310 / 1920);
  height: calc(100vw * 440 / 1920);
  background: rgba(0, 6, 15, 0.4);
  color: white;
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
  box-sizing: border-box;
  h3 {
    font-size: calc(100vw * 20 / 1920);
    margin-bottom: 5%;
  }
  p {
    font-size: calc(100vw * 14 / 1920);
    font-weight: 400;
    line-height: calc(100vw * 23 / 1920);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // margin-bottom: 10%;
  }
  .hrDiv {
    display: flex;
    align-items: center;
    height: calc(100vw * 3 / 1920);
    margin-bottom: 5%;
    .h {
      width: calc(100vw * 27 / 1920);
      height: 0px;
      border: calc(100vw * 2 / 1920) solid #ffffff;
      border-radius: calc(100vw * 3 / 1920);
    }
    .w {
      width: calc(100vw * 247 / 1920);
      height: 0px;
      border: calc(100vw * 1 / 1920) solid rgba(255, 255, 255, 0.3);
    }
  }
  .nameDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10%;
    div:nth-child(1) {
      margin-right: 10%;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.button-container button {
  margin: 5px;
}

.thumbnail {
  width: calc(100vw * 194 / 1920) !important;
  //   height: calc(100vw * 122 / 1920) !important;
  margin-bottom: 7%;
  margin-top: 7%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: calc(100vw * 154 / 1920) !important;
    height: calc(100vw * 112 / 1920) !important;
  }
}
</style>
