<template>
  <div>
    <Harder></Harder>
    <Swiper />
  </div>
</template>

<script>
import Swiper from "./swiper/Swiper.vue";
import Harder from "./Harder.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    Swiper,
    Harder,
  },
  computed: {
    ...mapState(["routerPush"]),
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "hidden"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "hidden"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    routerPush(n, o) {
      if (n) {
        window.scrollTo(0, 0);
        this.$store.state.routerPush = false;
      }
    },
  },
};
</script>

<style scoped>
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden !important;
}
</style>
