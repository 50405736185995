<template>
  <div class="fullscreen">
    <video ref="video" autoplay loop muted>
      <source src="../assets/index.mp4" type="video/mp4" />
    </video>
    <div class="content">
      <h1
        style="
          font-size: calc(100vw * 50 / 1920);
          color: white;
          user-select: none;
        "
      >
        滨沅国科（秦皇岛）智能科技股份有限公司
      </h1>
      <el-button v-if="buttonBool" class="elButton" @click="setVideo"
        >开始探索</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttonBool: true,
    };
  },
  mounted() {
    // 获取当前时间戳
    const currentTime = new Date().getTime();
    // 设置有效期，这里设置为一天（单位：毫秒）
    const expirationTime = currentTime + 24 * 60 * 60 * 1000;

    // 将过期时间存储到 localStorage 中
    localStorage.setItem("indexExpirationTime", expirationTime);

    const video = this.$refs.video;

    // 设置初始高度为窗口高度
    this.$el.style.height = window.innerHeight + "px";

    // 监听窗口大小变化事件，更新高度
    window.addEventListener("resize", () => {
      this.$el.style.height = window.innerHeight + "px";
      video.style.height = window.innerHeight + "px";
    });

    // 监听视频播放位置更改事件
    video.addEventListener("timeupdate", () => {
      if (video.duration - video.currentTime < 1 && this.setVideoNum == 2) {
        video.pause();
        this.$router.push(`/home`);
      }
      // 如果离视频结尾不足1秒，则暂停视频
      if (video.duration - video.currentTime < 1) {
        video.pause();
      }
    });
  },
  methods: {
    setVideo() {
      this.buttonBool = false;
      this.setVideoNum = 2;
      // 获取 video 元素并设置其高度为窗口高度
      const video = this.$refs.video;
      video.style.height = window.innerHeight + "px";

      // 监听窗口大小变化事件，以便在窗口大小变化时更新 video 元素的高度
      window.addEventListener("resize", () => {
        video.style.height = window.innerHeight + "px";
      });

      // 设置视频路径
      video.src = require("@/assets/index2.mp4");
    },
  },
};
</script>

<style>
.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fullscreen video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.content {
  text-align: center;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.elButton {
  width: 12%;
  height: 6%;
  position: absolute;
  top: 65%;
  left: 43%;
  font-size: calc(100vw * 25 / 1920) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: white !important;
}
.elButton:hover {
  /* font-weight: 800 !important; */
  transform: scale(1.1);
  /* font-size: 48px !important; */
  transition: transform 0.3s ease-in-out;
}
</style>
