<template>
  <div class="productCenterIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <el-carousel
          trigger="click"
          :interval="swiperTime"
          class="swiper"
          :height="imgHeight"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topDivimg">
        <div class="topDiv">
          <header>
            <div>
              <h3>{{ infoTypeStr }}</h3>
              <h4>&nbsp;&nbsp;&nbsp;NEWS</h4>
            </div>
            <div style="font-size: calc(100vw * 16 / 1920)">
              <span @click="$router.push('/newsIndex')">新闻资讯</span>
              <p>>行业资讯列表</p>
            </div>
          </header>
          <div class="listDiv" v-for="(item, index) in rightList" :key="index">
            <div class="left">
              <div class="numDiv">{{ item.month }}</div>
              <div class="dateDiv">
                <p>{{ item.infoDate }}</p>
                <p>{{ item.wk }}</p>
              </div>
              <img
                style="cursor: pointer"
                @click="$router.push(`/indetail?id=${item.infoId}`)"
                :src="'gw/prod-api' + item.infoImg"
                alt=""
              />
            </div>
            <div class="right">
              <h3
                style="cursor: pointer"
                @click="$router.push(`/indetail?id=${item.infoId}`)"
              >
                {{ item.infoTitle }}
              </h3>
              <p
                style="cursor: pointer"
                @click="$router.push(`/indetail?id=${item.infoId}`)"
              >
                {{ item.infoOutLine }}
              </p>
              <img
                @click="$router.push(`/indetail?id=${item.infoId}`)"
                src="../../assets/news/更多.png"
                alt=""
              />
            </div>
          </div>
          <el-pagination
            style="margin-bottom: 5%"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="dataLength"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";
export default {
  name: "newsIndex",
  components: {
    Harder,
    Footer,
  },
  data() {
    return {
      bannerList: [
        {
          url: require("@/assets/news/新闻中心banner1.png"),
          link: "",
        },
        {
          url: require("@/assets/news/新闻中心banner2.png"),
          link: "",
        },
        {
          url: require("@/assets/news/新闻中心banner3.png"),
          link: "",
        },
      ],
      swiperTime: 7000,
      infoTypeStr: "",
      rightList: [],
      pageNum: 1,
      pageSize: 10,
      dataLength: 0,
      imgHeight: "calc(100vw * 800 / 1920)",
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    this.list();
    if (sessionStorage.getItem("infoType") == 1) {
      this.infoTypeStr = "行业新闻";
    }
    if (sessionStorage.getItem("infoType") == 2) {
      this.infoTypeStr = "公司新闻";
    }
  },
  methods: {
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.pageNum = val;
      this.list();
    },
    async list() {
      let result = await this.$store.dispatch("list", {
        infoType: Number(sessionStorage.getItem("infoType")),
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (result.data.code == 200) {
        this.rightList = result.data.rows;
        this.dataLength = result.data.total;
      } else {
        this.rightList = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    margin-right: 40px;
    margin-bottom: 10%;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

// 修改滚动条样式
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 20px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  // box-shadow: inset 0 0 5px rgba(0, 123, 255, 0.2);
  background: rgb(208, 208, 208);
  height: 40px !important;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgb(239, 239, 239);
}

.productCenterIndexDiv {
  width: 100%;
  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .swiperDiv {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .topDivimg {
      width: 100%;
      //   height: calc(100vw * 200 / 1920);
      display: flex;
      flex-direction: column;
      align-items: center;
      .topDiv {
        width: calc(100vw * 1200 / 1920);
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 2%;
        header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #aaaaaa;
          height: calc(100vw * 70 / 1920);
          margin-top: 3%;
          margin-bottom: 5%;
          div {
            display: flex;
            align-items: center;
            h3 {
              font-size: calc(100vw * 24 / 1920);
              color: #000000;
              //   margin-right: 1%;
            }
            h4 {
              font-size: calc(100vw * 24 / 1920);
              color: #dfdfdf;
            }
            span {
              text-decoration: none;
              color: #063e8d;
              cursor: pointer;
            }
            p {
              color: #666666;
            }
          }
        }
        .listDiv {
          display: flex;
          width: 100%;
          border-bottom: 1px solid #e7e7e7;
          padding-bottom: 2%;
          margin-bottom: 2%;
          .left {
            display: flex;
            width: 50%;
            .numDiv {
              color: #006aff;
              margin-right: 6%;
              width: calc(100vw * 100 / 1920);
              height: calc(100vw * 48 / 1920);
              font-size: calc(100vw * 48 / 1920);
              font-weight: 700;
              line-height: calc(100vw * 48 / 1920);
              border-right: 1px solid rgba(0, 0, 0, 0.2);
            }
            .dateDiv {
              width: calc(100vw * 100 / 1920);
              height: calc(100vw * 58 / 1920);
              margin-right: 6%;
              margin-left: 2%;
              p:nth-child(1) {
                font-size: calc(100vw * 14 / 1920);
                color: #333333;
              }
              p:nth-child(2) {
                font-size: calc(100vw * 12 / 1920);
                color: #aaaaaa;
              }
            }
            img {
              width: calc(100vw * 273 / 1920);
              height: calc(100vw * 160 / 1920);
            }
          }
          .right {
            width: 50%;
            position: relative;
            h3 {
              font-size: calc(100vw * 16 / 1920);
              color: #000000;
              font-weight: 700;
              margin-bottom: 3%;
            }
            p {
              font-size: calc(100vw * 14 / 1920);
              color: #333333;
              //   margin-bottom: 5%;
            }
            img {
              width: calc(100vw * 20 / 1920);
              height: calc(100vw * 6 / 1920);
              position: absolute;
              bottom: 5%;
              cursor: pointer;
            }
          }
        }
        // .el-pagination {
        //   max-width: 100% !important;
        // }
      }
    }
  }
}
</style>
