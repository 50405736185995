<template>
  <div class="aboutIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <el-carousel
          trigger="click"
          :interval="swiperTime"
          class="swiper"
          :height="imgHeight"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="tabsDiv">
        <div class="tabs">
          <div class="tabsIndex qiyejieshao" @click="activeDiv('企业介绍')">
            <img src="../../assets/aboutMsg/qiyejieshao.png" alt="" />
            <span>企业介绍</span>
          </div>
          <div class="tabsIndex zizhirenzheng" @click="activeDiv('资质认证')">
            <img src="../../assets/aboutMsg/zizhirenzheng.png" alt="" />
            <span>资质认证</span>
          </div>
          <div class="tabsIndex zhishichanquan" @click="activeDiv('知识产权')">
            <img src="../../assets/aboutMsg/zhishichanquan.png" alt="" />
            <span>知识产权</span>
          </div>
        </div>
      </div>
      <div class="contener">
        <EnterpriseIntroduction v-show="$store.state.indexPage == 1" />
        <Qualificationcertification v-show="$store.state.indexPage == 2" />
        <Intellectualproperty v-show="$store.state.indexPage == 3" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";
import EnterpriseIntroduction from "./EnterpriseIntroduction.vue";
import Qualificationcertification from "./Qualificationcertification.vue";
import Intellectualproperty from "./Intellectualproperty.vue";
export default {
  name: "aboutIndex",
  metaInfo: {
    // 设置关键词
    meta: {
      keywords: "关于滨沅,企业介绍,资质认证,知识产权",
    },
  },
  components: {
    Harder,
    Footer,
    EnterpriseIntroduction,
    Qualificationcertification,
    Intellectualproperty,
  },
  data() {
    return {
      indexPage: 1,
      bannerList: [
        {
          url: require("@/assets/aboutMsg/关于我们banner1.png"),
          link: "",
        },
        {
          url: require("@/assets/aboutMsg/关于我们banner2.png"),
          link: "",
        },
        {
          url: require("@/assets/aboutMsg/关于我们banner3.png"),
          link: "",
        },
      ],
      swiperTime: 7000,
      scrollHeight: 0,
      imgHeight: "calc(100vw * 800 / 1920)",
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    if (this.$store.state.indexPage == 1) {
      let q = document.querySelector(".qiyejieshao");
      q.style.color = "#006aff";
      q.style.background = "rgba(217, 217, 217, 0.1)";
      let two = document.querySelector(".zizhirenzheng");
      two.style.color = "white";
      two.style.background = "transparent";
      let three = document.querySelector(".zhishichanquan");
      three.style.color = "white";
      three.style.background = "transparent";
      this.$store.state.indexPage = 1;
    }
    if (this.$store.state.indexPage == 2) {
      let z = document.querySelector(".zizhirenzheng");
      z.style.color = "#006aff";
      z.style.background = "rgba(217, 217, 217, 0.1)";
      let one = document.querySelector(".qiyejieshao");
      one.style.color = "white";
      one.style.background = "transparent";
      let three = document.querySelector(".zhishichanquan");
      three.style.color = "white";
      three.style.background = "transparent";
      this.$store.state.indexPage = 2;
    }
    if (this.$store.state.indexPage == 3) {
      let three = document.querySelector(".zhishichanquan");
      three.style.color = "#006aff";
      three.style.background = "rgba(217, 217, 217, 0.1)";
      let one = document.querySelector(".qiyejieshao");
      one.style.color = "white";
      one.style.background = "transparent";
      let two = document.querySelector(".zizhirenzheng");
      two.style.color = "white";
      two.style.background = "transparent";
      this.$store.state.indexPage = 3;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollHeight = window.scrollY;
    },
    activeDiv(str) {
      if (str == "企业介绍") {
        let q = document.querySelector(".qiyejieshao");
        q.style.color = "#006aff";
        q.style.background = "rgba(217, 217, 217, 0.1)";
        let two = document.querySelector(".zizhirenzheng");
        two.style.color = "white";
        two.style.background = "transparent";
        let three = document.querySelector(".zhishichanquan");
        three.style.color = "white";
        three.style.background = "transparent";
        this.$store.state.indexPage = 1;
      }
      if (str == "资质认证") {
        let z = document.querySelector(".zizhirenzheng");
        z.style.color = "#006aff";
        z.style.background = "rgba(217, 217, 217, 0.1)";
        let one = document.querySelector(".qiyejieshao");
        one.style.color = "white";
        one.style.background = "transparent";
        let three = document.querySelector(".zhishichanquan");
        three.style.color = "white";
        three.style.background = "transparent";
        this.$store.state.indexPage = 2;
      }
      if (str == "知识产权") {
        let three = document.querySelector(".zhishichanquan");
        three.style.color = "#006aff";
        three.style.background = "rgba(217, 217, 217, 0.1)";
        let one = document.querySelector(".qiyejieshao");
        one.style.color = "white";
        one.style.background = "transparent";
        let two = document.querySelector(".zizhirenzheng");
        two.style.color = "white";
        two.style.background = "transparent";
        this.$store.state.indexPage = 3;
      }
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    margin-right: 40px;
    margin-bottom: 10%;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

.aboutIndexDiv {
  width: 100%;
  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    .swiperDiv {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tabsDiv {
      height: calc(100vw * 100 / 1920);
      width: 100%;
      background: #00060f;
      display: flex;
      justify-content: center;
      align-items: center;
      .tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        .tabsIndex {
          width: calc(100vw * 280 / 1920);
          height: calc(100vw * 100 / 1920);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: calc(100vw * 44 / 1920);
            height: calc(100vw * 44 / 1920);
            margin-right: 5%;
          }
          span {
            font-size: calc(100vw * 16 / 1920);
          }
        }
        .tabsIndex:hover {
          background: rgba(217, 217, 217, 0.1) !important;
        }
      }
    }
    .contener {
      // width: calc(100vw * 1200 / 1920);
      width: 100% !important;
    }
  }
}
</style>
