<template>
  <div id="contener">
    <div class="backWhite">
      <div class="contenerDiv">
        <header>
          <div>QUALIFICATION</div>
          <div>资质认证</div>
          <div></div>
          <div style="line-height: calc(100vw * 26 / 1920)">
            “高新技术企业”、河北省“专精特新”企业，具备质量、环境、职业健康三大体系企业认证证书及机电总承包和电子智能化等行业权威资质，先后获得行业荣誉奖10+...
          </div>
        </header>
        <div class="dataDiv">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>资质证书</h3>
                <div></div>
              </div>
              <div class="d2">ABOUT US</div>
              <div class="d3" v-for="(item, index) in leftList" :key="index">
                {{ item.text }}
              </div>
            </div>
            <div class="dataDivRight">
              <div class="d3" v-for="(item, index) in rightList" :key="index">
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="bottomDiv">
            <!-- <div
              class="dialogImg"
              v-show="$store.state.dialogImgBool"
              @mouseenter="$store.state.dialogImgBool = true"
              @mouseleave="$store.state.dialogImgBool = false"
            >
              <img :src="$store.state.dialogImgurl" alt="" />
            </div> -->
            <!-- <Zzzs /> -->
            <div class="zzzsBack"></div>
          </div>
        </div>
      </div>
      <!-- <div class="backgroundDiv"></div> -->
      <div class="backWhite">
        <div class="contenerDiv">
          <div class="dataDiv2">
            <div class="topDiv">
              <div class="dataDivLeft">
                <div class="d1">
                  <h3>荣誉证书</h3>
                  <div></div>
                </div>
                <div class="d2">ABOUT US</div>
              </div>
            </div>
            <div class="center">
              <el-tabs v-model="activeName" :default-active="activeName">
                <el-tab-pane label="行业认可" name="first">
                  <Ryzs />
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
// import "swiper/swiper-bundle.css";
import Zzzs from "./zzzs.vue";
import Ryzs from "./ryzs.vue";
import { mapState } from "vuex";

export default {
  name: "EnterpriseIntroduction",
  components: {
    // swiper,
    // swiperSlide,
    Zzzs,
    Ryzs,
  },
  data() {
    return {
      leftList: [
        {
          text: "· 3A资信等级证书",
        },
        {
          text: "· 环境管理体系认证证书",
        },
        {
          text: "· 中国港口协会科学技术奖",
        },
        {
          text: "· 职业健康安全管理体系证书",
        },
        {
          text: "· 中国企业社会责任优秀案例",
        },
        {
          text: "· 河北省工业企业研发机构证书（C级）-散货港口智能控制研发中心",
        },
        {
          text: "· 星级合作伙伴荣誉证书",
        },
        {
          text: "· 知识产权管理体系认证证书",
        },
        {
          text: "· 科学技术成果评价证书",
        },
      ],
      rightList: [
        {
          text: "· 安全生产许可证",
        },
        {
          text: "· 河北省工业企业研发机构证书",
        },
        {
          text: "· 建筑业企业资质证书（机电工程施工总承包三级）",
        },
        {
          text: "· 建筑业企业资质证书（电子与智能化工程专业承包贰级）",
        },
        {
          text: "· 质量管理体系认证证书",
        },
        {
          text: "· 2022年河北省“专精特新”企业称号",
        },
      ],
      nextImg: 5000,
      activeName: "",
    };
  },
  computed: {
    ...mapState(["dialogImgBool"]),
  },
  mounted() {
    this.activeName = "first";
  },
  methods: {},
  watch: {
    dialogImgBool(n, o) {
      if (n) {
        // let diaLog = document.querySelector(".dialogImg");
        // diaLog.style.background = `url("${this.$store.state.dialogImgurl}")`;
        // diaLog.style = "background-size: 100% 100%;";
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-carousel__indicators {
  // 指示灯位置
  // left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    // margin-right: 40px;
    // margin-bottom: 10%;
    display: none;
  }
}

#contener {
  width: 100%;
  // height: calc(100vw * 950 / 1920);
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  // overflow: auto;
  .backWhite {
    width: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    z-index: 1;
    position: relative;
    .contenerDiv {
      width: calc(100vw * 1200 / 1920);
      header {
        width: 100% !important;
        height: calc(100vw * 186 / 1920);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 5%;
        div:nth-child(1) {
          font-size: calc(100vw * 64 / 1920);
          color: #eff1f4;
          position: absolute;
          top: 20%;
        }
        div:nth-child(2) {
          font-size: calc(100vw * 30 / 1920);
          font-weight: 700;
          position: absolute;
          top: 40%;
          // color: #eff1f4;
        }
        div:nth-child(3) {
          width: 3%;
          height: 8px;
          position: absolute;
          top: 75%;
          background: #d91724;
        }
        div:nth-child(4) {
          position: absolute;
          font-size: calc(100vw * 16 / 1920);
          width: 70%;
          top: 90%;
          text-align: center;
        }
      }
      .dataDiv {
        width: 100%;
        // height: calc(100vw * 1000 / 1920);
        .topDiv {
          display: flex;
          height: calc(100vw * 500 / 1920);
          .dataDivLeft {
            width: 47%;
            height: 100%;
            // background: #c7e49f;
            .d1 {
              display: flex;
              align-items: center;
              font-size: calc(100vw * 20 / 1920);
              div {
                width: 15%;
                // margin-left: 2%;
                border: 1px solid rgba(128, 142, 162, 0.1);
              }
            }
            .d2 {
              font-size: calc(100vw * 14 / 1920);
              color: rgba(6, 62, 141, 0.1);
              margin-top: 1%;
              margin-bottom: 3%;
            }
            .d3 {
              width: 95%;
              // letter-spacing: 5px;
              // margin-top: 3%;
              height: 5%;
              font-size: calc(100vw * 14 / 1920);
              // text-indent: 2em; /* 添加两个空格 */
              word-wrap: break-word; /* 自动换行 */
              overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .dataDivRight {
            width: 53%;
            height: 100%;
            // background: #d0d0d0;
            position: relative;
            padding-top: 6%;
            .d3 {
              width: 95%;
              // letter-spacing: 5px;
              // margin-top: 3%;
              height: 5%;
              font-size: calc(100vw * 14 / 1920);
              // text-indent: 2em; /* 添加两个空格 */
              word-wrap: break-word; /* 自动换行 */
              overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .bottomDiv {
          width: 100%;
          // height: calc(100vw * 100 / 1920);
          display: flex;
          align-items: center;
          position: relative;
          margin-top: -10%;
          // .dialogImg {
          //   width: calc(100vw * 585 / 1920);
          //   height: calc(100vw * 690 / 1920);
          //   background: beige;
          //   position: absolute;
          //   top: -600%;
          //   left: 30%;
          //   z-index: 3;
          //   img {
          //     width: 100%;
          //     height: 100%;
          //   }
          // }
          .zzzsBack {
            width: 100%;
            height: calc(100vw * 1543 / 1920);
            background: url("../../assets/Qualificationcertification/资质/资质图.png");
            background-size: 100% 100%;
            margin-bottom: 7%;
          }
        }
      }
    }
    // .backgroundDiv {
    //   width: 100%;
    //   height: calc(100vw * 240 / 1920);
    //   background: url("../../assets/Qualificationcertification/背景.png");
    //   background-size: 100% 100%;
    //   position: absolute;
    //   top: 45%;
    // }
    .dataDiv2 {
      width: 100%;
      height: calc(100vw * 650 / 1920);
      .topDiv {
        display: flex;
        height: calc(100vw * 110 / 1920);
        .dataDivLeft {
          width: 47%;
          height: 100%;
          // background: #c7e49f;
          .d1 {
            display: flex;
            align-items: center;
            font-size: calc(100vw * 20 / 1920);
            div {
              width: 15%;
              // margin-left: 2%;
              border: 1px solid rgba(128, 142, 162, 0.1);
            }
          }
          .d2 {
            font-size: calc(100vw * 14 / 1920);
            color: rgba(6, 62, 141, 0.1);
            margin-top: 1%;
            margin-bottom: 3%;
          }
          .d3 {
            width: 95%;
            // letter-spacing: 5px;
            // margin-top: 3%;
            height: 5%;
            font-size: calc(100vw * 14 / 1920);
            // text-indent: 2em; /* 添加两个空格 */
            word-wrap: break-word; /* 自动换行 */
            overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .dataDivRight {
          width: 53%;
          height: 100%;
          // background: #d0d0d0;
          position: relative;
          padding-top: 6%;
          .d3 {
            width: 95%;
            // letter-spacing: 5px;
            // margin-top: 3%;
            height: 5%;
            font-size: calc(100vw * 14 / 1920);
            // text-indent: 2em; /* 添加两个空格 */
            word-wrap: break-word; /* 自动换行 */
            overflow-wrap: break-word; /* 兼容性更好的自动换行属性 */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .center {
        /deep/ .el-tabs__header {
          .el-tabs__nav-wrap {
            .el-tabs__nav-scroll {
              display: flex;
              justify-content: center;
            }
            .el-tabs__item {
              width: calc(100vw * 150 / 1920);
              text-align: center;
              font-size: calc(100vw * 16 / 1920);
              color: #000000;
              font-weight: 600;
              margin-bottom: 2%;
              height: calc(100vw * 30 / 1920);
            }
            .el-tabs__active-bar {
              margin-top: 2%;
              background: #d91724;
              height: calc(100vw * 2 / 1920);
            }
          }
        }
      }
    }
  }
}
</style>
