<template>
  <div class="main">
    <!-- <div class="top">
      <div>
        <span style="color: #006aff; cursor: pointer" @click="getAnyList('')"
          >更多</span
        >
      </div>
    </div> -->
    <div class="bottom" v-loading="loading">
      <div class="d1" v-for="(item, index) in list" :key="index">
        <img @click="goPush(item.productId)" :src="item.productImg" alt="" />
        <div
          @click="goPush(item.productId)"
          style="font-size: calc(100vw * 16 / 1920)"
        >
          {{ item.productTitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "anquan",
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  mounted() {
    this.list = this.onelist;
    this.getAnyList();
  },
  methods: {
    async getAnyList(n) {
      this.loading = true;
      let result = await this.$store.dispatch("typeproduct", {
        productType: 2,
        rows: n,
      });
      if (result.data.code == 200) {
        result.data.data.forEach((item) => {
          item.productImg = "gw/prod-api" + item.productImg;
        });
        this.list = result.data.data;
        this.loading = false;
      }
    },
    goPush(id) {
      this.$router.push(`/sbdata?type=${2}&id=${id}`, () => {
        window.location.reload();
      });
      // location.href = `/sbdata?type=${2}&id=${id}`;
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  // height: calc(100vw * 500 / 1920) !important;
  .top {
    width: 100%;
    position: relative;
    margin: 2% 0;
    div {
      position: absolute;
      top: 0;
      right: 0;
      a {
        text-decoration: none;
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    margin-top: 3%;
    flex-wrap: wrap;

    .d1 {
      width: 32%;
      height: calc(100vw * 350 / 1920);
      //   background: beige;
      margin-right: 1%;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-out;
      margin-bottom: 1%;
      cursor: pointer;
      img {
        width: 100%;
        height: 88%;
      }
      div {
        width: 100%;
        height: 12%;
        text-align: center;
        line-height: calc(100vw * 32 / 1920);
      }
    }
    .d1:hover {
      transform: translateY(-10px);
      color: #006aff;
    }
  }
}
</style>
