import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import IndexVideo from "../components/IndexVideo.vue";
import aboutIndex from "../components/aboutBY/index.vue";
import productCenterIndex from "../components/productCenter/index.vue";
import sbdata from "../components/productCenter/Sbdata.vue";
import newsIndex from "../components/news/index.vue";
import newsAny from "../components/news/newsAny.vue";
import indetail from "../components/news/indetail.vue";
import recruitmentIndex from "../components/talentrecruitment/index.vue";
import contactusIndex from "../components/contactus/index.vue";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/index",
    },
    {
      path: "/index",
      name: "入场",
      component: IndexVideo,
      meta: {
        title: "滨沅国科（秦皇岛）智能科技股份有限公司",
      },
    },
    {
      path: "/home",
      name: "首页",
      component: Home,
      meta: {
        title: "首页",
      },
    },
    {
      path: "/aboutIndex",
      name: "关于滨沅",
      component: aboutIndex,
      meta: {
        title: "关于滨沅",
      },
    },
    {
      path: "/productCenterIndex",
      name: "产品中心",
      component: productCenterIndex,
      meta: {
        title: "产品中心",
      },
    },
    {
      path: "/sbdata",
      name: "产品详细信息",
      component: sbdata,
      meta: {
        title: "产品详细信息",
      },
    },
    {
      path: "/newsIndex",
      name: "新闻资讯",
      component: newsIndex,
      meta: {
        title: "新闻资讯",
      },
    },
    {
      path: "/newsAny",
      name: "新闻列表",
      component: newsAny,
      meta: {
        title: "新闻列表",
      },
    },
    {
      path: "/indetail",
      name: "新闻详细",
      component: indetail,
      meta: {
        title: "新闻详细",
      },
    },
    {
      path: "/recruitmentIndex",
      name: "人才招聘",
      component: recruitmentIndex,
      meta: {
        title: "人才招聘",
      },
    },
    {
      path: "/contactusIndex",
      name: "联系我们",
      component: contactusIndex,
      meta: {
        title: "联系我们",
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  // 获取当前时间戳
  const currentTime = new Date().getTime();
  // 获取 localStorage 中存储的过期时间
  const expirationTime = localStorage.getItem("indexExpirationTime");

  // 检查是否访问的是 index 路由，并且存在过期时间且未过期
  if (to.path === "/index" && expirationTime && currentTime < expirationTime) {
    // 重定向到其他路由，例如 SomeOtherComponent
    next({ path: "/home" });
  } else {
    // 继续正常跳转
    next();
  }

  document.documentElement.scrollTop = 0; // For modern browsers
  document.body.scrollTop = 0; // For older browsers
  // document.title = to.name || "滨沅国科（秦皇岛）智能科技股份有限公司"; // 设置文档标题
  document.title = to.meta.title || "滨沅国科（秦皇岛）智能科技股份有限公司"; // 设置文档标题
  next();
});
export default router;
