var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productCenterIndexDiv"},[_c('Harder'),_c('div',{staticClass:"main"},[_c('div',{staticClass:"swiperDiv"},[_c('el-carousel',{staticClass:"swiper",attrs:{"trigger":"click","interval":_vm.swiperTime,"height":_vm.imgHeight}},_vm._l((_vm.bannerList),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{attrs:{"src":item.url,"alt":""}})])}),1)],1),_vm._m(0),_c('div',{staticClass:"w100"},[_c('div',{staticClass:"bx"},[_c('div',{staticClass:"d1"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/productCenterMsg/d1Logo.png"),"alt":""}}),_c('h3',[_vm._v(_vm._s(_vm.coreproductData[0].productTitle))])]),_c('p',[_vm._v(" "+_vm._s(_vm.coreproductData[0].productExplain)+" ")]),_c('el-button',{staticClass:"d1Button",attrs:{"round":""},on:{"click":function($event){_vm.$router.push(
                  `/sbdata?type=${5}&id=${_vm.coreproductData[0].productId}`,
                  () => {
                    _vm.window.location.reload();
                  }
                )}}},[_vm._v("了解更多")])],1),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":'gw/prod-api' + _vm.coreproductData[0].productImg,"alt":""}})])]),_c('div',{staticClass:"d2"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":'gw/prod-api' + _vm.coreproductData[1].productImg,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/productCenterMsg/d2Logo.png"),"alt":""}}),_c('h3',[_vm._v(_vm._s(_vm.coreproductData[1].productTitle))])]),_c('p',[_vm._v(" "+_vm._s(_vm.coreproductData[1].productExplain)+" ")]),_c('el-button',{staticClass:"d1Button",attrs:{"round":""},on:{"click":function($event){_vm.$router.push(
                  `/sbdata?type=${6}&id=${_vm.coreproductData[1].productId}`,
                  () => {
                    _vm.window.location.reload();
                  }
                )}}},[_vm._v("了解更多")])],1)])])]),_vm._m(1),_c('div',{attrs:{"id":"w100"}},[_c('div',{attrs:{"id":"bx"}},[_vm._m(2),_c('div',{staticClass:"bxbottomDiv"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"设备管控","name":"first"}},[_c('Shebei')],1),_c('el-tab-pane',{attrs:{"label":"安全管控","name":"second"}},[_c('Anquan')],1),_c('el-tab-pane',{attrs:{"label":"生产管控","name":"third"}},[_c('shengchan')],1),_c('el-tab-pane',{attrs:{"label":"环保管控","name":"fourth"}},[_c('huanbao')],1)],1)],1)])]),_c('div',{attrs:{"id":"w100"}},[_c('div',{attrs:{"id":"bx"}},[_vm._m(3),_c('div',{staticClass:"myYoushi"},_vm._l((_vm.myList),function(item,index){return _c('div',{key:index},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('img',{attrs:{"src":item.url,"alt":""}}),_c('p',[_vm._v(_vm._s(item.text))])])}),0)])]),_c('div',{attrs:{"id":"w100"}},[_vm._m(4),_c('div',{staticClass:"jdal"},[_vm._m(5),_c('div',{staticClass:"jingdiananli"},[_c('Swiper')],1)])]),_c('div',{attrs:{"id":"w100"}},[_c('div',{attrs:{"id":"bx"}},[_c('div',{staticClass:"logoList"},_vm._l((_vm.logoList),function(item,index){return _c('div',{key:index},[_c('img',{attrs:{"src":item.url,"alt":""}})])}),0)])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topDivimg"},[_c('div',{staticClass:"topDiv"},[_c('header',[_c('div',[_vm._v("PROPERTY")]),_c('div',[_vm._v("核心业务")]),_c('div'),_c('div',{staticStyle:{"text-align":"center","line-height":"calc(100vw * 26 / 1920)"}},[_vm._v(" 公司产品主要有智能控制和智能运维两大核心业务，囊括散货大型装卸设备管控自动化、生产管控智能化、运维监管智能化、环境综合管控一体化、针对行业场景的打造了“沅视听”智能机器学习平台和数字孪生系统平台，为客户长期保驾护航的互联网+VR的智能运维(巡检)平台，致力于成为智慧港口、智慧钢铁、智慧电厂建设的智造专家。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zhudaochanping"},[_c('h2',[_vm._v("主导产品")]),_c('p',{staticStyle:{"line-height":"calc(100vw * 26 / 1920)"}},[_vm._v(" 主导产品包括：三维建模系统、堆取料机智能控制系统、翻车机智能控制系统、卸船机智能控制系统、门机智能控制系统、装船机智能控制系统、皮带机流程智能控制系统、智慧生产管控系统、流程料流追踪系统、数字孪生系统、传送带智能运维监控系统、滚筒轴承无线测温测震系统、皮带撕裂检测设备及系统、船舶吃水检测系统、运煤敞车反位智能识别系统、港口大型移动设备定位管理系统、运煤敞车车体表面异物检测系统、散货港口绿色管控系统、能量回馈系统、新能源系统、5G智慧化场景。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topDiv"},[_c('div',{staticClass:"dataDivLeft"},[_c('div',{staticClass:"d1"},[_c('h3',[_vm._v("产品分类")]),_c('div')]),_c('div',{staticClass:"d2"},[_vm._v("CLASSIFICATION")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topDiv"},[_c('div',{staticClass:"dataDivLeft"},[_c('div',{staticClass:"d1"},[_c('h3',[_vm._v("我们的优势")]),_c('div')]),_c('div',{staticClass:"d2"},[_vm._v("Our Advantages")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"bx"}},[_c('div',{staticClass:"topDiv"},[_c('div',{staticClass:"dataDivLeft"},[_c('div',{staticClass:"d1"},[_c('h3',[_vm._v("经典案例")]),_c('div')]),_c('div',{staticClass:"d2"},[_vm._v("Classic Cases")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topDiv"},[_c('div',{staticClass:"str"},[_vm._v("COOPERATION CASES")]),_c('div',{staticClass:"title"},[_vm._v("部分合作案例")]),_c('div',{staticClass:"l"}),_c('div',{staticClass:"r"})])
}]

export { render, staticRenderFns }