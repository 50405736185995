<template>
  <div class="productCenterIndexDiv">
    <Harder></Harder>
    <div class="main">
      <div class="swiperDiv">
        <el-carousel
          trigger="click"
          :interval="swiperTime"
          class="swiper"
          :height="imgHeight"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topDivimg">
        <div class="topDiv">
          <header>
            <div>PROPERTY</div>
            <div>核心业务</div>
            <div></div>
            <div
              style="text-align: center; line-height: calc(100vw * 26 / 1920)"
            >
              公司产品主要有智能控制和智能运维两大核心业务，囊括散货大型装卸设备管控自动化、生产管控智能化、运维监管智能化、环境综合管控一体化、针对行业场景的打造了“沅视听”智能机器学习平台和数字孪生系统平台，为客户长期保驾护航的互联网+VR的智能运维(巡检)平台，致力于成为智慧港口、智慧钢铁、智慧电厂建设的智造专家。
            </div>
          </header>
        </div>
      </div>
      <div class="w100">
        <div class="bx">
          <div class="d1">
            <div class="left">
              <div class="title">
                <img src="../../assets/productCenterMsg/d1Logo.png" alt="" />
                <h3>{{ coreproductData[0].productTitle }}</h3>
              </div>
              <p>
                {{ coreproductData[0].productExplain }}
              </p>
              <el-button
                class="d1Button"
                round
                @click="
                  $router.push(
                    `/sbdata?type=${5}&id=${coreproductData[0].productId}`,
                    () => {
                      window.location.reload();
                    }
                  )
                "
                >了解更多</el-button
              >
            </div>
            <div class="right">
              <img
                :src="'gw/prod-api' + coreproductData[0].productImg"
                alt=""
              />
            </div>
          </div>
          <div class="d2">
            <div class="left">
              <img
                :src="'gw/prod-api' + coreproductData[1].productImg"
                alt=""
              />
            </div>
            <div class="right">
              <div class="title">
                <img src="../../assets/productCenterMsg/d2Logo.png" alt="" />
                <h3>{{ coreproductData[1].productTitle }}</h3>
              </div>
              <p>
                {{ coreproductData[1].productExplain }}
              </p>
              <el-button
                class="d1Button"
                round
                @click="
                  $router.push(
                    `/sbdata?type=${6}&id=${coreproductData[1].productId}`,
                    () => {
                      window.location.reload();
                    }
                  )
                "
                >了解更多</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="zhudaochanping">
        <h2>主导产品</h2>
        <p style="line-height: calc(100vw * 26 / 1920)">
          主导产品包括：三维建模系统、堆取料机智能控制系统、翻车机智能控制系统、卸船机智能控制系统、门机智能控制系统、装船机智能控制系统、皮带机流程智能控制系统、智慧生产管控系统、流程料流追踪系统、数字孪生系统、传送带智能运维监控系统、滚筒轴承无线测温测震系统、皮带撕裂检测设备及系统、船舶吃水检测系统、运煤敞车反位智能识别系统、港口大型移动设备定位管理系统、运煤敞车车体表面异物检测系统、散货港口绿色管控系统、能量回馈系统、新能源系统、5G智慧化场景。
        </p>
      </div>
      <div id="w100">
        <div id="bx">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>产品分类</h3>
                <div></div>
              </div>
              <div class="d2">CLASSIFICATION</div>
            </div>
          </div>
          <div class="bxbottomDiv">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="设备管控" name="first">
                <Shebei />
              </el-tab-pane>
              <el-tab-pane label="安全管控" name="second"
                ><Anquan
              /></el-tab-pane>
              <el-tab-pane label="生产管控" name="third"
                ><shengchan
              /></el-tab-pane>
              <el-tab-pane label="环保管控" name="fourth"
                ><huanbao
              /></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div id="w100">
        <div id="bx">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>我们的优势</h3>
                <div></div>
              </div>
              <div class="d2">Our Advantages</div>
            </div>
          </div>
          <div class="myYoushi">
            <div v-for="(item, index) in myList" :key="index">
              <h3>{{ item.title }}</h3>
              <img :src="item.url" alt="" />
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="w100">
        <div id="bx">
          <div class="topDiv">
            <div class="dataDivLeft">
              <div class="d1">
                <h3>经典案例</h3>
                <div></div>
              </div>
              <div class="d2">Classic Cases</div>
            </div>
          </div>
        </div>
        <div class="jdal">
          <div class="topDiv">
            <div class="str">COOPERATION CASES</div>
            <div class="title">部分合作案例</div>
            <div class="l"></div>
            <div class="r"></div>
          </div>
          <div class="jingdiananli">
            <Swiper />
          </div>
        </div>
      </div>
      <div id="w100">
        <div id="bx">
          <div class="logoList">
            <div v-for="(item, index) in logoList" :key="index">
              <img :src="item.url" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Harder from "../Harder.vue";
import Footer from "../Footer.vue";
import Shebei from "./shebei.vue";
import Anquan from "./Anquan.vue";
import shengchan from "./shengchan.vue";
import huanbao from "./huanbao.vue";
import Swiper from "./swiper.vue";
export default {
  name: "aboutIndex",
  metaInfo: {
    // 设置关键词
    meta: {
      keywords: "智能控制,智能运维,设备管控,安全管控,生产管控,环保管控",
    },
  },
  components: {
    Harder,
    Footer,
    Shebei,
    Swiper,
    Anquan,
    shengchan,
    huanbao,
  },
  data() {
    return {
      bannerList: [
        {
          url: require("@/assets/productCenterMsg/产品一.png"),
          link: "",
        },
        {
          url: require("@/assets/productCenterMsg/产品二.png"),
          link: "",
        },
        {
          url: require("@/assets/productCenterMsg/产品三.png"),
          link: "",
        },
        {
          url: require("@/assets/productCenterMsg/产品四.png"),
          link: "",
        },
      ],
      swiperTime: 7000,
      activeName: "first",
      myList: [
        {
          url: require("@/assets/productCenterMsg/myYoushi/资质齐全.png"),
          title: "资质齐全",
          text: "三大体系证书、建筑业资质等资质认证保障了公司工程项目标准化流程化的管理标准、实施方案和服务品质。",
        },
        {
          url: require("@/assets/productCenterMsg/myYoushi/行业领先.png"),
          title: "行业领先",
          text: "公司坚持自主研发创新，使产品具备了行业领先的竞争力。智慧生产管控系统等产品已广泛应用到中国各大港口。",
        },
        {
          url: require("@/assets/productCenterMsg/myYoushi/先进技术.png"),
          title: "先进技术",
          text: "二十余项专利和软著让公司技术始终保持前沿地位，机器视觉等关键技术屡次斩获河北省科学技术奖。",
        },
        {
          url: require("@/assets/productCenterMsg/myYoushi/专业人才.png"),
          title: "专业人才",
          text: "公司拥有数十位行业专家和科研人才智库团队，公司团队硕士研究生等高端人才占比80%以上。",
        },
        {
          url: require("@/assets/productCenterMsg/myYoushi/产学研结合.png"),
          title: "产学研结合",
          text: "公司与燕山大学共建“产学研合作基地”，为企业研发创新项目的产业化奠定坚实基础。",
        },
      ],
      logoList: [
        {
          url: require("@/assets/productCenterMsg/logoList/秦皇岛港.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/广西柳钢.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/国家能源集团.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/首钢.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/华能曹妃甸.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/江苏利港.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/中国神华.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/中交第一航务工程局有限公司.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/唐上岗集团.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/国投曹妃甸.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/山东港口.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/邢台职业技术学院.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/北京华弘集成电路设计有限责任公司.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/天津港.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/珠海港.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/秦皇岛港.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/中信泰富.png"),
        },
        {
          url: require("@/assets/productCenterMsg/logoList/华能集团.png"),
        },
      ],
      imgHeight: "calc(100vw * 800 / 1920)",
      coreproductData: [],
    };
  },
  created() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.coreproduct();
  },
  methods: {
    handleClick(tab, event) {},
    async coreproduct() {
      let res = await this.$store.dispatch("coreproduct");
      if (res.data.code == 200) {
        this.coreproductData = res.data.data;
      } else {
        this.coreproductData = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  overflow: auto !important;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  //   top: 70px;
  //   left: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}

//右箭头
::v-deep .el-carousel__arrow--right {
  //   top: 70px;
  //   right: -13px;
  //   font-size: 55px;
  width: calc(100vw * 50 / 1920);
  height: calc(100vw * 50 / 1920);
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: center;
  //   transform: unset;
  //   right: 2%;
  li {
    margin-right: 40px;
    margin-bottom: 10%;
  }
}
/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: calc(100vw * 44 / 1920);
  height: calc(100vw * 4 / 1920);
  border: none;
  border-radius: 20px;
  background: #ffffff;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #d91724;
  opacity: 1;
}

.productCenterIndexDiv {
  width: 100%;

  .jdal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url("../../assets/productCenterMsg/jingdiananli/经典案例背景图.png");
    background-size: 100% 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    box-sizing: border-box; /* 添加这一行代码 */
    .jingdiananli {
      width: calc(100vw * 1200 / 1920);
    }
    .topDiv {
      display: flex;
      //   margin-bottom: 5%;
      width: 100%;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vw * 100 / 1920);
      .str {
        width: calc(100vw * 356 / 1920);
        height: calc(100vw * 42 / 1920);
        font-weight: 700;
        font-size: calc(100vw * 32 / 1920);
        color: rgba(255, 255, 255, 0.05);
        position: absolute;
        top: 0;
        left: 40%;
      }
      .title {
        font-weight: 400;
        font-size: calc(100vw * 32 / 1920);
        color: #ffffff;
        position: absolute;
        top: 20%;
        left: 44%;
      }
      .l {
        width: calc(100vw * 40 / 1920);
        height: 0px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        position: absolute;
        top: 30%;
        left: 37%;
      }
      .r {
        width: calc(100vw * 40 / 1920);
        height: 0px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        position: absolute;
        top: 30%;
        left: 56%;
      }
    }
  }
  #w100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #bx {
    width: calc(100vw * 1200 / 1920);
    margin-top: 3%;
    .topDiv {
      display: flex;
      margin-bottom: 2%;
      .dataDivLeft {
        width: 47%;
        // height: 100%;
        // background: #c7e49f;
        .d1 {
          display: flex;
          align-items: center;
          font-size: calc(100vw * 20 / 1920);
          div {
            width: 15%;
            // margin-left: 2%;
            border: 1px solid rgba(128, 142, 162, 0.1);
          }
        }
        .d2 {
          font-size: calc(100vw * 14 / 1920);
          color: rgba(6, 62, 141, 0.1);
          margin-top: 1%;
          margin-bottom: 3%;
        }
      }
    }
    .bxbottomDiv {
      /deep/ .el-tabs__nav-scroll {
        // display: flex;
        // justify-content: center;
      }
      /deep/ .el-tabs__item {
        width: calc(100vw * 150 / 1920);
        text-align: center;
        font-size: calc(100vw * 16 / 1920);
        color: #000000;
        font-weight: 600;
        margin-bottom: 2%;
      }
      /deep/ .el-tabs__active-bar {
        background: #006aff;
        height: calc(100vw * 2 / 1920);
        color: #006aff;
      }
    }
    .myYoushi {
      width: 100%;
      display: flex;
      padding-bottom: 3%;
      div {
        width: calc(100vw * 241 / 1920);
        height: calc(100vw * 347 / 1920);
        border: 1px solid #e7e7e7;
        margin-right: -1px; /* 添加这一行代码 */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3%;
        box-sizing: border-box;
        h3 {
          font-size: calc(100vw * 16 / 1920);
          font-weight: 600;
          margin-bottom: 10%;
          user-select: none;
        }
        img {
          width: calc(100vw * 102 / 1920);
          height: calc(100vw * 102 / 1920);
          margin-bottom: 10%;
        }
        p {
          display: block;
          font-size: calc(100vw * 14 / 1920);
          width: 80%;
          user-select: none; //文本不可选取
          line-height: calc(100vw * 30 / 1920);
        }
      }
      div:last-child {
        margin-right: 0; /* 最后一个div不需要margin-right */
      }
    }
    .jingdiananli {
    }
    .logoList {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 4%;
      div {
        width: calc(100vw * 195 / 1920);
        height: calc(100vw * 113 / 1920);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -1px;
        box-sizing: border-box;
        border: 1px solid #efefef;
        img {
          max-width: 80%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  //   background-color: #b21e1e;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .swiperDiv {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .topDivimg {
      width: 100%;
      height: calc(100vw * 250 / 1920);
      background: url("../../assets/productCenterMsg/topHeader.png");
      background-size: 100% 100%;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      //   margin-bottom: 2%;
      padding-bottom: 3%;
      .topDiv {
        width: calc(100vw * 1200 / 1920);
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 2%;
        header {
          width: 80% !important;
          height: calc(100vw * 186 / 1920);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          //   margin-bottom: 3%;

          div:nth-child(1) {
            font-size: calc(100vw * 64 / 1920);
            color: #eff1f4;
            position: absolute;
            top: 20%;
          }
          div:nth-child(2) {
            font-size: calc(100vw * 30 / 1920);
            font-weight: 700;
            position: absolute;
            top: 40%;
            // color: #eff1f4;
          }
          div:nth-child(3) {
            width: 3%;
            height: 8px;
            position: absolute;
            top: 75%;
            background: #d91724;
          }
          div:nth-child(4) {
            position: absolute;
            font-size: calc(100vw * 16 / 1920);
            top: 90%;
          }
        }
      }
    }
    .w100 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .bx {
        width: calc(100vw * 1200 / 1920);
        padding-top: 3%;
        padding-bottom: 3%;
        .d1 {
          display: flex;
          width: 100%;
          .left {
            width: 50%;
            position: relative;
            height: calc(100vw * 330 / 1920);
            position: relative;
            .title {
              display: flex;
              width: calc(100vw * 140 / 1920);
              position: absolute;
              right: 6%;
              top: 10%;
              img {
                margin-right: 10%;
                width: calc(100vw * 24 / 1920);
                height: calc(100vw * 25 / 1920);
              }
              h3 {
                font-size: calc(100vw * 20 / 1920);
              }
            }
            p {
              display: block;
              width: 91%;
              position: absolute;
              right: 10%;
              top: 30%;
              font-size: calc(100vw * 14 / 1920);
              text-align: right;
            }
            .d1Button {
              position: absolute;
              bottom: 15%;
              right: 10%;
              background-color: #d91724 !important;
              color: white !important;
              border: none !important;
              width: calc(100vw * 160 / 1920) !important;
              height: calc(100vw * 40 / 1920) !important;
              font-size: calc(100vw * 20 / 1920) !important;
              // border-radius: 5%;
            }
            .d1Button:hover {
              /* font-weight: 800 !important; */
              transform: scale(1.1);
              /* font-size: 48px !important; */
              transition: transform 0.3s ease-in-out;
            }
          }
          .right {
            width: 50%;
            height: calc(100vw * 330 / 1920);
            // background: url("../../assets/productCenterMsg/d1.png");
            // background-size: 100% 100%;
            img {
              width: 100%;
              height: calc(100vw * 330 / 1920);
            }
          }
        }
        .d2 {
          display: flex;
          width: 100%;
          .right {
            width: 50%;
            position: relative;
            height: calc(100vw * 330 / 1920);
            .title {
              display: flex;
              width: calc(100vw * 140 / 1920);
              position: absolute;
              left: 10%;
              top: 10%;
              img {
                margin-right: 10%;
                width: calc(100vw * 24 / 1920);
                height: calc(100vw * 25 / 1920);
              }
              h3 {
                font-size: calc(100vw * 20 / 1920);
              }
            }
            p {
              display: block;
              width: 91%;
              position: absolute;
              left: 10%;
              top: 30%;
              font-size: calc(100vw * 14 / 1920);
            }
            .d1Button {
              position: absolute;
              bottom: 15%;
              left: 10%;
              background-color: #d91724 !important;
              color: white !important;
              border: none !important;
              width: calc(100vw * 160 / 1920) !important;
              height: calc(100vw * 40 / 1920) !important;
              font-size: calc(100vw * 20 / 1920) !important;
              // border-radius: 5%;
            }
            .d1Button:hover {
              /* font-weight: 800 !important; */
              transform: scale(1.1);
              /* font-size: 48px !important; */
              transition: transform 0.3s ease-in-out;
            }
          }
          .left {
            width: 50%;
            height: calc(100vw * 330 / 1920);
            // background: url("../../assets/productCenterMsg/d1.png");
            // background-size: 100% 100%;
            img {
              width: 100%;
              height: calc(100vw * 330 / 1920);
            }
          }
        }
      }
    }

    .zhudaochanping {
      width: 100%;
      height: calc(100vw * 800 / 1920);
      background: url("../../assets/productCenterMsg/主导产品.png");
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      padding-top: 3%;
      box-sizing: border-box; /* 添加这一行代码 */
      user-select: none;
      h2 {
        font-size: calc(100vw * 30 / 1920);
        font-weight: 700;
        margin-bottom: 2%;
      }
      p {
        width: calc(100vw * 832 / 1920);
        font-size: calc(100vw * 14 / 1920);
        text-align: center;
      }
    }
  }
}
</style>
